<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>

		<!-- Show Div QUOTATIONS -->
		<div v-if="type==='Quotes'">
			<h5>Cotizaciones sin Procesar</h5>
			<hr>
			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">#Folio</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Nombre</th>
							<th scope="col" class="text-center">Monto</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.CardName}}</td>
							<td valign="middle" class="text-right">{{row.DocTotal | currency }}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'Q')">
										<i class="fas fa-align-justify"></i>
									</a>

									<a type="button" @click="goToCreatePDF(row.DocEntry,'Quotes')" title="Imprimir Oferta" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-pdf"></i>
									</a>
									
									<button type="button" :id="'oqu-'+row.DocEntry" @click="goToCreateOrder(row.DocEntry,'oqu-'+row.DocEntry)" title="Crear pedido" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-alt"></i>
									</button>
										
									<template v-if="userData.SALESMAN==='Y'">
										<!--  -->

										<a type="button" @click="goToLoadDocument(row.DocEntry,'edit')" title="Editar cotización" class="btn btn-outline-secondary btn-sm">
											<i class="far fa-edit"></i>
										</a>
										
										<a type="button" @click="goToCloseDocument(row.DocEntry)" title="Cerrar cotización" class="btn btn-outline-danger btn-sm">
											<i class="far fa-times-circle"></i>
										</a>
									</template>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
		<!-- Show Div QUOTATIONS END -->

		<!-- Show Div ORDERS -->
		<div v-if="type==='Orders'">
			<h5>Pedidos sin Procesar</h5>
			<hr>
			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">#Folio</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Nombre</th>
							<th scope="col" class="text-center">Monto</th>
							<th scope="col" class="text-center">Guia</th>
							<th scope="col" class="text-center">Referencia</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index" v-if="row.Type==='O' && row.Type==='O'">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.CardName}}</td>
							<td valign="middle" class="text-right">{{row.DocTotal | currency }}</td>
							<td valign="middle">
								{{row.guia}}
								<template v-if="row.url_guia !== '0'" >
									<a :href="row.url_guia" target="_blank">Ver Guia</a>
								</template>
							</td>
							<td valign="middle">{{row.referencia}}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'O')">
										<i class="fas fa-align-justify"></i>
									</a>
									<a type="button" @click="goToCreatePDF(row.DocEntry,'Orders')" title="Imprimir Pedido" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-pdf"></i>
									</a>
									<template v-if="userData.SALESMAN==='Y'">
										<a type="button" @click="goToCloseDocument(row.DocEntry)" title="Cerrar pedido" class="btn btn-outline-danger btn-sm">
											<i class="far fa-times-circle"></i>
										</a>
									</template>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="7" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- Orders delivery -->
			<h5 class="mt-3">Pedidos Surtiendose</h5>
			<hr>
			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">#Folio</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Comentarios</th>
							<th scope="col" class="text-center">Almacén</th>
							<th scope="col" class="text-center">Estatus</th>
							<th scope="col" class="text-center">Importe</th>
							<th scope="col" class="text-center">Saldo</th>
							<th scope="col" class="text-center">Guia</th>
							<th scope="col" class="text-center">Referencia</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index" v-if="row.Type==='S'">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.CardName}}</td>
							<td valign="middle">{{row.WhsCode}}</td>
							<td valign="middle">{{row.estatus}}</td>
							<td valign="middle" class="text-right">
								{{row.DocTotal | currency }}
							</td>
							<td valign="middle" class="text-right">{{row.Balance | currency }}</td>
							<td valign="middle">
								{{row.guia}}
								<template v-if="row.url_guia !== '0'" >
									<a :href="row.url_guia" target="_blank">Ver Guia</a>
								</template>
							</td>
							<td valign="middle">{{row.referencia}}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'D')">
										<i class="fas fa-align-justify"></i>
									</a>
									<a type="button" @click="goToCreatePDF(row.DocEntry,'Delivery')" title="Imprimir Pedido" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-pdf"></i>
									</a>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="8" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- end Order delivery -->
			<!-- Orders backorder -->
			<h5 class="mt-3">BackOrders</h5>
			<hr>
			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">#Folio</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Nombre</th>
							<th scope="col" class="text-center">Monto</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index" v-if="row.Type==='B'">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.CardName}}</td>
							<td valign="middle" class="text-right">{{row.DocTotal | currency }}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'B')">
										<i class="fas fa-align-justify"></i>
									</a>
									<template v-if="userData.SALESMAN==='Y'">
										<a type="button" @click="goToCloseDocument(row.DocEntry)" title="Cerrar pedido" class="btn btn-outline-danger btn-sm">
											<i class="far fa-times-circle"></i>
										</a>
									</template>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
			<!-- end Order backorder -->
		</div>
		<!-- Show Div ORDERS END -->

		<!-- Show Div INVOICES -->
		<div v-if="type==='Invoices'">
			<h5>Últimos 30 Pedidos Facturado</h5>
			<hr>

			<form v-on:submit.prevent="searchDocument">
				<div class="row mb-3">
					<div class="col-12 col-md-10">
						<input type="text" class="form-control" placeholder="Buscar por folio" aria-label="Recipient's username" aria-describedby="button-addon2" v-model="term">
					</div>
				  	<div class="col-12 col-md-2">
				    	<button class="btn btn-outline-secondary btn-block" type="button" v-on:click="searchDocument()">
				    		<i class="fas fa-search"></i>
				    	</button>
			    	</div>
				</div>
			</form>

			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">Factura</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Fecha Entrega</th>
							<th scope="col" class="text-center">No. Guía</th>
							<th scope="col" class="text-center">Referencia</th>
							<th scope="col" class="text-center">No. Cajas</th>
							<th scope="col" class="text-center">Paquetería</th>
							<th scope="col" class="text-center">Monto</th>
							<th scope="col" class="text-center">Nota de Crédito</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.fechaEntrega}}</td>
							<td valign="middle">{{row.Guia}}</td>
							<td valign="middle">{{row.referencia}}</td>
							<td valign="middle">{{row.Cajas}}</td>
							<td valign="middle">{{row.Paqueteria}}</td>
							<td valign="middle" class="text-right">{{row.DocTotal | currency }}</td>
							<td valign="middle">{{row.NC}}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'Q')">
										<i class="fas fa-align-justify"></i>
									</a>

									<a v-if="row.PDF" target="_blank" :href="splitDocs(row.PDF)" title="DESCARGAR PDF" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-pdf"></i>
									</a>

									<a v-if="row.XML" target="_blank" :href="splitDocs(row.XML)" title="DESCARGAR XML" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-code"></i>
									</a>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>

		<!-- Show Div INVOICES_WEB -->
		<div v-if="type==='Invoices_web'">
			<h5>Últimos 30 Pedidos WEB Facturado</h5>
			<hr>

			<form v-on:submit.prevent="searchDocument">
				<div class="row mb-3">
					<div class="col-12 col-md-10">
						<input type="text" class="form-control" placeholder="Buscar por folio" aria-label="Recipient's username" aria-describedby="button-addon2" v-model="term">
					</div>
				  	<div class="col-12 col-md-2">
				    	<button class="btn btn-outline-secondary btn-block" type="button" v-on:click="searchDocument()">
				    		<i class="fas fa-search"></i>
				    	</button>
			    	</div>
				</div>
			</form>

			<div class="table-responsive">

				<div v-if="loadingText===true" class="text-center loading">
					<div class="loadingtext">
      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
      				</div>
      			</div>

				<table class="table table-hover">
					<thead class="thead-dark">
						<tr>
							<th scope="col" class="text-center">Factura</th>
							<th scope="col" class="text-center">Fecha</th>
							<th scope="col" class="text-center">Fecha Entrega</th>
							<th scope="col" class="text-center">No. Guía</th>
							<th scope="col" class="text-center">Estatus</th>
							<th scope="col" class="text-center">Paquetería</th>
							<th scope="col" class="text-center">No. Entrega</th>
							<th class="text-center">Acciones</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in docs" :key="index">
							<td valign="middle">{{row.DocNum}}</td>
							<td valign="middle">{{row.DocDate}}</td>
							<td valign="middle">{{row.fechaEntrega}}</td>
							<td valign="middle">{{row.Guia}}</td>
							<td valign="middle">{{row.Status}}</td>
							<td valign="middle">{{row.Paqueteria}}</td>
							<td valign="middle">{{row.Entrega}}</td>
							<td class="text-right">
								<div class="btn-group mr-2" role="group" aria-label="First group">
									<a type="button" data-toggle="modal" data-target="#staticBackdrop" title="Ver contenido" class="btn btn-outline-secondary btn-sm" @click="loadmodal(row.DocEntry,'Q')">
										<i class="fas fa-align-justify"></i>
									</a>

									<a v-if="row.PDF" target="_blank" :href="splitDocs(row.PDF)" title="DESCARGAR PDF" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-pdf"></i>
									</a>

									<a v-if="row.XML" target="_blank" :href="splitDocs(row.XML)" title="DESCARGAR XML" class="btn btn-outline-primary btn-sm">
										<i class="far fa-file-code"></i>
									</a>
								</div>
							</td>
						</tr>
					</tbody>
					<tfoot v-if="total<=0">
						<tr>
							<td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>

		<!-- Show Div INVOICES END -->

		<!-- Modal details -->
		<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		    	<div class="modal-content">
		      		<div class="modal-header">
		      			Detalle del documento {{detailsHead.docnum}}
		        		<h5 class="modal-title" id="staticBackdropLabel">{{titles}}</h5>
		        		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          			<span aria-hidden="true">&times;</span>
		        		</button>
		      		</div>
		      		<div class="modal-body">
		      			<div v-if="loadingText===true" class="text-center loading">
							<div class="loadingtext">
		      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
		      				</div>
		      			</div>
		        		<div v-if="loadingText===false" class="table-responsive">
		        			Cliente: {{detailsHead.cardcode}} {{detailsHead.cardname}}<br>
		        			Fecha: {{detailsHead.date}}<br>
		        			Usuario que realiza el pedido: {{detailsHead.salesman}}<br>
		        			Correo: {{detailsHead.email}}<br>
		        			Teléfono: {{detailsHead.phone}}<br>
		        			Fecha Liberación: {{detailsHead.follow}}<br>
		        			<table class="table table-hover">
								<thead>
									<tr>
										<th scope="col" class="text-center">Item</th>
										<th scope="col" class="text-center">Descripción</th>
										<th scope="col" class="text-center">Cantidad</th>
										<th scope="col" class="text-center">Precio</th>
										<th scope="col" class="text-center">SubTotal</th>
										<th v-if="typeDoc==='B'" scope="col" class="text-center">Entregados</th>
										<th v-if="typeDoc==='B'" scope="col" class="text-center">Restante</th>
										<!--
											<th v-if="typeDoc==='B' && userData.SALESMAN==='Y'" scope="col" class="text-center"></th> 
										-->
									</tr>
								</thead>
								<tbody>
									<tr v-for="(row,index) in details" :key="index" :class="changeClass(row.LineStatus, typeDoc)">
										<td>{{row.ItemCode}}</td>
										<td>{{row.ItemName}}<br>{{row.notes}}</td>
										<td class="text-right">{{row.Quantity | number('0,0') }}</td>
										<td class="text-right">{{row.Price | currency }}</td>
										<td class="text-right">{{row.Price*row.Quantity | currency }}</td>
										<td v-if="typeDoc==='B'" class="text-right">{{row.QuantitySent | number('0,0') }}</td>
										<td v-if="typeDoc==='B'" class="text-right">
											{{row.QuantityOpen | number('0,0') }}
										</td>
										<!--
										<td v-if="typeDoc==='B' && userData.SALESMAN==='Y' " class="text-right">
											<template v-if="userData.SALESMAN==='Y' && row.LineStatus==='O' ">
												<a type="button" @click="goToCloseLine(row.DocEntry,row.LineNum)" title="Cerrar linea" class="btn btn-outline-danger btn-sm">
													<i class="far fa-times-circle"></i>
												</a>
											</template>
										</td>
										-->
									</tr>
								</tbody>
								<tfoot v-if="totaldetails<=0">
									<tr>
										<td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
									</tr>
								</tfoot>
							</table>
							<div class="my-3 text-right">
								<div v-if="loadingText===true" class="text-center loading">
									<div class="loadingtext">
				      					<span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
				      				</div>
				      			</div>
				        		<button type="button" class="btn btn-warning" data-dismiss="modal">Cerrar</button>
				        		<button v-if="typeDoc==='O' || typeDoc==='Q'  || typeDoc==='I'" type="button" class="btn btn-primary" @click="goToLoadDocument(docEntry,'load')">
				        			Cargar al carrito
				        		</button>
				        		<!---->
				        		<template v-if="userData.SALESMAN==='Y'">
				        			<button v-if="type==='Quotes'" :id="'oqumodal-'+docEntry" type="button" class="btn btn-primary" @click="goToCreateOrder(docEntry,'oqumodal-'+docEntry)">
				        				Crear Pedido
				        			</button>
				        		</template>
				        	
				        		
				      		</div>
		        		</div>
		      		</div>
		      		
		    	</div>
		  	</div>
		</div>

	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  	props: ['type','user','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		loadingText: false,
    		loadingTextItems: false,
    		term:'',
    		docs:[],
    		details:[],
    		detailsHead:[],
    		userData:[],
    		total:0,
    		totaldetails:0,
    		titles:'',
    		docEntry:0,
    		typeDoc:'O',
    		docTotal: 0,
    	}
  	},
  	components: { Loading },
    mounted() {
        //console.log('ubk',this.ubk);
        /*
        CardCode: "28806", CardName: "Joaquin Barranco Orozco", SALESMAN: "N", WHOLESALER: "N", list_price: 7, list_price_type: "PUBLICO", location: "B", showcart: true
        */
        this.userData = this.user;
        //console.log(this.type);
        this.callApi();
    },
    methods: {
    	async callApi(search=''){
            this.isLoading = true;
            this.docs = [];
            var searchers = search.length > 0 ? 1 : 0;
            var SPName = 'spWebOptionsPublic'
            var Param1 = this.type;
            var Param2 = this.userData.CardCode;
            var Param3 = `${this.userData.list_price}`;
            var Param4 = search;
            var Param5 = `${searchers}`;
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            var acum = 0;
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data
                //console.log(response);
                let i=0;
                if(result.length>0){
                	result.forEach(element=>{
                		var update = element;
                		if(element.Type==='S'){
                			acum += element.DocTotal;
                			update.Balance = acum;
                		}
	                    this.docs.push(update); 
	                    i++;
	                });
	                this.total=i;
                }
            })
            .catch(error => {
                console.log("error", error);
            })
            .finally(() => {
            	this.isLoading = false;
            	//console.log("Result:",this.docs);
            });
        },
        async loadmodal(donentry,doc){
            this.loadingText = true;
            this.docEntry = donentry;
            this.details = [];
            var Param1 = this.type+'_items';
            if(doc==='D')Param1 = 'Delivery_items';
            if(doc==='B')Param1 = 'Backorder_items';
            this.typeDoc = doc;
            var Param2 = `${donentry}`;
            var Param3 = '';
            var Param4 = '';
            var SPName = 'spWebOptionsPublic'
            let data = {SPName,Param1,Param2,Param3,Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            let headerdata = [];
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data
                console.log(result);
                let i=0;
                result.forEach(element=>{
                    this.details.push(element);
                    var dates = 'NA';
                    var hores = 'NA';
                    if(element.datefree.length>5){ dates = element.datefree; }
                    if(element.hourfree.length>5){ hores = element.hourfree; }
                    headerdata = { 
                    	"salesman":element.salesman, 
                    	"cardcode":element.CardCode, 
                    	"cardname":element.CardName, 
                    	"date":element.DocDate,
                    	"docnum":element.DocNum,
                    	"follow":dates + ' '+ hores,
                    	"phone": element.phone,
                    	"email": element.mail,
                    };
                    i++;
                });
                //console.log(headerdata);
                this.detailsHead = headerdata;
                this.totaldetails=i;
            })
            .catch(error => {
                console.log("error", error);
            })
            .finally(() => {
                this.loadingText = false;
            });
        },
        splitDocs(file){
        	// //172.31.30.197/iInvoice/Bicimex/FacturacionElectronica/CFDI/MMM/CFDI_FA_12797_XAXX010101000.pdf
        	// //bcxsap/Corpotek/Bicimex/CPortal/CFDI/CFDI_FA_180001719_RORO710203R58.pdf
        	// /BCXSAP/iInvoice/Bicimex/FacturacionElectronica/CFDI/COK1_XM_CFDI_FacturaNormal_120025359_ADDON.pdf
        	let newurl = "https://bicimex.com/bcx/docs/"+file;
        	if(file.includes('Corpotek')){
        		let newAddress = file.replaceAll(/\\/g, "*");
        		newAddress = newAddress.replaceAll("**bcxsap*Corpotek*Bicimex*", "");
        		newAddress = newAddress.replaceAll("*", "/");
        		console.log("newurl",newAddress);
        		newurl = "https://bicimex.com/bcx/retail/"+newAddress
        	}
        	// --bcxsap-Corpotek-Bicimex-iRetail-CFDI-Consolidador-CFDI_FA_10069777_XAXX010101000.pdf
        	// iRetail-CFDI-Consolidador-CFDI_FA_10069777_XAXX010101000.pdf
        	// iRetail-CFDI-Consolidador-CFDI_FA_10069777_XAXX010101000.pdf
        	return newurl
        },
        searchDocument(){ this.callApi(this.term); },
        changeClass(value, types) {
		  return {
		    'text-desable': value === 'C' && types === 'B',
		    '': value === 'O' && types === 'B',
		    '': types !== 'B'
		  }
		},
        goToLoadDocument(docentry,load){
        	Swal.fire({
			  	title: 'Esta acción eliminara el contenido de su carrito, ¿Esta seguro?',
			  	icon: 'question',
			  	showCancelButton: true,
			  	confirmButtonText: `Si`,
			  	allowOutsideClick: false,
  				allowEscapeKey: false,
			}).then((result) => {
			  /* Read more about isConfirmed, isDenied below */
			  if (result.isConfirmed) {
			  	this.goToLoadCart(docentry,load);
			  } else {
			    Swal.fire('No surgieron cambios!', '', 'info')
			  }
			});
        },
        async goToLoadCart(docentry,load){
        	this.loadingTextItems = true;
        	let paramToSend = `load/${this.type}_items`; 
        	if(load==='edit'){ paramToSend = 'edit/quote'; }
        	await axios
            .post(`${this.ubk}documents/${paramToSend}/${docentry}`,null)
            .then(response => {
                let result = response.data
                console.log('Loading:',result);
                window.location = `${this.ubk}cart`;
            })
            .catch(error => { console.log("error", error);  })
            .finally(() => { this.loadingTextItems = false; });
        },
        goToCloseDocument(docentry){
        	Swal.fire({
			  	title: 'Esta acción cerrar el documento y perdera toda información sobre el, ¿Esta seguro?',
			  	icon: 'question',
			  	showCancelButton: true,
			  	confirmButtonText: `Si`,
			  	allowOutsideClick: false,
  				allowEscapeKey: false,
			}).then((result) => {
			  /* Read more about isConfirmed, isDenied below */
			  if (result.isConfirmed) {
			    this.closeDocument(docentry);
			  } else {
			    Swal.fire('No surgieron cambios!', '', 'info')
			  }
			});
        },
        goToCloseLine(docentry,linenum){
        	Swal.fire({
			  	title: 'Esta acción cerrar la linea, ¿Esta seguro?',
			  	icon: 'question',
			  	showCancelButton: true,
			  	confirmButtonText: `Si`,
			  	allowOutsideClick: false,
  				allowEscapeKey: false,
			}).then((result) => {
			  /* Read more about isConfirmed, isDenied below */
			  if (result.isConfirmed) {
			    console.log('Delete',docentry, linenum);
			  } else {
			    Swal.fire('No surgieron cambios!', '', 'info')
			  }
			});
        },
        async closeDocument(docentry){
        	this.loadingTextItems = true;
        	await axios
            .post(`${this.ubk}documents/close/${this.type}/${docentry}`,null)
            .then(response => {
                let result = response.data;
                //console.log('Loading:',result);
                if(result.length>2){
                	Swal.fire('Ups!! contacte a un asesor.', result, 'info');
                }else{
                	Swal.fire({
					  	title: "Documento Cerrado",
					  	icon: 'info',
					  	showCancelButton: false,
					  	confirmButtonText: `Ok`,
					  	allowOutsideClick: false,
		  				allowEscapeKey: false,
					}).then((result) => {
					  /* Read more about isConfirmed, isDenied below */
					  if (result.isConfirmed) { window.location.reload(); }
					});
                }
                
            })
            .catch(error => { console.log("error", error);  })
            .finally(() => { this.loadingTextItems = false; });
        },
        async goToCreateOrder(docentry,idElement){
        	document.getElementById(idElement).disabled = true;
        	this.loadingTextItems = true;
        	await axios
            .post(`${this.ubk}documents/create/order/${docentry}`,null)
            .then(response => {
                let result = response.data
                //console.log('Loading:',result);
                //Swal.fire(responData.title,responData.message, responData.icon);
                //alert(result.message);
                var urlsend = result.url;
                Swal.fire({
				  	title: result.message,
				  	icon: result.icon,
				  	showCancelButton: false,
				  	confirmButtonText: `Ok`,
				  	allowOutsideClick: false,
	  				allowEscapeKey: false,
				}).then((result) => {
				  /* Read more about isConfirmed, isDenied below */
				  if (result.isConfirmed) { 
				  	window.location = `${this.ubk}${urlsend}`; 
				  }
				});
            })
            .catch(error => { console.log("error", error);  })
            .finally(() => { this.loadingTextItems = false; });
        },
        async goToCreatePDF(docentry,documents){
        	window.location.href = `${this.ubk}documents/create/pdf/${documents}/${docentry}`;
        }
    },
};
</script>

<style lang="css" scoped>
	table{ font-size: 12px!important; }
</style>