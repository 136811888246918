<template>
	<div class="btn-group dropup">
        <img :src="ubk+'icons/offert.svg'" class="cursor imgBradgeimg" alt="icon offert to vol" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-placement="top" title="Oferta por volumen"/>

        <div class="dropdown-menu dropdown-menu-right dropdown-menu-lg-left" style="min-width:200px">
            <!-- Dropdown menu links -->
            <div class="p-2 text-muted">
                <h6 class="text-center">Oferta x volumen</h6>
                <table class="table">
                    <tr>
                        <th>Cantidad</th><th>Precio</th><th>Descuento</th>
                    </tr>
                    <tr v-for="(row,index) in offerts" v-bind:key="index">
                        <td v-if="row.Type==='S'" class="text-left">+ {{row.Amount | number('0,0') }}</td>
                        <td v-if="row.Type==='S'" class="text-center">{{row.Price | currency}}</td>
                        <td v-if="row.Type==='S'" class="text-center">{{row.Discount | percent}}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'PricesOffertComponent',
  props: ['offerts','ubk'],
  data () {
    return {
        
    }
  },
};
</script>

<style lang="css" scoped>
.imgBradgeimg {
    width: 60px!important;
}

</style>