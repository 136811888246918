   <template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
	    <!-- .block-posts -->
    	<div v-if="records>0" class="block block-categories mt-4">
            <div class="container">
                <div class="block-header">
                    <h3 class="block-header__title">{{title}}</h3>
                    <div class="block-header__divider"></div>
                </div>
                <div infinite-wrapper class="block-categories__list">
                    
                    <div class="block-categories__item category-card category-card--layout--compact" v-for="(row, index) in listas" :key="index">
                        <div class="category-card__body">
                            <div class="category-card__image">
                            	<a :href="links+'='+row.code">
                            		<img :src="ubk+'images/brandsall/'+row.brand.toLowerCase()+'.png'" :alt="row.brand.toLowerCase()" @error="imageUrlAlt">
                            	</a>
                            </div>
                            <div class="category-card__content">
                                <div class="category-card__name">
                                    <a :href="links+'='+row.code">{{row.brand}}</a>
                                </div>
                                <div class="category-card__products">
                                    <span v-show="row.num>1">{{row.num}} Productos</span>
                                    <!-- <span v-show="row.num<=1">{{row.num}} Producto</span> -->
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <!-- Hacker News item loop force-use-infinite-wrapper -->
                    <infinite-loading
                        @infinite="loadCards"
                        spinner="waveDots"
                        @distance="200"
                        :forceUseInfiniteWrapper="true"
                    >
                        <div slot="no-more" class="break"> No more records </div>
                        <div slot="spinner" class="break">Loading...</div>
                        <div slot="no-results" class="break">No result data</div>
                    </infinite-loading>
                <!-- End item loop -->
            </div>
        </div>
        <!-- .block-posts / end -->
	</div>
</template>
<script>
import axios from "axios";
	// Import component
import Loading from 'vue-loading-overlay';

export default {
	props: ['action','title','options','ubk'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            records:0,
            showPrice:false,
            userData:[],
            location:null,
            listas:[],
            numShow:20,
            page:0,
            links:'',
        }
	},
	components: { Loading },
	mounted() {
        this.type = this.action;
        this.location = this.options.location;
        if(this.action==='BRANDS'){
        	this.links = this.ubk+"search?init=0&search=*&brand_in";
        }
        if(this.action==='CATEGORIES'){
        	this.links = this.ubk+"search?init=0&search=*&fam_in";
        }
        this.loadCards();
    },
    methods: {
        	async loadCards($state){
                //this.isLoading = true;
                let vm = this;
                let Param1 = "ALL";
                if(this.location==='M') Param1 = "Y";
                if(this.location==='C') Param1 = "N";
                if(this.location==='B') Param1 = "ALL";
                this.page++;
                let data = {
                    'Action':this.action,
                    'Param1':Param1,
                    'Param2':'',
                    'Param3':'',
                    'Param4':'',
                    'Param5':'',
                    'Param6':'',
                    'Param7':'',
                    'Param8':'',
                    'Param9':'',
                    'Param10':'',
                    'Param11':'',
                    'Param12':'',
                    'Param13':'',
                    'Param14':'',
                    'Page':this.page,
                    'Size':this.numShow
                };
                //console.log(data);
                await axios.post(this.api + "/products/search",data)
                    .then(({ data }) => {
                        //console.log('Result',data);
                        if (data.length) {
                            const itms = data;
                            itms.forEach(element=>{
                                this.listas.push(element);
                            });
                            data.forEach(element => { this.records = element.TotalRows; });
                            $state.loaded();
                        }else{
                            $state.complete();
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        //this.isLoading = false;
                });// end axios call

            },// end function load api   this.loading = false
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            }
        },
};
</script>

<style>
.break{
	text-align: center;
	margin-top: 20px;
}
</style>
