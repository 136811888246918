<template>
    <!-- BEGIN OPTION MOVILE -->
    <div v-if="option === 'Movil'">
        <!-- BEGIN BIKE MENU -->
        <li class="page_menu_item has-children">
            <a href="javascript:;" data-toggle="collapse" data-target="#collapseMenuBici" aria-expanded="true" aria-controls="collapseOne" class="ml-auto">
                <i class="fas fa-bicycle mr-3"></i> Bicis <i class="fas fa-angle-double-down float-right down-arrow"></i>
            </a>
            <div id="collapseMenuBici" class="collapse" aria-labelledby="headingOne" data-parent="#accordionMenuMovile">
                <div class="container-fluid p-3" style="background-color: #fff;">     
                    <div class="row">
                        <div class="col-12 menus"> 
                            <div v-if="loading">Cargando...</div>
                            <!-- FOR Categories -->
                            <div v-for="(cat,index) in catBike">
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-bicycle"></i> Bicicletas </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=B&cat='+encodeURI(cat)">
                                    <i class="fas fa-bicycle"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catAccBike">
                                <h3 v-if="index===0" class="text-center gray"><i class="fab fa-slack"></i> Accesorios </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=B&cat='+encodeURI(cat)">
                                    <i class="fab fa-slack"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catRefBike">
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-tools"></i> Refacciones </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=B&cat='+encodeURI(cat)">
                                    <i class="fas fa-tools"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catToyBike">
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-feather-alt"></i> Juguetes </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=B&cat='+encodeURI(cat)">
                                    <i class="fas fa-feather-alt"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!-- END BIKE MENU -->
        <!-- BEGIN BIKE MENU -->
        <li class="page_menu_item has-children">
            <a href="javascript:;" data-toggle="collapse" data-target="#collapseMenuMoto" aria-expanded="true" aria-controls="collapseOne" class="ml-auto">
                <i class="fas fa-bicycle mr-3"></i> Motos <i class="fas fa-angle-double-down float-right down-arrow"></i>
            </a>
            <div id="collapseMenuMoto" class="collapse" aria-labelledby="headingOne" data-parent="#accordionMenuMovile">
                <div class="container-fluid p-3" style="background-color: #fff;">     
                    <div class="row">
                        <div class="col-12 menus"> 
                            <div v-if="loading">Cargando...</div>
                            <!-- FOR Categories -->
                            <div v-for="(cat,index) in catMoto" >
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-motorcycle"></i> Motocicletas </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=M&cat='+encodeURI(cat)">
                                    <i class="fas fa-motorcycle"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catAccMoto" >
                                <h3 v-if="index===0" class="text-center gray"><i class="fab fa-slack"></i> Accesorios </h3>
                                <a class="dropdown-item" v-bind:href="'/search?op=C&type=M&cat='+encodeURI(cat)">
                                    <i class="fab fa-slack"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catRefMoto" >
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-tools"></i> Refacciones </h3>
                                <a class="dropdown-item"  v-bind:href="'/search?op=C&type=M&cat='+encodeURI(cat)">
                                    <i class="fas fa-tools"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                            <!-- FOR Accesories -->
                            <div v-for="(cat,index) in catToyMoto" >
                                <h3 v-if="index===0" class="text-center gray"><i class="fas fa-feather-alt"></i> Juguetes </h3>
                                <a class="dropdown-item"  v-bind:href="'/search?op=C&type=M&cat='+encodeURI(cat)">
                                    <i class="fas fa-feather-alt"></i> {{cat}} 
                                </a>
                            </div> <!-- END FOR Categories -->
                        </div>
                    </div>
                </div>
            </div>
        </li>
        <!-- END BIKE MENU -->
    </div>
    <!-- END OPTION MOVILE -->
</template>

<script>
import axios from "axios";

    export default {
        props: ['option'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                loading:true,
                catergories:null,
                catBike:[],
                catAccBike:[],
                catRefBike:[],
                catToyBike:[],
                catMoto:[],
                catAccMoto:[],
                catRefMoto:[],
                catToyMoto:[],
                observer: null
            }
        },
        computed: {
            // load any time data. if value change
            getGlobals : function(){ return null; },
        },
        mounted() {
          this.loadCategories();
        },
        methods: {
            async loadCategories(){
                this.loading = true;
                let vm = this;
                await axios
                    .get(this.api + "/products/categories")
                    .then(response => {
                        this.catergories = response.data.result;
                        this.catergories.forEach(element=>{
                            if(element.Bicis==='Y' && element.Motos==='N'){
                                if(element.Bicicletas==='Y')this.catBike.push(element.Categorias);
                                if(element.Accesorios==='Y')this.catAccBike.push(element.Categorias);
                                if(element.Refacciones==='Y')this.catRefBike.push(element.Categorias);
                                if(element.Juguetes==='Y')this.catToyBike.push(element.Categorias);
                            }
                            if(element.Bicis==='N' && element.Motos==='Y'){
                                if(element.Motocicletas==='Y')this.catMoto.push(element.Categorias);
                                if(element.Accesorios==='Y')this.catAccMoto.push(element.Categorias);
                                if(element.Refacciones==='Y')this.catRefMoto.push(element.Categorias);
                                if(element.Juguetes==='Y')this.catToyMoto.push(element.Categorias);
                            }
                        });
                        this.catBike.sort();
                        this.catAccBike.sort();
                        this.catRefBike.sort();
                        this.catToyBike.sort();
                        this.catMoto.sort();
                        this.catAccMoto.sort();
                        this.catRefMoto.sort();
                        this.catToyMoto.sort();
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => this.loading = false);
            },// end function load api
            
        }
    }
</script>
