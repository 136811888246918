<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<h1>Artículos próximos a llegar.</h1>
       	<table class="table">
            <thead>
                <tr>
                    <th>Clave</th>
                    <th>Nombre</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                    <td>{{order.ItemCode}}</td>
                    <td>
                        {{order.ItemName}}
                    </td>
                </tr>
            </tbody>
        </table>
        <a :href="ubk+'orders'" class="btn btn-outline-secondary ">Ver mis backorder</a>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'SeeBackOrderArriveComponent',
  props: ['cardcode','ubk'],
      data () {
        return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            loadingText: false,
            orders:[],
        }
    },
    components: { Loading },
    mounted() {
        this.callApi();
    },
    methods: {
        async callApi(){
            this.isLoading = true;
            var SPName = 'spReports';
            var Param1 = 'BACKORDER-ALERT';
            var Param2 = this.cardcode;
            var Param3 = '';
            var Param4 = '';
            let data = {SPName,Param1,Param2,Param3, Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(({ data })=> { 
                //console.log(data);
                this.orders = data; 
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
    },
};
</script>

<style lang="css" scoped>
</style>