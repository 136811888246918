<template>
    <div>
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="mt-3 table-responsive">
            <h3>Listado de mis clientes</h3>
            <div class="my-3">
	            <button type="button" class="btn btn-info" @click="goToUrl('p')">Prospectos</button>
	            <button type="button" class="btn btn-primary" @click="goToUrl('c')">Nuevos Clientes</button>  
	        </div>
            <table class="table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th>Linea de Crédito</th>
                        <th>Balance</th>
                        <th>Seguro</th>
                        <th>Condición Pago</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(userdb, index) in users" :key="index">
                        <td>{{userdb.CardCode}}</td>
                        <td>{{userdb.CardName}}</td>
                        <td>{{userdb.CreditLine}}</td>
                        <td><span v-if="userdb.Balance>0"> {{ userdb.Balance | currency }} </span></td>
                        <td>{{userdb.Seguro}}</td>
                        <td>{{userdb.CPago}}</td>
                    </tr>
                </tbody>
            </table>
            <export-excel
	            class   = "btn btn-warning"
	            :data   = "json_data"
	            :fields = "json_fieldsRLE"
	            worksheet = "Mis Clientes"
	            :name    = "nameFile">
	         
	            <i class="far fa-file-excel"></i> Descargar Excel.
	         
	        </export-excel>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props: ['slpcode','user','ubk'],
    data () {
        return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            loadingText: false,
            userd:null,
            userData:[],
            users:[],
            json_data:[],
            searchclient:'',
            nameFile:'my-wallet.xls',
            json_fieldsRLE: {
                'Código Cliente': 'CardCode',
                'Nombre Cliente': 'CardName',
                'Linea de Crédito': 'CreditLine',
                'Balance': 'Balance',
                'Seguro': 'Seguro',
                'Condición Pago': 'CPago',
            },
        }
    },
    components: { Loading },
    mounted() {
        /*
        CardCode: "28806", CardName: "Joaquin Barranco Orozco", SALESMAN: "N", WHOLESALER: "N", list_price: 7, list_price_type: "PUBLICO", location: "B", showcart: true, EMPID:0
        */
        this.userData = this.user;
        this.callApi();
    },
    methods: {
        async callApi(){
            this.isLoading = true;
            this.header = [];
            var SPName = 'spReports';
            var Param1 = 'MY-WALLET';
            var Param2 = this.slpcode;
            var Param3 = '';
            var Param4 = '';
            let data = {SPName,Param1,Param2,Param3, Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(({ data })=> { 
                //console.log(data);
                this.users = data; 
                this.json_data = data;
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        goToUrl(type){
            location.href = `${this.ubk}newuser/${type}`;
        }
    },
};
</script>

<style lang="css" scoped>
</style>
