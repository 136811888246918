<template>
    <div >
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="products-view">
            <div v-if="action!='LANDING'" class="products-view__options">
                <div class="view-options view-options--offcanvas--always">
                    <div class="view-options__filters-button">
                        <button type="button" class="filters-button">
                            <svg class="filters-button__icon" width="16px" height="16px">
                                <use :xlink:href="ubk+'images/sprite.svg#filters-16'"></use>
                            </svg>
                            <span class="filters-button__title">>Filtros</span>
                        </button>
                    </div>
                    <div class="view-options__layout">
                        <div class="layout-switcher">
                            <div class="layout-switcher__list">
                                <button data-layout="grid-5-full" data-with-features="false" title="Grid" type="button" class="layout-switcher__button  layout-switcher__button--active ">
                                    <svg width="16px" height="16px">
                                        <use :xlink:href="ubk+'images/sprite.svg#layout-grid-16x16'"></use>
                                    </svg>
                                </button>
                                <button data-layout="list" data-with-features="false" title="List" type="button" class="layout-switcher__button ">
                                    <svg width="16px" height="16px">
                                        <use :xlink:href="ubk+'images/sprite.svg#layout-list-16x16'"></use>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="view-options__legend">{{records}} productos</div>
                    <div class="view-options__divider"></div>
                    <div class="view-options__control">
                        <table border="0" class="table noneborder">
                            <tr class="noneborder">
                                <td v-if="salesman" class="noneborder centertd">
                                    <table style="width:100%">
                                        <tr>
                                            <td align="middle"  class="noneborder centertd">Articulos sin stock</td>
                                            <td class="noneborder centertd">
                                                <label class="switch" for="invoice_check">
                                                    <input type="checkbox" class="form-check-input" id="invoice_check" name="invoice_check"  @change="onStock($event)" v-model="checkedStock">
                                                    <span class="slider round"></span>
                                                </label>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td class="noneborder">
                                    <label for="">Mostrar</label>
                                    <div>
                                        <select class="form-control form-control-sm" name="numShow" v-model="numShow" @change="onChangePage($event)" >
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                    </div>
                                </td>
                                <td class="noneborder">
                                    <label for="">Ordenar por</label>
                                    <div>
                                        <select class="form-control form-control-sm" name="orderBy" v-model="orderby" @change="onChange($event)" >
                                            <option value="RL">Relevancia</option>
                                            <option value="AZ">Nombre (A-Z)</option>
                                            <option value="ZA">Nombre (Z-A)</option>
                                            <option value="PME">Precio Menor-Mayor</option>
                                            <option value="PMA">Precio Mayor-Menor</option>
                                        </select>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <div  infinite-wrapper class="products-view__list products-list" data-layout="grid-5-full" data-with-features="false" data-mobile-grid-columns="2">

                <div class="products-list__body" >
                    <div  class="products-list__item" v-for="(item, index) in listas" :key="index">
                        <product
                            :item = "item"
                            :userData="userData"
                            :action="action"
                            :ubk="ubk"
                            :isSugerido="isSugerido"
                        />
                    </div>
                </div>
                
            </div>
            <div class="products-view__pagination" v-if="records>numShow">
                <ul class="pagination justify-content-center" style="flex-wrap: wrap;">
                    <li v-if="page===1" class="page-item disabled">
                        <button type="button" class="page-link page-link--with-arrow" href="" aria-label="Previous">
                            <
                        </button>
                    </li>
                    <li v-else class="page-item">
                        <button type="button" class="page-link page-link--with-arrow" @click="loadProducts(--page)" aria-label="Previous">
                            <
                        </button>
                    </li>
                    <div v-for="id in (firstRecord,lastRecord)" v-if="id >= firstRecord">
                        <li v-if="id===page" class="page-item active">
                            <button type="button" class="page-link" href="">{{id}} <span class="sr-only">(current)</span></button>
                        </li>
                        <li class="page-item" v-else>
                            <button type="button" class="page-link" @click="loadProducts(id)">{{id}}</button>
                        </li>
                    </div>

                    <li v-if="page===parseInt(lastPage)" class="page-item disabled">
                        <button type="button"  class="page-link page-link--with-arrow" aria-label="Next" disabled>
                            >
                        </button>
                    </li>
                    <li v-else class="page-item">
                        <button type="button" class="page-link page-link--with-arrow" @click="loadProducts(++page)" aria-label="Next">
                            >
                        </button>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</template>

<script>
    import Loading from 'vue-loading-overlay';
    import pricescomponent from './PricesComponent';
    import product from './PrintProductComponent';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
  
    export default {
        props: ['action','search','options','ubk','param01','param02','param03','param04','param05','param06','param07','param08','param09','param10','param11','param12','param13','param14'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                location:"B",
                userData:null,
                isLoading: false,
                listas:[],
                page: 1,
                records:0,
                loop:0,
                numShow:25,
                page:0,
                showPrice:false,
                backPage:'disabled',
                activePage:'active',
                infiniteId: +new Date(),
                links:'',
                orderby: 'RL',
                seeFav: true,
                checkedStock:false,
                salesman:false,
                isSugerido:false,
                firstRecord:0,
                lastRecord:0,
                lastPage:1,

            }
        },// end data parameters
        components: { Loading, pricescomponent, product },
        mounted() {
            //console.log('data',this.options);
            this.location = this.options.location;
            this.userData = this.options;
            this.showPrice = this.userData.showcart;
            this.links = this.ubk+"search?init=0&search=*";
            this.orderby = this.param09;
            if(this.param12==='Y'){ this.checkedStock = false; }else{ this.checkedStock = true; }
            if(this.userData.CardCode === "20000"){ this.seeFav = false; }
            if(this.options.SALESMAN === "Y"){ this.salesman = true; }
            if(this.options.see_stock35 === "Y"){ this.salesman = true; }
            this.loadProducts(1);
        },
        methods: {
            goTop() {
                window.scrollTo(0,0);
            },
            async loadProducts(page = 1){
                this.listas = [];
                this.isLoading = true;
                let vm = this;
                let Param1 = '';
                let Param2 = '';
                let Param3 = '';
                let Param4 = '';
                let Param5 = '';
                let Param6 = '';
                let Param7 = '';
                let Param8 = '';
                let Param9 = '';
                let Param10 = '';
                let Param11 = '';
                let Param12 = '';
                let Param13 = '';
                let Param14 = '';
                var search = ['micrositio', 'search'];
                if(this.userData.location==='M'){ // moto
                    Param1 = "AND(T0.Bikes='N' OR T0.Motos='Y')";
                }else if(this.userData.location==='C'){ // bici
                    Param1 = "AND(T0.Bikes='Y' OR T0.Motos='N')";
                }else{
                    Param1 = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                    Param1 =  this.checkCategories();
                }
                if( search.includes(vm.action) ){
                    //Param1 = vm.param01;
                    if(vm.param01.length>0){
                        Param1 = vm.param01;
                    }
                    //Param1 = vm.param01;
                    Param2 = vm.param02;
                    Param3 = vm.param03;
                    Param4 = vm.param04;
                    Param5 = vm.param05;
                    Param6 = vm.param06;
                    Param7 = vm.param07;
                    Param8 = vm.param08;
                    Param9 = vm.param09;
                    Param10 = vm.param10;
                    Param11 = vm.param11;
                    Param12 = vm.param12;
                    Param13 = vm.param13;
                    Param14 = vm.param14;
                }else{
                    /*
                    if(this.userData.location==='M'){ // moto
                        Param1 = "AND(T0.Bikes='N' OR T0.Motos='Y')";
                    }else if(this.userData.location==='C'){ // bici
                        Param1 = "AND(T0.Bikes='Y' OR T0.Motos='N')";
                    }else{
                        Param1 = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                    }
                    */
                    Param2 = 'N';
                    Param3 = vm.param03;
                    Param4 = vm.param04;
                    Param5 = vm.param05;
                    Param6 = vm.param06;
                    Param7 = vm.param07;
                    Param8 = vm.param08;
                    Param9 = vm.param09;
                    Param10 = vm.action;
                    Param11 = 'N';
                    Param12 = vm.param12;
                    Param13 = vm.param13;
                    Param14 = vm.param14;
                }
                var ifProcedure = 'FILTERS-SEARCH';
                if(this.search==='LANDING'){
                    ifProcedure = 'LANDING-ALL';
                    Param1 = vm.param01;
                    Param2 = vm.param02;
                    Param3 = vm.param03;
                    Param4 = vm.param04;
                    Param5 = vm.param05;
                    Param6 = vm.param06;
                    Param7 = vm.param07;
                    Param8 = vm.param08;
                    Param9 = vm.param09;
                    Param10 = vm.param10;
                    Param11 = 'N';
                    Param12 = vm.param12;
                    Param13 = vm.param13;
                    Param14 = vm.param14;
                }
                this.page = page;
                let data = {
                    'Action':ifProcedure,
                    'Param1':`${Param1}`,
                    'Param2':`${Param2}`,
                    'Param3':`${Param3}`,
                    'Param4':`${Param4}`,
                    'Param5':`${Param5}`,
                    'Param6':`${Param6}`,
                    'Param7':`${Param7}`,
                    'Param8':`${Param8}`,
                    'Param9':`${Param9}`,
                    'Param10':`${Param10}`,
                    'Param11':`${Param11}`,
                    'Param12':`${Param12}`,
                    'Param13':`${Param13}`,
                    'Param14':`${Param14}`,
                    'Page':page,
                    'Size':parseInt(this.numShow)
                };
                if(Param10==='SUGGESTED')vm.isSugerido = true;
                //console.log('Params',data);
                await axios.post(this.api + "/products/search",data)
                    .then(({ data }) => {
                        //console.log('Result',data);
                        if (data.length) {
                            const itms = data;
                            itms.forEach(element=>{
                                this.listas.push(element);
                                this.records = element.TotalRows;
                            });
                            //this.listas = itms;
                            //data.forEach(element => { this.records = element.TotalRows; });
                            var numPages = Math.ceil(this.records/this.numShow);
                            this.firstRecord = page - (page % 10) + 1;
                            //console.log('FirstRecord',this.firstRecord, numPages, this.records);
                            if (this.firstRecord > page) { this.firstRecord = this.firstRecord - 10; }
                            this.lastRecord = (this.firstRecord + 9) > this.records ? this.records : this.firstRecord +9;
                            if(this.lastRecord>numPages) this.lastRecord = numPages;
                            this.lastPage = numPages;
                        }else{
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                        this.goTop();
                });// end axios call
                    
            },// end function load api
            checkCategories(){
                const locations = new URLSearchParams(window.location.search);
                let loc = locations.get('cat_in');
                let arrloc = [];
                let newselected = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                if(loc){
                    newselected = "AND(";
                    arrloc = loc.split(',');
                    if(arrloc.includes("bike")){ 
                        newselected = " AND(T0.Bikes='Y' OR T0.Motos='N') "; 
                    } 
                    if(arrloc.includes("moto")){ 
                        newselected = " AND(T0.Bikes='N' OR T0.Motos='Y') ";
                    }
                    if(arrloc.includes("moto") && arrloc.includes("bike")){ 
                        newselected = " AND(T0.Bikes='Y' OR T0.Motos='Y') ";
                    }
                    if(arrloc.includes("all")){
                        if(this.userData.location==='M'){ // moto
                            newselected = "AND(T0.Bikes='N' OR T0.Motos='Y')";
                        }else if(this.userData.location==='C'){ // bici
                            newselected = "AND(T0.Bikes='Y' OR T0.Motos='N')";
                        }else{
                            newselected = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                        }
                    }
                }
                return newselected;
            },
            onChange(event) {
                let parameters = window.location.search;
                var orderBySend = `orderby=${this.orderby}`;
                if(parameters.length>0){
                    var updateparameter = getParameterByName("orderby");
                    if(updateparameter){ 
                        var change = "orderby="+updateparameter;
                        parameters = parameters.replace(change,orderBySend);
                    }else{
                        parameters += `&${orderBySend}`;
                    }
                }else{
                    parameters = `?${orderBySend}`;
                }
                let urlSend = `${window.location.origin}${window.location.pathname}${parameters}`;
                window.location = urlSend;
            },
            onChangePage(event) {
                this.loadProducts(1);
            },
            onStock(event) {
                //console.log(this.checkedStock);
                let parameters = window.location.search;
                var stock="stock=Y";
                if(this.checkedStock){ stock="stock=N"; }
                if(parameters.length>0){
                    var updateparameter = getParameterByName("stock");
                    if(updateparameter){ 
                        var change = "stock="+updateparameter;
                        parameters = parameters.replace(change,stock);
                    }else{
                        parameters += `&${stock}`;
                    }
                }else{
                    parameters = `?${stock}`;
                }
                let urlSend = `${window.location.origin}${window.location.pathname}${parameters}`;
                window.location = urlSend;
            },
        },// end methods
    };
</script>

<style >
ul.paginate-links > li.disabled > a { /* it doesn't work */
    color: red;
}
ul.paginate-links > li.number > a { /* it doesn't work, too */
    color: blue;
}
.noneborder{
    border:none!important;
}
.centertd {
    text-align: center;
    vertical-align: middle!important;
}
</style>
