<template>
    <div>
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="block block-sidebar block-sidebar--offcanvas--always">
            <div class="block-sidebar__backdrop"></div>
            <div class="block-sidebar__body">
                <div class="block-sidebar__header">
                    <div class="block-sidebar__title">Filtros</div>
                    <button class="block-sidebar__close" type="button">
                        <svg width="20px" height="20px">
                            <use :xlink:href="ubk+'images/sprite.svg#cross-20'"></use>
                        </svg>
                    </button>
                </div>
                <div class="block-sidebar__item">
                    <div class="widget-filters widget widget-filters--offcanvas--always" data-collapse data-collapse-opened-class="filter--opened">
                        <h4 class="widget-filters__title widget__title">Filtros</h4>
                        <div class="widget-filters__list">

                            <!-- categories -->
                            <div v-if="seeCat" class="widget-filters__item">
                                <div  class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Categoría
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item ">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input :checked="catb"  class="input-check__input" type="checkbox" value="bike" @change="checkFilter('cat','bike')">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title">
                                                            Bicicletas
                                                        </span>
                                                    </label>

                                                    <label class="filter-list__item ">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input :checked="catm"  class="input-check__input" type="checkbox" value="moto" @change="checkFilter('cat','moto')">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title">
                                                            Motocicletas
                                                        </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <!-- familia -->
                            <div v-if="seeFam" class="widget-filters__item">
                                <div  class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Familias
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item " v-for="(fam,index) in families" :key="index">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" type="checkbox" :value="fam.Code" v-model="chkfam" @change="checkFilter('fam',fam.Code)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title">
                                                            {{fam.Name}}
                                                        </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- subfamilia -->
                            <div v-if="seeSubFam" class="widget-filters__item">
                                <div class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Sub-Familia
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item " v-for="(row,index) in subfamilies" :key="index">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" type="checkbox" :value="row.Code" v-model="chksub" @change="checkFilter('subfam',row.Code)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title"> {{row.Name}} </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- subfamilia -->
                            <div v-if="seeSubFam2" class="widget-filters__item">
                                <div class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Sub-Familia 2
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item " v-for="(row,index) in subfamilies2" :key="index">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" type="checkbox" :value="row.Code" v-model="chksub" @change="checkFilter('subfam2',row.Code)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title"> {{row.Name}} </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- marcas -->
                            <div v-if="seeBrand" class="widget-filters__item">
                                <div class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Marca
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item " v-for="(row,index) in brands" :key="index">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" type="checkbox" :value="row.Code" v-model="chkbra" @change="checkFilter('brand',row.Code)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title"> {{row.Name}} </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Especificaciones
                            <div v-if="seeEspecifi" class="widget-filters__item">
                                <div class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Especificaciones
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-list">
                                                <div class="filter-list__list">

                                                    <label class="filter-list__item " v-for="(row,index) in especific" :key="index">
                                                        <span class="filter-list__input input-check">
                                                            <span class="input-check__body">
                                                                <input class="input-check__input" type="checkbox" :value="row.Code" v-model="chkesp" @change="checkFilter('spects',row.Name)">
                                                                <span class="input-check__box"></span>
                                                                <svg class="input-check__icon" width="9px" height="7px">
                                                                    <use :xlink:href="ubk+'images/sprite.svg#check-9x7'"></use>
                                                                </svg>
                                                            </span>
                                                        </span>
                                                        <span class="filter-list__title"> {{row.Name}} </span>
                                                    </label>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                             -->
                            <!-- Price -->
                            <div class="widget-filters__item">
                                <div class="filter filter--opened" data-collapse-item>
                                    <button type="button" class="filter__title" data-collapse-trigger>
                                        Precio
                                        <svg class="filter__arrow" width="12px" height="7px">
                                            <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-down-12x7'"></use>
                                        </svg>
                                    </button>
                                    <div class="filter__body" data-collapse-content>
                                        <div class="filter__container">
                                            <div class="filter-price  d-flex">
                                                <label for="range1"> De
                                                    <input id="range1" type="range" class="custom-range" :min="pmin1" :max="pmax1" v-model="chkp1" @change="checkFilter('prices')">
                                                    <h6 class="text-left">{{chkp1 | currency}}</h6>
                                                </label>
                                                <label for="range2"> Hasta
                                                    <input id="range2" type="range" class="custom-range" :min="pmin2" :max="pmax2"  v-model="chkp2" @change="checkFilter('prices')">
                                                    <h6 class="text-right">{{chkp2 | currency}}</h6>
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="bootompost">
                        <div class="d-flex justify-content-around">
                            <button class="btn btn-primary btn-sm" @click="checkFilter('send')">Aplicar</button>
                            <button class="btn btn-secondary btn-sm" @click="checkFilter('reset')">Limpiar</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    props: [
        'action','search','options','ubk','param01','param02','param03','param04',
        'param05','param06','param07','param08','param09','param10','param11','param12','param13','param14'],
    data () {
        return {
            api:process.env.MIX_API_URL,
            chktrue:true,
            chkfalse:false,
            chkfam:[],
            chksub:[],
            chkbra:[],
            chkesp:[],
            chkp1:0,
            chkp2:0,
            location:"B",
            userData:null,
            isLoading: false,
            categories:[],
            families:[],
            subfamilies:[],
            subfamilies2:[],
            brands:[],
            especific:[],
            prices1:[],
            prices2:[],
            seeCat:false,
            seeFam:true,
            seeSubFam: true,
            seeSubFam2: true,
            seeBrand:true,
            catb:false,
            catm:false,
            seeEspecifi:true,
            pmin1:0,
            pmax1:1,
            pmin2:2,
            pmax2:3,
            sendCat:[],
            sendFam:[],
            sendSubFam:[],
            sendSubFam2:[],
            sendBrand:[],
            sendSpects:[],
            sendPrices:[],
            loadFam:[],
        }
    },
    components: { Loading },
    mounted() {
        //console.log('Action',this.action);
        this.location = this.options.location;
        this.userData = this.options;
        this.loadfiltres();
    },
    methods: {
        async loadfiltres(){
            //this.isLoading = true;
            this.sendCat = [];
            this.sendFam = [];
            this.sendSubFam=[];
            this.sendBrand=[];
            this.sendSpects=[];
            this.sendPrices=[];
            let vm = this;
            let Param1 = '';
            let Param2 = '';
            let Param3 = '';
            let Param4 = '';
            let Param5 = '';
            let Param6 = '';
            let Param7 = '';
            let Param8 = '';
            let Param9 = '';
            let Param10 = '';
            let Param11 = '';
            let Param12 = '';
            let Param13 = '';
            let Param14 = '';
            if(this.userData.location==='M'){ // moto
                Param1 = "AND(T0.Bikes='N' OR T0.Motos='Y')";
                this.catm = true;
            }else if(this.userData.location==='C'){ // bici
                Param1 = "AND(T0.Bikes='Y' OR T0.Motos='N')";
                this.catb = true;
            }else{
                Param1 = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                this.seeCat = true;
                this.catb = true;
                this.catm = true;
                this.sendCat.push('bike');
                this.sendCat.push('moto');
                Param1 =  this.checkCategories();
            }
            //console.log('Param1-1',Param1, vm.action);
            var search = ['micrositio', 'search'];
            if( search.includes(vm.action) ){
                //console.log('Param1-2',Param1);
                if(vm.param01.length>0){
                    Param1 = vm.param01;
                }
                Param2 = vm.param02;
                Param3 = vm.param03;
                Param4 = vm.param04;
                Param5 = vm.param05;
                Param6 = vm.param06;
                Param7 = vm.param07;
                Param8 = vm.param08;
                Param9 = vm.param09;
                Param10 = vm.param10;
                Param11 = vm.param11;
                Param12 = vm.param12;
                Param13 = vm.param13;
                Param14 = vm.param14;
            }else{
                /*
                if(this.userData.location==='M'){ // moto
                    Param1 = "AND(T0.Bikes='N' OR T0.Motos='Y')";
                }else if(this.userData.location==='C'){ // bici
                    Param1 = "AND(T0.Bikes='Y' OR T0.Motos='N')";
                }else{
                    Param1 = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
                }
                */
                Param2 = 'N';
                Param3 = vm.param03;
                Param4 = vm.param04;
                Param5 = vm.param05;
                Param6 = vm.param06;
                Param7 = vm.param07;
                Param8 = vm.param08;
                Param9 = vm.param09;
                Param10 = vm.action;
                Param11 = 'N';
                Param12 = vm.param12;
                Param13 = vm.param13;
                Param14 = vm.param14;
            }
            let data = {
                'Action':'FILTERS-SEARCH',
                'Param1':`${Param1}`,
                'Param2':`${Param2}`,
                'Param3':`${Param3}`,
                'Param4':`${Param4}`,
                'Param5':`${Param5}`,
                'Param6':`${Param6}`,
                'Param7':`${Param7}`,
                'Param8':`${Param8}`,
                'Param9':`${Param9}`,
                'Param10':`${Param10}`,
                'Param11':"Y",
                'Param12':`${Param12}`,
                'Param13':`${Param13}`,
                'Param14':`${Param14}`,
                'Page':1,
                'Size':20
            };
            let fami = [];
            let subfami = [];
            let subfami2 = [];
            let bran = [];
            let especifi = [];
            let price = [];
            this.loadFam = Param4.split(',');
            const div = document.createElement('div');
            //console.log('DatasFiltera',data);
            await axios.post(this.api + "/products/search",data)
                .then(({ data }) => {
                    var result = data;
                    //console.log('Filtros',result);
                    result.forEach(element=>{
                        fami.push({ "Name":element.Categorie, "Code":element.CodeCat});
                        if(element.SubCategorie.length>=1) subfami.push({ "Name":element.SubCategorie, "Code":element.CodeSubCat, "op":"SUB1"});
                        if(element.SubCategorie2.length>=1) subfami2.push({ "Name":element.SubCategorie2, "Code":element.CodeSubCat2, "op":"SUB2"});
                        if(element.Brand.length>=1) bran.push({ "Name":element.Brand, "Code":element.CodeBrand});
                        if(element.Details.length>1) especifi.push({ "Name":element.Details, "Code":element.Details});
                        price.push(element.Price);
                    });
                    //let family = fami.filter((el, index) => fami.indexOf(el) === index)
                    vm.families = repeatElements(fami);
                    if(fami.length<=0) this.seeFam=false;
                    vm.subfamilies = repeatElements(subfami);
                    if(subfami.length<=0) this.seeSubFam=false;
                    vm.subfamilies2 = repeatElements(subfami2);
                    if(subfami2.length<=0) this.seeSubFam2=false;
                    vm.brands = repeatElements(bran);
                    if(bran.length<=0) this.seeBrand=false;
                    vm.especific = repeatElements(especifi);
                    if(especifi.length<=0) this.seeEspecifi=false;
                    price.sort(function(a, b){return b-a});
                    price.reverse();
                    this.pmin1 = 0;
                    this.pmax1 = price[price.length-1];

                    this.pmin2 = 0;
                    this.pmax2 = price[price.length-1];

                    this.chkp1 = 0;
                    this.chkp2 = price[price.length-1];
                    //console.log(this.chkp2, price);
                    //vm.prices2 = JSON.parse(prices[prices.length-1]);
                    //var newFam = repeatElements(fami.sort());
                    //for (var key in newFam){ vm.families.push({"key":key,"value":newCat[key]}); }
                })
                .catch(error => {
                    console.log("error", error);
                }).finally(() => {
                    this.isLoading = false;
                    //console.log('prices',vm.prices1,vm.prices2)
                });

        },// end function load api
        checkCategories(){
            
            const locations = new URLSearchParams(window.location.search);
            let loc = locations.get('cat_in');
            let arrloc = [];
            let newselected = "AND(T0.Bikes='Y' OR T0.Motos='Y')";
            if(loc){
                newselected = "AND(";
                arrloc = loc.split(',');
                this.catb = false;
                this.catm = false;
                if(arrloc.includes("bike")){ 
                    newselected = " AND(T0.Bikes='Y' OR T0.Motos='N') "; 
                    this.catb = true;
                    this.sendCat = [];
                    this.sendCat.push('bike');
                } 
                if(arrloc.includes("moto")){ 
                    newselected = " AND(T0.Bikes='N' OR T0.Motos='Y') "; 
                    this.catm = true;
                    this.sendCat = [];
                    this.sendCat.push('moto');
                }
                if(arrloc.includes("moto") && arrloc.includes("bike")){ 
                    this.catb = true;
                    this.catm = true;
                    this.sendCat = [];
                    this.sendCat.push('bike');
                    this.sendCat.push('moto');
                    newselected = " AND(T0.Bikes='Y' OR T0.Motos='Y') ";
                }
                if(arrloc.includes("all")){
                    if(this.userData.location==='M'){ // moto
                        ewselected = " AND(T0.Bikes='N' OR T0.Motos='Y') "; 
                        this.catm = true;
                        this.sendCat = [];
                        this.sendCat.push('moto');
                    }else if(this.userData.location==='C'){ // bici
                        newselected = " AND(T0.Bikes='Y' OR T0.Motos='N') "; 
                        this.catb = true;
                        this.sendCat = [];
                        this.sendCat.push('bike');
                    }else{
                        this.catb = true;
                        this.catm = true;
                        this.sendCat = [];
                        this.sendCat.push('bike');
                        this.sendCat.push('moto');
                        newselected = " AND(T0.Bikes='Y' OR T0.Motos='Y') ";
                    }
                }
            }
            return newselected;
        },
        checkFilter(op,value=null){
            //fam subfam brand spects prices send reset
            //console.log(window.location);
            if(op==='cat'){ 
                if(!this.sendCat.includes(value)){          //checking weather array contain the id
                    this.sendCat.push(value);               //adding to array because value doesnt exists
                }else{
                    this.sendCat.splice(this.sendCat.indexOf(value), 1);  //deleting
                }
            }
            
            if(op==='fam'){ this.sendFam.push(value); }
            if(op==='subfam'){ this.sendSubFam.push(value); }
            if(op==='subfam2'){ this.sendSubFam2.push(value); }

            if(op==='brand'){ this.sendBrand.push(value); }
            if(op==='spects'){ this.sendSpects.push(value); }
            if(op==='prices'){ this.sendPrices = `${this.chkp1}_AND_${this.chkp2}` }

            if(op==='send'){
                let locations = getParameterByName('location');
                locations = locations ? '&location='+locations : '';

                let stock = getParameterByName('stock');
                stock = stock ? '&stock='+stock : '';

                let orderby = getParameterByName('orderby');
                orderby = orderby ? '&orderby='+orderby : '';

                let urlSend = `${window.location.origin}${window.location.pathname}?init=0${locations}${stock}${orderby}`;
                //console.log('1',urlSend);
                // Search Query
                if(this.action==='search'){
                    urlSend += '&search='+encodeURIComponent(this.search);
                }
                // SEND Categories Bike or Moto
                if(this.sendCat.length>0){
                    urlSend += '&cat_in=';
                    this.sendCat.forEach(element=> urlSend += element+',' );
                    urlSend = urlSend.slice(0, -1);
                }else{ if(this.param04.length>0){ urlSend += '&cat_in='+this.param04.replace(/['"]+/g, ''); } }

                // SEND SUBFAMILY 1 AND 2
                if(this.sendFam.length>0){
                    urlSend += '&fam_in=';
                    this.sendFam.forEach(element=> urlSend += element+',' );
                    urlSend = urlSend.slice(0, -1);
                }else{ if(this.param04.length>0){ urlSend += '&fam_in='+this.param04.replace(/['"]+/g, ''); } }

                // SEND SUBFAMILY
                if(this.sendSubFam.length>0){
                    urlSend += '&subfam_in=';
                    this.sendSubFam.forEach(element=> urlSend += element+',' );
                    urlSend = urlSend.slice(0, -1);
                }else{ if(this.param05.length>0){ urlSend += '&subfam_in='+this.param05; } }
                
                // SEND SUBFAMILY2
                if(this.sendSubFam2.length>0){
                    urlSend += '&subfam2_in=';
                    this.sendSubFam2.forEach(element=> urlSend += element+',' );
                    urlSend = urlSend.slice(0, -1);
                }else{ if(this.param05.length>0){ urlSend += '&subfam2_in='+this.param05; } }
                

                // SEND BRAND
                if(this.sendBrand.length>0){
                    urlSend += '&brand_in=';
                    this.sendBrand.forEach(element=> urlSend += element+',' );
                    urlSend = urlSend.slice(0, -1);
                }else{ if(this.param06.length>0){ urlSend += '&brand_in='+this.param06; } }
                // SEND SPECTS
                if(this.sendSpects.length>0){
                    urlSend += '&spects_in=';
                    this.sendSpects.forEach(element=> urlSend += element+',');
                    urlSend = urlSend.slice(0, -1);
                }else{
                    if(this.param07.length>0){ urlSend += '&spects_in='+this.param07.replace(/['"]+/g, ''); }
                }
                // SEND PRICES
                if(this.sendPrices.length>0){
                    urlSend += '&prices_in='+this.sendPrices;
                }else{ if(this.param08.length>0){ urlSend += '&prices_in='+this.param08.replace(/[ ]+/g, '_'); } }
                //console.log('URL',urlSend)

                window.location = urlSend;
            }
            if(op==='reset'){
              let urlSend = `${window.location.origin}${window.location.pathname}?init=0`;
              if(this.action==='search'){
                  urlSend += '&search='+encodeURIComponent(this.search);
              }
              window.location = urlSend;
              //$('input[type=checkbox]').prop('checked', false);
            }
        }
    },
};
</script>

<style>

</style>
