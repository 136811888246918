<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="true" :is-full-page="true"></loading>
	    <!-- .block-posts -->
        <div v-if="records>0" class="block block-posts my-4" data-layout="grid-4" data-mobile-columns="2">
            <div class="container">
                <div class="block-header">
                    <h3 class="block-header__title">{{title}}</h3>
                    <div class="block-header__divider"></div>
                    <div class="block-header__arrows-list">
                        <button class="block-header__arrow block-header__arrow--left" type="button" @click="clickcaroucel('prev')">
                            <svg width="7px" height="11px">
                                <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-left-7x11'"></use>
                            </svg>
                        </button>
                        <button class="block-header__arrow block-header__arrow--right" type="button" @click="clickcaroucel('next')">
                            <svg width="7px" height="11px">
                                <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-right-7x11'"></use>
                            </svg>
                        </button>
                    </div>
                </div>
                <div class="block-posts__slider">
                    <carousel
                        :autoplay="true"
                        :items="4"
                        :margin="15"
                        :responsive="{0:{items:1,nav:false},900:{items:3,nav:true},990:{items:4,nav:true}}"
                        :autoplayHoverPause="true"
                    >
                        <template slot="prev"><span :class="'owl-prev owl-prev-'+action">prev</span></template>
                        <template slot="next"><span :class="'owl-next owl-next-'+action">next</span></template>

                        <!-- Begin loop data for -->
                        <div class="post-card" v-for="(row, index) in listas" :key="index">
                            <div class="post-card__image">
                                <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                    <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                </a>
                            </div>
                            <div class="post-card__info">
                                <div class="post-card__category">
                                    <a href="">Special Offers</a>
                                </div>
                                <div class="post-card__name" style="height: 70px;">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{row.ItemName}}</a>
                                </div>
                                <div class="post-card__date">{{row.ItemCode}}</div>
                                <div class="post-card__content">
                                    <ul class="product-card__features-list">
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>
                                    </ul>
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="btn btn-warning btn-block btn-sm">Ver detalles</a>
                                </div>
                            </div>
                        </div>
                        <!-- END loop data for -->
                    </carousel>
                </div> <!-- end block-posts__slider -->
            </div>
        </div>
        <!-- end -->
        <!-- .block-posts / end -->
	</div>
</template>
<script>
import axios from "axios";
	// Import component
import Loading from 'vue-loading-overlay';
import product from './PrintProductComponent';
import carousel from 'vue-owl-carousel'

export default {
	props: ['action', 'title', 'options', 'ubk', 'item', 'cardcode', 'productdb'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            userData:null,
            isLoading: false,
            records:0,
            showPrice:false,
            listas:[],
            links:'',
        }
	},
	components: { Loading, product, carousel},
	mounted() {
        //console.log(this.action,this.side,this.title);
        //console.log(this.productdb);
        this.userData = this.options;
        this.type = this.action;
        this.links = this.ubk+"search?init=0&search=*";
        this.loadItems();
    },
    methods: {
        	async loadItems(){
                //this.isLoading = true;
                let vm = this;
                let locationB = 'Y';
                let locationM = 'Y';
                if(this.userData.location==='C'){
                    locationB = 'Y';
                    locationM = 'N';
                }
                if(this.userData.location==='M'){
                    locationB = 'N';
                    locationM = 'Y';
                }
                var categorie = '';
                var SubCategorie2 = '';
                if(this.productdb){
                    categorie = this.productdb.Categorie;
                    SubCategorie2 = this.productdb.SubCategorie2;
                }
                let data = {
                    'Action':this.action,
                    'Param1':this.item,
                    'Param2':this.cardcode,
                    'Param3':`${categorie}`,
                    'Param4':`${SubCategorie2}`,
                    'Param5':locationB,
                    'Param6':locationM,
                    'Param7':'',
                    'Param8':'',
                    'Param9':'',
                    'Param10':'',
                    'Param11':'Y',
                    'Param12':'Y',
                    'Param13':'',
                    'Param14':'',
                    'Page':1,
                    'Size':20
                };
                //console.log(data);
                await axios.post(this.api + "/products/search",data)
                    .then(({ data }) => {
                        //console.log('Result',data);
                        this.records = data.length;
                        this.listas = data;
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                	});// end axios call
            },// end function load api   this.loading = false
            clickcaroucel(action){
                $('.owl-'+action+'-'+this.action).click();
            },
            addProductCart(item){
                console.log('Add Cart Item ',item);
            },
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            onCancel() {
              console.log('User cancelled the loader.')
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            },
        },
};
</script>

<style>
.owl-prev, .owl-next{
    display: none!important;
}
</style>
