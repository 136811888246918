<template>
    <div>
        <div v-show="action==='TOP'" class="dropcart__body">
            <input name="cartNum" id="cartNum" type="hidden" v-model="cartNum" v-on:click="onChange">
            <h5 class="text-center text-muted mt-3" v-show="items.length<=0">Carrito Vacio</h5>
            <div class="dropcart__products-list">

                <div class="dropcart__product" v-for="(row, index) in items.slice(0, limit)" v-bind:key="index">
                    <div class="product-image dropcart__product-image">
                        <a :href="ubk+'details/product/'+row.id+'/'+sanitizeTitle(row.name)" class="product-image__body">
                            <img v-bind:src="ubk+'Items/'+row.id+'.jpg'" v-bind:alt="row.id" class="product-image__img" @error="imageUrlAlt">
                        </a>
                    </div>
                    <div class="dropcart__product-info">
                        <div class="dropcart__product-name">
                            <a :href="ubk+'details/product/'+row.id+'/'+sanitizeTitle(row.name)" :title="row.name">{{row.name | truncate(20) }}</a>
                        </div>
                        <ul class="dropcart__product-options">
                            <li :title="row.options.Categorie">{{row.options.Categorie | truncate(30) }}</li>
                        </ul>
                        <div class="dropcart__product-meta">
                            <span class="dropcart__product-quantity">{{ row.qty | number('0,0')}}</span> ×
                            <span class="dropcart__product-price">{{ row.price | currency}}</span>
                        </div>
                    </div>
                    <button type="button" class="dropcart__product-remove btn btn-light btn-sm btn-svg-icon" v-on:click="onDelete(row.rowid, row)">
                        <svg width="10px" height="10px">
                            <use :xlink:href="assets+'/sprite.svg#cross-10'" class="fill-red"></use>
                        </svg>
                    </button>
                </div>

                <template v-if="items.length>limit">
                    <hr>
                    <p class="text-center text-danger">
                        <small>Ir al carrito para ver todos los artículos</small>
                    </p>
                </template>
                <div class="dropcart__totals">
                    <table>
                        <tr>
                            <th>Subtotal</th>
                            <td>{{ subtotal | currency }}</td>
                        </tr>
                        <tr v-show="showIva">
                            <th>IVA</th>
                            <td>{{ iva | currency}}</td>
                        </tr>
                        <tr>
                            <th>Total</th>
                            <td>{{ total | currency }}</td>
                        </tr>
                    </table>
                </div>
                <div class="dropcart__buttons">
                    <a class="btn btn-secondary" :href="ubk+'cart'">Ir al Carro</a>
                    <a v-if="btn_service_call" class="btn btn-primary" :href="ubk+'service'">Servicio</a>
                    <template v-else>
                        <a class="btn btn-primary" :href="ubk+'checkout'">{{nameButtonPay}}</a>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>

<style>
   .r { align-items: center; }
   .fill-red{
    fill: red;
   }
</style>

<script>
    // Import component
    import Loading from 'vue-loading-overlay';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        props: ['action','assets','num','options','ubk'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                userData:null,
                isLoading: false,
                items:[],
                cartNum:0,
                limit:5,
                iva:0.00,
                subtotal:0.0,
                total:0.0,
                showIva:true,
                nameButtonPay:'Pagar',
                btn_service_call: false,
            }
        },
        components: { Loading },
        mounted() {
            this.userData = this.options;
            this.cartNum = this.num;
            this.iva = 0.00;
            this.subtotal = 0.0;
            this.total = 0.0;
            if(this.userData.WHOLESALER==='Y') this.nameButtonPay = 'Continuar';
            if(this.userData.SALESMAN==='Y') this.nameButtonPay = 'Continuar';
            this.btn_service_call = this.userData.service_call;
            //console.log('UserdataSDoc',this.userData);
            this.loadCartTop();
        },
        methods:{
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            async loadCartTop(){
                this.items = [];
                await axios.get(this.ubk+'loadcartmenu').then(response => {
                    let dat = response.data;
                    //console.log('Item',dat);
                    this.items = dat;
                    let sub=0, iv=0;
                    dat.forEach(element => {
                        if( parseInt(this.userData.list_price)===3 || parseInt(this.userData.list_price)===7 ){
                            if(this.action==='TOP'){ this.showIva=false; }
                            this.showIva=false;
                            sub += element.qty*element.price;
                            iv += 0;
                            //console.log('no suma',iv);
                        }else{
                            //var priceNew = parseFloat(element.price) / 1.16;
                            var priceNew = parseFloat(element.price);
                            sub += element.qty*priceNew;
                            iv += (element.qty*priceNew)*0.16;
                        }
                        //console.log("showIva", this.showIva);
                    });
                    this.subtotal = sub;
                    this.iva = iv;
                    this.total = sub + iv;
                })
                .catch(error => {
                    console.log("error", error);
                })
                .finally(() => {this.isLoading = false;  });
            },
            onChange(){
                this.loadCartTop();
            },
            onDelete(rowid, product){
                Swal.fire({
                    title:"Eliminar Artículo",
                    text: "Esta seguro de eliminar el artículo?",
                    icon: "info",
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'SI'
                }).then((result) => {
                    if (result.value) {
                        delCartJS(rowid,this.ubk, product);
                    }
                });
            },
            sanitizeTitle: function(title) {
                return this.$func.sanitizeTitleURLVue(title);
            },
        },
    };
</script>