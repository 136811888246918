<template>
    <div>
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="row">
            <div class="col-12 col-md-10">
                <input type="search" class="form-control" id="searchclient" v-model="searchclient" placeholder="Buscar clientes por código, correo o nombre" v-on:keyup.enter="search">
            </div>
            <div class="col-12 col-md-2">
                <button class="btn btn-outline-secondary btn-block" type="button" @click="search">Buscar</button>
            </div>
        </div>
        <div class="my-3">
            <button type="button" class="btn btn-info" @click="goToUrl('p')">Prospectos</button>
            <button type="button" class="btn btn-primary" @click="goToUrl('c')">Nuevos Clientes</button>  
        </div>
        
        <div class="mt-3 table-responsive">
            <h3>Listado de Clientes</h3>
            <table class="table">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Nombre</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(userdb, index) in users" :key="index">
                        <td>{{userdb.CardCode}}</td>
                        <td>
                            {{userdb.CardName}}
                            <span v-if="userdb.Balance>0"> ({{ userdb.Balance | currency }}) </span>
                        </td>
                        <td>
                            <button class="btn btn-outline-secondary btn-block" type="button" @click="selectedUser(userdb)">Seleccionar</button>
                        </td>
                    </tr>
                </tbody>
            </table>

        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props: ['show','slpcode','user','ubk'],
    data () {
        return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            loadingText: false,
            userd:null,
            userData:[],
            users:[],
            searchclient:'',
        }
    },
    components: { Loading },
    mounted() {
        /*
        CardCode: "28806", CardName: "Joaquin Barranco Orozco", SALESMAN: "N", WHOLESALER: "N", list_price: 7, list_price_type: "PUBLICO", location: "B", showcart: true, EMPID:0
        */
        this.userData = this.user;
        this.callApi();
    },
    methods: {
        async callApi(){
            this.isLoading = true;
            this.header = [];
            var SPName = 'spWebOptionsPublic';
            var Param1 = 'GET-USERS';
            var Param2 = this.show;
            var Param3 = this.slpcode;
            var Param4 = this.searchclient;
            let data = {SPName,Param1,Param2,Param3, Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(({ data })=> { 
                //console.log(data);
                this.users = data; 
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        search(){
            //console.log('Search:',this.searchclient);
            this.callApi();
        },
        async selectedUser(userdb){
            this.isLoading = true;
            console.log("selectedUser:", userdb);
            await axios
            .post(this.ubk + "select_user",userdb)
            .then(response => {
                let result = response.data;
                var regimen = result.regimen??0
                //console.log(result, result.regimen, regimen, parseInt(regimen));
                
                if(parseInt(regimen)<=0){
                    window.location = this.ubk+"profile?alert=regimen";
                }else{
                    window.location = this.ubk+"my-alerts";
                }
                /**/
                //window.location = this.ubk+"my-alerts";
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        goToUrl(type){
            location.href = `${this.ubk}newuser/${type}`;
        }
    },
};
</script>

<style lang="css" scoped>
</style>
