<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report top30 -->
		<h3 class="text-center">Facturas a pagar por estatus</h3>
		<div class="table-responsive">
			<table class='table table-condensed table-striped font-type'>
				<thead>
					<tr>
						<th class='text-center'>Seleccionar</th>
						<th class='text-center'>Folio</th>
						<th class='text-center'>Fecha</th>
						<th class='text-center'>Fecha vencimiento</th>
						<th class='text-center'>Importe</th>
                        <th class='text-center'>Días de atraso</th>
                        <th class='text-center'>Intereses</th>
						<th v-if="seeDiscount" class='text-center'>DPP</th>
						<th v-if="seeDiscount" class='text-center'>Pague antes de</th>
						<th v-if="seeDiscount" class='text-center'>Pago pronto pago</th>
                        <th class='text-center'>Estatus</th>
                        <th v-if="seeNC" class='text-center'>Nota crédito</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row,index) in inv_due_client" :key="index">
						<td>
							<label>
								<input type="checkbox" name="pay" v-model="selected" :value="row" @change="change()">
							</label>
						</td>
						<td>
							{{ row.DocNum }}
						</td>
						<td>{{ row.DocDate }}</td>
						<td>{{ row.DocDueDate }}</td>
						<td class="text-right">{{  checkImpuesto(row,'balance') | currency }}</td>
                        <td class="text-center">{{ row.Dias | number('0,0') }}</td>
                        <td class="text-right">{{  (row.ConditionCode===209 ? 0 : checkImpuesto(row,'tax')) | currency }} </td>

						<td v-if="seeDiscount" class="text-right">
                            {{ row.dDiscount | number('0,0') }}
                        </td>
						<td v-if="seeDiscount" class="text-center">
                            <span v-if="row.dDiscount>0">{{ row.dateDPP }}</span>
                            <span v-else>-</span>
                        </td>
						<td v-if="seeDiscount" class="text-right">
                            <span v-if="row.action==='DPP' && row.dDiscount>0">{{ prontoPago('DPP',row) | currency }}</span>
                            <span v-else>-</span>
                        </td>
                        <td class="text-center">
                            <span v-if="row.action==='VN'">VENCIDO</span>
                            <span v-if="row.action==='PP'">POR VENCER</span>
                            <span v-if="row.action==='DPP'">PRONTO PAGO</span>
                        </td>
                        <td v-if="seeNC" class="text-right">
                            <span v-if="row.action==='DPP' && row.dDiscount>0">{{ prontoPago('NC',row) | currency }}</span>
                            <span v-else>-</span>
                        </td>
					</tr>
				</tbody>
				<tfoot>
					<tr v-if="seeBtnPay">
						<td colspan="11" align="left">
							<button :disabled="showButton" type="button" class="btn btn-primary" @click="togglePayment">
								Pagar
							</button>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
		<div v-if="userData.SALESMAN==='Y'">
			<h3 class="text-center">Facturas por vencer de mis clientes</h3>
			<div class="table-responsive">
				<table class='table table-condensed table-striped font-type'>
					<thead>
						<tr>
							<th class='text-center'>Folio</th>
							<th class='text-center'>Fecha</th>
							<th class='text-center'>Cliente</th>
							<th class='text-center'>Total</th>
							<th class='text-center'>Saldo</th>
							<th class='text-center'>30 Días</th>
							<th class='text-center'>60 Días</th>
							<th class='text-center'>90 Días</th>
							<th class='text-center'>+ 90 Días</th>
							<th class='text-center'>Días</th>
							<th class='text-center'>Intereses</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in inv_due_docs" :key="index" v-show="row.type==='S'">
							<td>{{ row.DocNum }}</td>
							<td>{{ row.DocDueDate }}</td>
							<td>{{ row.CardName }}</td>
							<td>{{ row.DocTotal | currency }}</td>
							<td>{{ row.noVencida | currency }}</td>
							<td>{{ row.d30 | currency }}</td>
							<td>{{ row.d60 | currency }}</td>
							<td>{{ row.d90 | currency }}</td>
							<td>{{ row.m90D | currency }}</td>
							<td>{{ row.Dias | number('0,0') }}</td>
							<td>{{ (row.ConditionCode===209 ? 0 : formatPrice(((row.DocTotal * 0.115131)/100)*row.Dias)) | currency }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		<!-- End Report top30 -->
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
let today = new Date();

export default {
  	props: ['user','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		userData:[],
    		inv_due_docs: [],
            inv_due_client: [],
    		isCheckAll: false,
    		showButton: true,
    		selected: [],
            seeDiscount: false,
            seeNC: false,
            seeBtnPay:false,
    	}
   	},
   	components: { Loading },
  	mounted() {
  		this.userData = this.user;
  		if(this.userData.CardCode==='658'){}
  		this.seeBtnPay = true;
  		
  		this.callApi();
  	},
   	methods: {
   		async callApi(){
   			this.isLoading = true;
            this.inv_due_docs = [];
            this.inv_due_client = [];
            let today_date = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
            var SPName = 'spWebOptionsPublic'
            var Param1 = 'WALLET-ALL';
            var Param2 = this.userData.CardCode;
            var Param3 = today_date;
            var Param4 = today_date;
            var Param5 = `${this.userData.EMPID}`;
            let data = {SPName, Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data;
                //console.log(result);
                this.seeDiscount = true;
                this.seeNC = false;
                let addDPP = 1;
                if(result.length>0){
                    result.forEach(element=>{
                        if(element.action==='VN'){
                            if(element.type==='C'){
                                addDPP = 0;
                                this.seeDiscount = false;
                            }
                        }
                        this.inv_due_docs.push(element);
                    });
                    result.forEach(element=>{
                        if(element.type==='C'){
                            if(element.action==='VN'){
                                this.inv_due_client.push(element);
                            }
                            if(element.action==='PP'){
                                this.inv_due_client.push(element);
                            }
                            if(addDPP===1){
                                if(element.action==='DPP'){
                                    this.inv_due_client.push(element);
                                    this.seeNC = true;
                                }
                            }
                            if(element.ConditionCode===209){
                                if(element.action==='DPP'){
                                    this.inv_due_client.push(element);
                                    this.seeNC = true;
                                }
                            }
                        }
                    });
                    //this.inv_due_client.sort(function(a, b){return b-a});
                }
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false;  });
        },
        addProductCart(item,itemname,categorie,price,qty,priceid){
            addCartJS(item,itemname,categorie,0,1);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        },
        formatPrice(value) {
        	return parseFloat(value) > 0 ? value : 0;
    	},
        prontoPago: function(type,row){
            let invoice = this.checkImpuesto(row,'balance');
            let nc = this.formatPrice(invoice*.035);
            if(type==='NC') return nc;
            if(type==='DPP') return this.formatPrice(invoice-nc);
        },
	    checkImpuesto: function(balance,op) {
  			let valor = 0;
	    	if(parseFloat(balance.noVencida)>0){
	    		if(op==='tax') return  this.formatPrice(((balance.noVencida * 0.115131)/100)*balance.Dias);
	    		else return balance.noVencida
	    	}
	    	if(parseFloat(balance.d30)>0){
	    		if(op==='tax') return  this.formatPrice(((balance.d30 * 0.115131)/100)*balance.Dias);
	    		else return balance.d30
	    	}
	    	if(parseFloat(balance.d60)>0){
	    		if(op==='tax') return  this.formatPrice(((balance.d60 * 0.115131)/100)*balance.Dias);
	    		else return balance.d60
	    	}
	    	if(parseFloat(balance.d90)>0){
	    		if(op==='tax') return  this.formatPrice(((balance.d90 * 0.115131)/100)*balance.Dias);
	    		else return balance.d90
	    	}
	    	if(parseFloat(balance.m90D)>0){
	    		if(op==='tax') return  this.formatPrice(((balance.m90D * 0.115131)/100)*balance.Dias);
	    		else return balance.m90D
	    	}
	    },
	    change: function() {
	    	if(this.selected.length===0){ this.showButton=true; }else{ this.showButton=false; }
	    },
	    async togglePayment() {
	    	let total = 0;
	    	let tax = 0;
	    	let cardcode = '';
	    	let invoices = '';
	    	let docnums = '';
	    	let send = false;
	    	let bill = "";
	    	let ship = "";
	    	let rfc = "";
            let isDPP = false;
            let noisDPP = false;
            let validAlert = false;
            let actions = 'PP-VN';
            let total_nc = 0;
	    	this.selected.forEach(element=>{
                send = true;
                var cnpp = 0;
                if(element.dDiscount>0){
                    if(element.action==='DPP'){
                        isDPP=true;
                        validAlert = true;
                    }else{
                        noisDPP=true;
                    }
                }
                if(validAlert){
                    if(isDPP && noisDPP){
                        Swal.fire('No puedes pagar facturas con estatus de pronto pago y facturas por vencer', '', 'info');
                        send = false;
                    }else{
                        // paymens whit NC and DPP
                        actions = 'DPP';
                        cnpp = this.prontoPago('NC',element);
                        var payment = this.prontoPago('DPP',element);
                        var impuesto = 0;//this.checkImpuesto(element,'tax');
                    }
                }else{
                    // payments PP and VN
                    actions = 'VN-PP-DPP';
                    cnpp = 0;
                    var payment = this.checkImpuesto(element,'total');
                    var impuesto = element.ConditionCode===209 ? 0 : this.checkImpuesto(element,'tax');
                }
                total_nc += cnpp;
                tax += impuesto;
                cardcode = element.CardCode
                total += payment;
                invoices += element.DocEntry+':'+payment+':'+impuesto+':'+cnpp+':'+element.DocNum+';';
                docnums += element.DocNum+'-';
                bill = element.bill;
                rfc = element.rfc;
                ship = element.ship;
	    	});
	    	if(send){
	    		this.isLoading = true;
	    		const user_id = this.userData.user_id;
	    		let type_payment = 'TODO';
	    		let data = {
                    user_id,
                    cardcode,
                    total,
                    'impuesto':tax,
                    invoices,
                    type_payment,
                    docnums,
                    bill,
                    ship,
                    rfc,
                    actions,
                    total_nc
                };
	    		//console.log('SendTable:',data);
	    		/**/
	    		await axios.post(`${this.ubk}pay-my-invoices`,data)
	            .then(response => {
	                let result = response.data;
	                //console.log(result);
	                window.location = `${this.ubk}pay-my-invoices/${result.reference}`;
	            })
	            .catch(error => { console.log("error", error); })
	            .finally(() => { this.isLoading = false; });
	    	}
	    },
   	},
};
</script>

<style lang="css" scoped>
</style>
