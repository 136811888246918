<template>
	<div>
		<div class="row">
            <div class="col-3 col-md-3 my-2 text-center" v-for="(brand, index) in brands" :key="index" >
            	<img 
            		:src="ubk+'media/catalogos/motos/logos/'+brand.file" 
            		:alt="brand.name" 
            		class="full-img cursor imglogo" 
            		@click="getFiles(brand.name)"
            	 >
            </div>
	    </div>
	    <hr>
	    <!-- .block-posts -->
	    <div class="container mt-4">
        	<div class="row">
        		<div class="col-6 col-md-3 cardshover" v-for="(row, index) in files" :key="index">
        			<a :href="links+row.name">
	        			<img 
		            		:src="dirSon+row.father+'/'+row.file" 
		            		:alt="row.name" 
		            		class="full-img cursor"
		            	>
	            		<div class="overlay"></div>
	            	</a>
        		</div>
        	</div>
    	</div>
        <!-- .block-posts / end -->
	</div>
</template>

<script>
export default {
	props: [ 'action', 'brands', 'elements', 'ubk'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            dirSon:'',
            links:'',
            files:[],
        }
	},
	mounted() {
		this.dirSon = this.ubk+'media/catalogos/motos/elements/';
		this.links = this.ubk+"search?location=CATMOTO&search=";
		this.getFiles(this.action);
	},
	methods: {
		getFiles(brand){
			this.files = [];
			var loopElements = this.elements;
			loopElements.forEach(element => {
				if(element.father === brand){
					this.files.push(element);
				}
			});
		}
	},
}
</script>

<style>
.imglogo{
	max-width: 180px;
}
</style>
