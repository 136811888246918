<template>
    <div >
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="products-view" v-if="action!='LANDING'">
        	<div class="view-options__legend text-right">{{records}} productos</div>
            <div class="view-options__divider mb-4"></div>
            <div  infinite-wrapper class="products-view__list products-list" data-layout="grid-5-full" data-with-features="false" data-mobile-grid-columns="2">

                <div class="products-list__body" >
                    <div  class="products-list__item" v-for="(item, index) in listas" :key="index">
                        <product
                            :item = "item"
                            :userData="userData"
                            :action="action"
                            :ubk="ubk"
                            :isSugerido="isSugerido"
                        />
                    </div>
                </div>

            </div>
            <div class="products-view__pagination" v-if="records>numShow">
                <ul class="pagination justify-content-center" style="flex-wrap: wrap;">
                    <li v-if="page===1" class="page-item disabled">
                        <button type="button" class="page-link page-link--with-arrow" href="" aria-label="Previous">
                            <
                        </button>
                    </li>
                    <li v-else class="page-item">
                        <button type="button" class="page-link page-link--with-arrow" @click="loadProducts(--page)" aria-label="Previous">
                            <
                        </button>
                    </li>
                    <div v-for="id in (firstRecord,lastRecord)" v-if="id >= firstRecord">
                        <li v-if="id===page" class="page-item active">
                            <button type="button" class="page-link" href="">{{id}} <span class="sr-only">(current)</span></button>
                        </li>
                        <li class="page-item" v-else>
                            <button type="button" class="page-link" @click="loadProducts(id)">{{id}}</button>
                        </li>
                    </div>

                    <li v-if="page===parseInt(lastPage)" class="page-item disabled">
                        <button type="button"  class="page-link page-link--with-arrow" aria-label="Next" disabled>
                            >
                        </button>
                    </li>
                    <li v-else class="page-item">
                        <button type="button" class="page-link page-link--with-arrow" @click="loadProducts(++page)" aria-label="Next">
                            >
                        </button>
                    </li>

                </ul>
            </div>
        </div>
    </div>
</template>

<script>

    import Loading from 'vue-loading-overlay';
    import pricescomponent from './PricesComponent';
    import product from './PrintProductComponent';
    // Import stylesheet
    import 'vue-loading-overlay/dist/vue-loading.css';
  
    export default {
        props: ['action','options','ubk','param1','param2'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                location:"B",
                userData:null,
                isLoading: false,
                listas:[],
                records:0,
                loop:0,
                firstRecord:0,
                lastRecord:0,
                lastPage:1,
                numShow:40,
                page:0,
                showPrice:false,
                backPage:'disabled',
                activePage:'active',
                infiniteId: +new Date(),
                links:'',
                orderby: 'RL',
                seeFav: true,
                checkedStock:false,
                salesman:false,
                isSugerido:false,
            }
        },// end data parameters
        components: { Loading, pricescomponent, product },
        mounted() {
            //console.log('data',this.options);
            this.location = this.options.location;
            this.userData = this.options;
            this.showPrice = this.userData.showcart;
            this.links = this.ubk+"search?init=0&search=*";
            this.orderby = this.param09;
            if(this.userData.CardCode === "20000"){ this.seeFav = false; }
            if(this.options.SALESMAN === "Y"){ this.salesman = true; }
            if(this.options.see_stock35 === "Y"){ this.salesman = true; }
            this.loadProducts(1);
        },
        methods: {
            goTop() {
                window.scrollTo(0,0);
            },
            async loadProducts(page = 1){
                this.listas = [];
                this.isLoading = true;
                let vm = this;
                let Param1 = this.userData.CardCode;
                let Param2 = vm.param1;
                let Param3 = vm.param2;
                let Param4 = '';
                let Param5 = '';
                let Param6 = '';
                let Param7 = '';
                let Param8 = '';
                let Param9 = '';
                let Param10 = '';
                let Param11 = '';
                let Param12 = '';
                let Param13 = '';
                let Param14 = '';
                this.page++;
                let data = {
                    'Action':this.action,
                    'Param1':`${Param1}`,
                    'Param2':`${Param2}`,
                    'Param3':`${Param3}`,
                    'Param4':`${Param4}`,
                    'Param5':`${Param5}`,
                    'Param6':`${Param6}`,
                    'Param7':`${Param7}`,
                    'Param8':`${Param8}`,
                    'Param9':`${Param9}`,
                    'Param10':`${Param10}`,
                    'Param11':`${Param11}`,
                    'Param12':`${Param12}`,
                    'Param13':`${Param13}`,
                    'Param14':`${Param14}`,
                    'Page':page,
                    'Size':this.numShow
                };
                if(Param10==='SUGGESTED')vm.isSugerido = true;
                //console.log('Params',data);
                await axios.post(this.api + "/products/search",data)
                    .then(({ data }) => {
                        //console.log('Result',data);
                        if (data.length) {
                            const itms = data;
                            itms.forEach(element=>{
                                this.listas.push(element);
                            });
                            data.forEach(element => { this.records = element.TotalRows; });
                            var numPages = Math.ceil(this.records/this.numShow);
                            this.firstRecord = page - (page % 10) + 1;
                            //console.log('FirstRecord',this.firstRecord, numPages, this.records);
                            if (this.firstRecord > page) { this.firstRecord = this.firstRecord - 10; }
                            this.lastRecord = (this.firstRecord + 9) > this.records ? this.records : this.firstRecord +9;
                            if(this.lastRecord>numPages) this.lastRecord = numPages;
                            this.lastPage = numPages;
                        }else{
                            
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                });// end axios call
                    
            },// end function load api
        },// end methods
    };
</script>

<style >
ul.paginate-links > li.disabled > a { /* it doesn't work */
    color: red;
}
ul.paginate-links > li.number > a { /* it doesn't work, too */
    color: blue;
}
.noneborder{
    border:none!important;
}
.centertd {
    text-align: center;
    vertical-align: middle!important;
}
</style>
