 <template>
    <div class="master"><!-- Master Div -->
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div v-if=" totals >0 " class="block block-products block-products--layout--large-first" data-mobile-grid-columns="2">
            <div class="container">
                <div class="block-header">
                    <h3 class="block-header__title">{{title}}</h3>
                    <div class="block-header__divider text-right">
                        <small>
                            <a v-show="action!=='DB'" v-bind:href="ubk+'products/promotions/GET-'+action">
                                + ver más artículos
                            </a>
                            <a v-show="action==='DB'" v-bind:href="ubk+'products/list/'+home_id">
                                + ver más artículos
                            </a>
                        </small>
                    </div>
                </div>
                <div class="block-products__body"><!-- products__body -->

                    <div v-if="side==='L'" class="block-products__featured"><!-- LEFT PRODUCT PRINCIPAL -->
                        <div class="block-products__featured-item" v-for="(row,index)  in principal" v-bind:key="index">
                            <div class="product-card product-card--hidden-actions ">
                                <!--
                                <button class="product-card__quickview" type="button" @click="loadModalProduct(row.ItemCode)">
                                    <svg width="16px" height="16px">
                                        <use :xlink:href="ubk+'images/sprite.svg#quickview-16'"></use>
                                    </svg>
                                    <span class="fake-svg-icon"></span>
                                </button> 
                                -->
                                <div v-show="seeFav">
                                    <button v-if="row.idfav==='0'" class="product-favorite" title="Agregar a favorito" type="button" @click="addFavorite($event, row.ItemCode, row)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#heart-20'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                    <button v-else class="product-favorite" title="Eliminar de favorito" type="button" @click="addFavorite($event, row.ItemCode)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#wishlist-16'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                </div>
                                <div class="product-card__badges-list">
                                    <div v-if="row.News==='Y'" class="new-badge">
                                        <img :src="ubk+'media/nuevo.svg'" alt="producto nuevo" width="15">
                                    </div>
                                    <div v-if="row.Offert==='Y'" class="new-badge">
                                        <img :src="ubk+'media/oferta.svg'" alt="producto con oferta" width="15">
                                    </div>
                                    <div v-if="row.Liquidation==='Y'" class="new-badge">
                                        <img :src="ubk+'media/liquidations.svg'" alt="producto en liquidacion" width="15">
                                    </div>
                                    <div v-if="row.OnRequest==='Y'" class="new-badge">
                                        <img :src="ubk+'media/sobrepedido.svg'" alt="producto sobre pedido" width="15">
                                    </div>
                                </div>
                                <div class="product-card__image product-image">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                        <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                    </a>
                                </div>
                                <div class="product-card__info">
                                    <div class="product-card__name text-justify">
                                        <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{row.ItemName}}</a><br>
                                        <div class="text-right"><small class="text-muted">{{row.ItemCode}}</small></div>
                                    </div>
                                    <ul class="product-card__features-list">
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.see_stock35==='Y'">
                                            <span v-if="userData.SALESMAN==='Y'" class="text-success">
                                                {{row.Stock | number('0,0')}} Disponible(s)
                                            </span>
                                            <div v-else>
                                                <span v-if="row.Stock>500" class="text-success">
                                                    +500 Disponibles
                                                </span>
                                                <span v-else class="text-success">
                                                    {{row.Stock | number('0,0')}} Disponible(s)
                                                </span>
                                            </div>
                                        </li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.WHOLESALER==='Y'">
                                            Pzas x multiplo: {{row.StockPack | number('0,0')}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="product-card__actions">
                                    <div class="product__actions-item">
                                        <div class="input-number product__quantity qty-action">
                                            <input :id="'qty_'+action+'_'+row.ItemCode" class="input-number__input form-control form-control-lg" type="number" min="1" value="1" @blur="onAction(action,row.ItemCode,row.Stock,'W')">
                                            <div class="input-number__add more" @click="onAction(action,row.ItemCode,row.Stock,'A')"></div>
                                            <div class="input-number__sub less" @click="onAction(action,row.ItemCode,row.Stock,'R')"></div>
                                        </div>
                                    </div>
                                    <div class="product-card__prices">
                                        <pricescomponent
                                            op="item"
                                            :item.sync="row.ItemCode"
                                            :price.sync="row.Price"
                                            :stock.sync="row.Stock"
                                            :options.sync="userData"
                                            :action.sync="action"
                                            :ubk="ubk"
                                            :row="row"
                                            home="floatbtn"
                                        ></pricescomponent>
                                    </div>
                                    <div class="product-card__buttons">
                                        <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart" type="button" @click="addProductCartNew(row)">
                                            Agregar al carro
                                        </button>
                                        <a v-if="!showPrice" class="btn btn-secondary btn-block product-card__addtocart" :href="ubk+'login'">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- LEFT PRODUCT PRINCIPAL END -->

                    <div class="block-products__list"><!-- products__list -->
                        <div class="block-products__list-item"  v-for="(row,index) in items" v-bind:key="index">
                            <div class="product-card product-card--hidden-actions ">
                                <div v-show="seeFav">
                                    <button v-if="row.idfav==='0'" class="product-favorite" title="Agregar a favorito" type="button" @click="addFavorite($event, row.ItemCode, row)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#heart-20'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                    <button v-else class="product-favorite" title="Eliminar de favorito" type="button" @click="addFavorite($event, row.ItemCode)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#wishlist-16'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                </div>
                                <div class="product-card__badges-list">
                                    <div v-if="row.News==='Y'" class="new-badge">
                                        <img :src="ubk+'media/nuevo.svg'" alt="producto nuevo" width="15">
                                    </div>
                                    <div v-if="row.Offert==='Y'" class="new-badge">
                                        <img :src="ubk+'media/oferta.svg'" alt="producto con oferta" width="15">
                                    </div>
                                    <div v-if="row.Liquidation==='Y'" class="new-badge">
                                        <img :src="ubk+'media/liquidations.svg'" alt="producto en liquidacion" width="15">
                                    </div>
                                    <div v-if="row.OnRequest==='Y'" class="new-badge">
                                        <img :src="ubk+'media/sobrepedido.svg'" alt="producto sobre pedido" width="15">
                                    </div>
                                </div>
                                <div class="product-card__image product-image">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                        <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                    </a>
                                </div>
                                <div class="product-card__info">
                                    <div class="product-card__name text-justify">
                                        <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{row.ItemName}}</a><br>
                                        <div class="text-right"><small class="text-muted">{{row.ItemCode}}</small></div>
                                    </div>
                                    <ul class="product-card__features-list">
                                        
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>

                                        <li v-if=" userData.SALESMAN==='Y' || userData.see_stock35==='Y'">
                                            <span v-if="userData.SALESMAN==='Y'" class="text-success">
                                                {{row.Stock | number('0,0')}} Disponible(s)
                                            </span>
                                            <div v-else>
                                                <span v-if="row.Stock>500" class="text-success">
                                                    +500 Disponibles
                                                </span>
                                                <span v-else class="text-success">
                                                    {{row.Stock | number('0,0')}} Disponible(s)
                                                </span>
                                            </div>
                                        </li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.WHOLESALER==='Y'">
                                            Pzas x multiplo: {{row.StockPack | number('0,0')}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="product-card__actions">
                                    <div class="product__actions-item">
                                        <div class="input-number product__quantity qty-action">
                                            <input :id="'qty_'+action+'_'+row.ItemCode" class="input-number__input form-control form-control-lg" type="number" min="1" value="1" @blur="onAction(action,row.ItemCode,row.Stock,'W')">
                                            <div class="input-number__add more" @click="onAction(action,row.ItemCode,row.Stock,'A')"></div>
                                            <div class="input-number__sub less" @click="onAction(action,row.ItemCode,row.Stock,'R')"></div>
                                        </div>
                                    </div>
                                   <div class="product-card__prices">
                                        <pricescomponent
                                            op="item"
                                            :item.sync="row.ItemCode"
                                            :price.sync="row.Precio"
                                            :stock.sync="row.exist"
                                            :options.sync="userData"
                                            :action.sync="action"
                                            :ubk="ubk"
                                            :row="row"
                                            home="floatbtn"
                                        ></pricescomponent>
                                    </div>
                                    <div class="product-card__buttons">
                                        <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart" type="button" @click="addProductCartNew(row)">
                                            Agregar al carro
                                        </button>
                                        <a v-if="!showPrice" class="btn btn-secondary btn-block product-card__addtocart" :href="ubk+'login'">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- products__list end -->

                    <div v-if="side==='R'" class="block-products__featured"><!-- RIGHT PRODUCT PRINCIPAL -->
                        <div class="block-products__featured-item" v-for="(row,index)  in principal" v-bind:key="index">
                            <div class="product-card product-card--hidden-actions ">
                                <div v-show="seeFav">
                                    <button v-if="row.idfav==='0'" class="product-favorite" title="Agregar a favorito" type="button" @click="addFavorite($event, row.ItemCode, row)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#heart-20'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                    <button v-else class="product-favorite" title="Eliminar de favorito" type="button" @click="addFavorite($event, row.ItemCode)">
                                        <svg width="16px" height="16px">
                                            <use :xlink:href="ubk+'images/sprite.svg#wishlist-16'"></use>
                                        </svg>
                                        <span class="fake-svg-icon"></span>
                                    </button>
                                </div>
                                <div class="product-card__badges-list">
                                    <div v-if="row.News==='Y'" class="new-badge">
                                        <img :src="ubk+'media/nuevo.svg'" alt="producto nuevo" width="15">
                                    </div>
                                    <div v-if="row.Offert==='Y'" class="new-badge">
                                        <img :src="ubk+'media/oferta.svg'" alt="producto con oferta" width="15">
                                    </div>
                                    <div v-if="row.Liquidation==='Y'" class="new-badge">
                                        <img :src="ubk+'media/liquidations.svg'" alt="producto en liquidacion" width="15">
                                    </div>
                                    <div v-if="row.OnRequest==='Y'" class="new-badge">
                                        <img :src="ubk+'media/sobrepedido.svg'" alt="producto sobre pedido" width="15">
                                    </div>
                                </div>
                                <div class="product-card__image product-image">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                        <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                    </a>
                                </div>
                                <div class="product-card__info">
                                    <div class="product-card__name text-justify">
                                        <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{row.ItemName}}</a><br>
                                        <div class="text-right"><small class="text-muted">{{row.ItemCode}}</small></div>
                                    </div>
                                    <ul class="product-card__features-list">
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.see_stock35==='Y'">
                                            <span v-if="userData.SALESMAN==='Y'" class="text-success">
                                                {{row.Stock | number('0,0')}} Disponible(s)
                                            </span>
                                            <div v-else>
                                                <span v-if="row.Stock>500" class="text-success">
                                                    +500 Disponibles
                                                </span>
                                                <span v-else class="text-success">
                                                    {{row.Stock | number('0,0')}} Disponible(s)
                                                </span>
                                            </div>
                                        </li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.WHOLESALER==='Y'">
                                            Pzas x multiplo: {{row.Stockpack | number('0,0')}}
                                        </li>
                                    </ul>
                                </div>
                                <div class="product-card__actions">
                                    <div class="product__actions-item">
                                        <div class="input-number product__quantity qty-action">
                                            <input :id="'qty_'+action+'_'+row.ItemCode" class="input-number__input form-control form-control-lg" type="number" min="1" value="1" @blur="onAction(action,row.ItemCode,row.Stock,'W')">
                                            <div class="input-number__add more" @click="onAction(action,row.ItemCode,row.Stock,'A')"></div>
                                            <div class="input-number__sub less" @click="onAction(action,row.ItemCode,row.Stock,'R')"></div>
                                        </div>
                                    </div>
                                    <div class="product-card__prices">
                                        <pricescomponent
                                            op="item"
                                            :item.sync="row.ItemCode"
                                            :price.sync="row.Price"
                                            :stock.sync="row.Stock"
                                            :options.sync="userData"
                                            :action.sync="action"
                                            :ubk="ubk"
                                            :row="row"
                                            home="floatbtn"
                                        ></pricescomponent>
                                    </div>
                                    <div class="product-card__buttons">
                                        <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart" type="button" @click="addProductCartNew(row)">
                                            Agregar al carro
                                        </button>
                                        <a v-if="!showPrice" class="btn btn-secondary btn-block product-card__addtocart" :href="ubk+'login'">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> <!-- RIGHT PRODUCT PRINCIPAL END -->

                </div><!-- products__body end -->
            </div>
        </div>
    </div><!-- Master Div end -->
</template>

<script>
	import axios from "axios";
    import $ from 'jquery';
	// Import component
	import Loading from 'vue-loading-overlay';
    import pricescomponent from './PricesComponent';
	// Import stylesheet
	import 'vue-loading-overlay/dist/vue-loading.css';
    //console.log(window.location);
    
	export default {
		props: ['action','side','title','options','ubk', 'items_home','home_id'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                userData:null,
                isLoading: false,
                totals:0,
                showPrice:false,
                items:[],
                principal:[],
                links:'',
                seeFav: true,
            }
        },
        components: { Loading, pricescomponent },
        mounted() {
            this.userData = this.options;
            this.showPrice = this.userData.showcart;
            this.links = this.ubk+"search?init=0&search=*";
            this.loadItems();
            if(this.userData.CardCode === "20000"){ this.seeFav = false; }
        },
        methods: {
        	async loadItems(){
                this.isLoading = true;
                let vm = this;
                let Param1 = 'GET-'+this.action;
                let Param2 = '';
                let Param3 = '';
                let Param4 = 'TOP'
                if(this.userData.location==='M'){ // moto
                    Param2 = 'N'; Param3 = 'Y';
                }else if(this.userData.location==='C'){ // bici
                    Param2 = 'Y'; Param3 = 'N';
                }else{
                    Param2 = 'Y'; Param3 = 'Y';
                }
                let Param5 = this.userData.CardCode;
                let Param6 = this.items_home;
                let data = { 'SPName':'spWebOptionsPublic',Param1,Param2,Param3,Param4,Param5,Param6,'Param7':'','Param8':'','Param9':'','Param10':''};
                //console.log(data);
                await axios
                    .post(this.api + "/spmodule/callsp",data)
                    .then(response => {
                        let result = response.data
                        //console.log(response);
                        let i=0;
                        result.forEach(element=>{
                            if(i===0) this.principal.push(element); else this.items.push(element);
                            i++;
                        });
                        this.totals=i;
                        //console.log('Item',this.items);
                    })
                    .catch(error => {
                        console.log("error", error);
                    })
                    .finally(() => {
                        this.isLoading = false;
                    } );
            },// end function load api   this.loading = false
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            loadModalProduct(item){
                // quickview.clickHandler.apply(this, arguments);
            },
            addFavorite(event, item, product){
                let str = event.currentTarget.innerHTML;
                //heart-20 wishlist-16
                if(str.includes("heart-20")){
                    var newstr = str.replace("heart-20", "wishlist-16");
                    event.currentTarget.innerHTML = newstr;
                }else{
                    var newstr = str.replace("wishlist-16","heart-20");
                    event.currentTarget.innerHTML = newstr;
                }
                addFavorite(item,this.ubk,product);
            },
            addProductCartNew(product){
                var sendQty = $(`#qty_${this.action}_${product.ItemCode}`).val();
                var sendPrice = $(`#price_${this.action}_${product.ItemCode}`).val();
                var sendDiscount = $(`#discount_${this.action}_${product.ItemCode}`).val();
                var item = product.ItemCode;
                var categorie = product.Categorie;
                var itemname = product.ItemName;
                var stock = product.Stock;
                addCartJS(item,itemname,categorie,sendPrice,sendQty,this.ubk,stock,product,'Home Page Products','',sendDiscount);
                //console.log('Add Cart Item ',item);
            },
            addProductCart(item,itemname,categorie,price,qty,priceid, stock, product){
                var sendQty = $('#'+qty).val();
                var sendPrice = $('#'+priceid).val();
                //console.log(sendQty,sendPrice,price);
                addCartJS(item,itemname,categorie,price,sendQty,this.ubk,stock,product,'Home Page Products');
                //console.log('Add Cart Item ',item);
            },
            onAction(ubk,item,stock,action){
                //'qty_'+action+'_'+row.ItemCode
                var id = 'qty_'+ubk+'_'+item;
                var stocks = stock;
                if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==='Y'){ 
                    stocks = this.userData.maxStockCart;
                }
                moreQty(id,action,stock,stocks);
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            }
        }
	};
</script>

<style>
    .product-card__features-list{
        display: block!important;
    }
    .product__quantity {
         width: 100%!important;
    }
    .form-control{
        width: 100%!important;
    }
</style>
