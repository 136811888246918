<template>
  <div class="container-fluid">
      <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
      test
  </div>
</template>

<script>
import axios from "axios";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
    props: ['item','options'],
    data () {
        return {
            api:process.env.MIX_API_URL,
            userData:null,
            isLoading: false,
        }
    },
    components: { Loading },
    mounted() {
        console.log('data',this.item,this.options);
        this.userData = this.options;
        //this.loadOfferts();
    },
    methods: {
        async loadOfferts(){
            this.isLoading = true;
            let vm = this;
            let data = {
                'SPName':'spWebOptionsPublic',
                'Param1':'GET-PRICES',
                'Param2':this.item,
                'Param3':this.userData.CardCode,
                'Param4':this.userData.list_price,
                'Param5':'1',
                'Param6':this.userData.list_price_type,
                'Param7':'','Param8':'','Param9':'','Param10':''
            };
            await axios.post(this.api + "/products/searchDataItem",data)
                .then(response => {
                    var result = response.data.result;
                    vm.offerts = result;
                    result.forEach(element => {
                        if(element.Type==='P')vm.pricedb = element.Price;
                    });
                    //console.log(vm.offerts);
                })
                .catch(error => {
                    console.log("error", error);
                }).finally(() => this.isLoading = false);
        },// end function load api
    },
};
</script>

<style>

</style>
