<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<div class="addresses-list">
	        <a class="addresses-list__item addresses-list__item--new" data-toggle="modal" data-target="#staticBackdrop" title="Agregar nueva direccion" @click="actions('N',0)">
	            <div class="addresses-list__plus"></div>
	            <div class="btn btn-secondary btn-sm">Agregar nueva</div>
	        </a>
	    	<template v-for="(row, index) in address">
	    		<div class="addresses-list__divider" :key="index"></div>
		        <div class="addresses-list__item card address-card ">
		            <div v-show="row.DefaultShip==='Y'" class="address-card__badge">Default Envío</div>
		            <div v-show="row.DefaultBill==='Y'" class="address-card__badge">Default Facturación</div>
		            <div class="address-card__body">
		            	<div class="address-card__row-title">{{row.name}}</div>
		                <div v-show="row.type==='B'" class="address-card__row-title">Facturación</div>
		                <div v-show="row.type==='S'" class="address-card__row-title">Envío</div>
		                <div class="address-card__row">
		                    {{row.street}} {{row.street_next}}  {{row.street_nint}}<br>
		                    {{row.building}}, {{row.city}}.<br>
		                    {{row.county}}, {{row.zip}}.<br>
		                    {{row.state}}.
		                </div>
		                <div class="address-card__footer">
		                    <a href="javascript:;"  data-toggle="modal" data-target="#staticBackdrop" @click="actions('E',row)">Editar</a>
		                </div>
		            </div>
		        </div>
	    	</template>
	    </div>
	    <!-- Modal details -->
		<div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
			<div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
		    	<div class="modal-content">
		      		<div class="modal-header">
		      			Dirección
		        		<button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          			<span aria-hidden="true">&times;</span>
		        		</button>
		      		</div>
		      		<div class="modal-body">
		      			<form class="form-group"  @submit.prevent="handleSubmit">
							<div class="form-group">
							    <label for="name">Identificador <span class="text-danger">*</span></label>
							    <input 
							    	type="text" 
							    	class="form-control"
							    	:class="{'is-invalid': submitted && $v.inputs.name.$error}"
							    	v-model="inputs.name" 
							    	id="name" 
							    	name="name" 
							    	aria-describedby="nameHelp" 
							    	maxlength="20"
							    >
							    <small id="nameHelp" class="form-text text-muted">Favor de poner un nombre identificador no tiene que ser igual alguna dirección anterior. y no mayor a 20 caracteres</small>
							    <div v-if="submitted && !$v.inputs.name.required" class="invalid-feedback">
				                  Identificador ya existe o es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.name.maxLength" class="invalid-feedback">
				                  Máximo de 20 caracteres
				                </div>
							</div>
							<div class="form-group row">
								<div class="col-6">
									<label for="state">Tipo</label>
								    <select name="tipe" v-model="inputs.type" id="type" class="form-control">
								    	<option value="S">Envío</option>
								    	<option value="B">Facturación</option>
								    </select>
								</div>
								<div class="col-6">
								    <label for="isdefault">Default</label>
								    <select name="tipe" v-model="inputs.isdefault" id="isdefault" class="form-control">
								    	<option value="N" selected>NO</option>
								    	<option value="Y">SI</option>
								    </select>
								</div>
							</div>
							<div class="form-group">
							    <label for="street">Calle <span class="text-danger">*</span></label>
							    <input 
							    	type="text" 
							    	class="form-control"
							    	:class="{'is-invalid': submitted && $v.inputs.street.$error}"
							    	v-model="inputs.street" 
							    	id="street" 
							    	name="street" 
							    	maxlength="80"
							    >
							    <div v-if="submitted && !$v.inputs.street.required" class="invalid-feedback">
				                  Nombre de la calle es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.street.maxLength" class="invalid-feedback">
				                  Máximo de 80 caracteres
				                </div>
							</div>
							<div class="form-group row">
								<div class="col-6">
							    	<label for="street">Num. Exterior <span class="text-danger">*</span></label>
							    	<input 
							    		type="text" 
							    		class="form-control"
							    		:class="{'is-invalid': submitted && $v.inputs.nume.$error}" 
							    		v-model="inputs.nume" 
							    		id="nume" 
							    		name="nume" 
							    		maxlength="20" 
							    	>
							    	<div v-if="submitted && !$v.inputs.nume.required" class="invalid-feedback">
					                  Número exterior de la calle es obligatorio!
					                </div>
								</div>
								<div class="col-6">
							    	<label for="street">Num. Interior</label>
							    	<input type="text" class="form-control" v-model="inputs.numi" id="numi" name="numi" maxlength="20" >
								</div>
							</div>
							<div class="form-group">
							    <label for="block">Colonia <span class="text-danger">*</span></label>
							    <input 
							    	type="text" 
							    	class="form-control"
							    	:class="{'is-invalid': submitted && $v.inputs.block.$error}" 
							    	v-model="inputs.block" 
							    	id="block" 
							    	name="block" 
							    	maxlength="100"
							    >
							    <div v-if="submitted && !$v.inputs.block.required" class="invalid-feedback">
				                  Colonia es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.block.maxLength" class="invalid-feedback">
				                  Máximo de 100 caracteres
				                </div>
							</div>
							<div class="form-group">
							    <label for="city">Ciudad <span class="text-danger">*</span></label>
							    <input 
							    	type="text" 
							    	class="form-control" 
							    	:class="{'is-invalid': submitted && $v.inputs.city.$error}" 
							    	v-model="inputs.city"
							    	id="city"
							    	name="city" 
							    	maxlength="90" 
							    >
							    <div v-if="submitted && !$v.inputs.city.required" class="invalid-feedback">
				                  Ciudad es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.city.maxLength" class="invalid-feedback">
				                  Máximo de 90 caracteres
				                </div>
							</div>
							<div class="form-group">
							    <label for="county">Municipio / Localidad <span class="text-danger">*</span></label>
							    <input 
							    	type="text" 
							    	class="form-control"
							    	:class="{'is-invalid': submitted && $v.inputs.county.$error}" 
							    	v-model="inputs.county" 
							    	id="county" 
							    	name="county" 
							    	maxlength="90"
							    >
							    <div v-if="submitted && !$v.inputs.county.required" class="invalid-feedback">
				                  Municipio / Localidad es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.county.maxLength" class="invalid-feedback">
				                  Máximo de 90 caracteres
				                </div>
							</div>
							<div class="form-group">
							    <label for="zip">Código Postal <span class="text-danger">*</span></label>
							    <input 
							    	type="number" 
							    	class="form-control"
							    	:class="{'is-invalid': submitted && $v.inputs.zip.$error}"  
							    	v-model="inputs.zip" 
							    	id="zip" 
							    	name="zip" 
							    	maxlength="5" 
							    >
							    <div v-if="submitted && !$v.inputs.zip.required" class="invalid-feedback">
				                  Municipio / Localidad es obligatorio!
				                </div>
				                <div v-if="submitted && !$v.inputs.zip.maxLength" class="invalid-feedback">
				                  Máximo de 5 caracteres
				                </div>
							</div>
							<div class="form-group">
							    <label for="state">Estado <span class="text-danger">*</span></label>
							    <select name="state" v-model="inputs.state" id="state" class="form-control">
							    	<option v-for="st in states" :value="st.code">{{st.name}}</option>
							    </select>
							</div>

							<button class="btn btn-primary btn-block">Guardar</button>
						</form>
		      		</div>
		    	</div>
		  	</div>
		</div>
	</div>
</template>

<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	name: 'AddressComponent',
	props: ['user','states','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		userData:[],
    		address:[],
    		inputs: {
    			action:"",
    			rows:"",
		        name:"",
	    		street:"",
	    		nume:"",
	    		numi:"",
	    		block:"",
	    		city:"",
	    		county:"",
	    		zip:"",
	    		state:"AGS",
	    		type:"S",
	    		isdefault:"N",
		    },
		    submitted: false,
    	}
  	},
  	components: { Loading },
  	mounted() {
  		this.userData = this.user;
  		this.callApi();
  	},
  	validations: {
	    inputs: {
	      name: { required, maxLength: maxLength(20) },
	      street: { required, maxLength: maxLength(80) },
	      nume: { required },
	      block: { required, maxLength: maxLength(100) },
	      city: { required, maxLength: maxLength(90) },
	      county: { required, maxLength: maxLength(90) },
	      zip: { required, maxLength: maxLength(5) },
	    }
	},
  	methods: {
  		async callApi(){
            this.address = [];
			var SPName = 'spReports'
            var Param1 = 'MY-ADDRESS';
            var Param2 = this.userData.CardCode;
            var Param3 = "";
            var Param4 = "";
            var Param5 = "";
            let dataSend = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            await axios.post(this.api + "/spmodule/callsp",dataSend)
            .then(response => {
                let result = response.data;
                //console.log(result);
                this.address = result;
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        async senddata(datasend){
        	this.isLoading = true;
        	console.log("Send",datasend);
            await axios.post(this.ubk + "profile/address",datasend)
            .then(response => {
                //let result = response.data.result;
                //console.log(response);
                $('#staticBackdrop').modal('hide');
            })
            .catch(error => { 
            	//Swal.fire("Error", "Intentar más tarde, disculpe las molestias", "error");
            	console.log("error", error); 
            })
            .finally(() => { 
            	this.callApi();
            });
        },
        clean(){
        	this.inputs.rows="",
		    this.inputs.name="",
	    	this.inputs.street="",
	    	this.inputs.nume="",
	    	this.inputs.numi="",
	    	this.inputs.block="",
	    	this.inputs.city="",
	    	this.inputs.county="",
	    	this.inputs.zip="",
        	this.inputs.state = "AGS",
	    	this.inputs.type = "S",
	    	this.inputs.isdefault = "N"
        },
        actions(op,records){
        	this.clean();
	    	document.getElementById("name").disabled = false;
        	if(op==='N'){ // new record
        		this.inputs.action = "N"; 
        	}else{
        		this.inputs.action = "E";
        		this.inputs.name = records.name;
        		this.inputs.street = records.street;
        		this.inputs.nume = records.street_next;
        		this.inputs.numi = records.street_nint;
        		this.inputs.block = records.building;
        		this.inputs.city = records.city;
        		this.inputs.county = records.county;
        		this.inputs.zip = records.zip;
        		this.inputs.state = records.state;
        		this.inputs.type = records.type;
        		if(records.DefaultShip==='Y'){ this.inputs.isdefault = "Y"; }
        		if(records.DefaultBill==='Y'){ this.inputs.isdefault = "Y"; }
        		document.getElementById("name").disabled = true;
        	}
        },
        handleSubmit(e){
        	this.submitted = true;
		    this.$v.$touch();
		    var rows = "";
		    this.address.forEach(element=>{
		    	var dbName = element.name.toLowerCase();
		    	var prName = this.inputs.name;
		    	if(this.inputs.action==='E'){
		    		if(prName.toLowerCase()===dbName.toLowerCase()){ 
			        	rows += `<row>EDIT</row>`;
			        }else{ rows += "<row/>"; }
		    	}else{
					rows += "<row/>";
					if(prName.toLowerCase()===dbName.toLowerCase()){ this.inputs.name = ""; }
		    	}
		    });
		    if (this.$v.$invalid) { return; }
		    /**/
		    this.inputs.rows = rows;
        	this.senddata(this.inputs);
        },
  	},
};
</script>

<style lang="css" scoped>
</style>