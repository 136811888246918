<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report last-entries -->
		<h3 class="text-center">Reporte de Pedidos Abiertos</h3>
        <div>
            <h5>Seleccionar un Vendedor</h5>
            <vue-single-select
                name="a_sell"
                placeholder="Selecciona un vendedor"
                v-model="userd"
                :options="users"
                option-key="slpcode"
                option-label="cardname"
                @input="sendUser"
            ></vue-single-select>
            <hr>
        </div>
        <vue-good-table 
            :search-options="searchOptions"
            :globalSearch="true"
            :columns="columnsTable" 
            :rows="dataTable"
            :pagination-options = "paginationOptions"
            styleClass="vgt-table small-table table table-hover table-bordered table-responsive"
            :lineNumbers="true"
            :fixed-header="true"
            compactMode
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'DocNum'" @click="loadmodal(props.row.DocEntry,'B')">
                    <a href="javascript:;" data-toggle="modal" data-target="#staticBackdrop">
                        {{props.row.DocNum}}
                    </a>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
        <br>
        <export-excel
            class   = "btn btn-warning"
            :data   = "json_data"
            :fields = "json_fieldsRLE"
            worksheet = "Ordenes Abiertas"
            :name    = "nameFile">
         
            <i class="far fa-file-excel"></i> Descargar Excel.
         
        </export-excel>

        <!-- Modal details -->
        <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="staticBackdropLabel">
                            Partidas Abiertas
                            <small>Detalle del documento {{detailsHead.docnum}}</small>
                        </h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <div v-if="loadingText===true" class="text-center loading">
                            <div class="loadingtext">
                                <span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
                            </div>
                        </div>
                        <div v-if="loadingText===false" class="table-responsive">
                            Cliente: {{detailsHead.cardcode}} {{detailsHead.cardname}}<br>
                            Fecha: {{detailsHead.date}}<br>
                            Usuario que realiza el pedido: {{detailsHead.salesman}}<br>
                            <table class="table table-hover">
                                <thead>
                                    <tr>
                                        <th scope="col" class="text-center">Item</th>
                                        <th scope="col" class="text-center">Descripción</th>
                                        <th scope="col" class="text-center">Cantidad</th>
                                        <th scope="col" class="text-center">Precio</th>
                                        <th scope="col" class="text-center">SubTotal</th>
                                        <th v-if="typeDoc==='B'" scope="col" class="text-center">Entregados</th>
                                        <th v-if="typeDoc==='B'" scope="col" class="text-center">Restante</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-for="(row,index) in details" :key="index">
                                        <td>{{row.ItemCode}}</td>
                                        <td>{{row.ItemName}}<br>{{row.notes}}</td>
                                        <td class="text-right">{{row.Quantity | number('0,0') }}</td>
                                        <td class="text-right">{{row.Price | currency }}</td>
                                        <td class="text-right">{{row.Price*row.Quantity | currency }}</td>
                                        <td v-if="typeDoc==='B'" class="text-right">{{row.QuantitySent | number('0,0') }}</td>
                                        <td v-if="typeDoc==='B'" class="text-right">{{row.QuantityOpen | number('0,0') }}</td>
                                    </tr>
                                </tbody>
                                <tfoot v-if="totaldetails<=0">
                                    <tr>
                                        <td colspan="5" class="text-center text-muted"> No se encontraron registros </td>
                                    </tr>
                                </tfoot>
                            </table>
                            <div class="my-3 text-right">
                                <div v-if="loadingText===true" class="text-center loading">
                                    <div class="loadingtext">
                                        <span class="text-center text-white"> <i class="fas fa-spinner fa-spin"></i> Cargando... </span>
                                    </div>
                                </div>
                                <button type="button" class="btn btn-warning" data-dismiss="modal">Cerrar</button>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment'
import VueSingleSelect from "vue-single-select";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  	name: 'ReportOpenOrderComponent',
  	props: [ 'user','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
	    	isLoading: false,
            loadingText: false,
            userd:null,
            userData:[],
            users:[],
	    	header:[],
            json_data:[],
            details:[],
            detailsHead:[],
            typeDoc:'B',
            dataTable:[],
            columnsTable:[],
            totalRecords:0,
            totaldetails:0,
            searchOptions: {
                enabled: true, 
                skipDiacritics: true,
                placeholder: 'Search this table',
            },
            paginationOptions:{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [10, 100, 1000],
                dropdownAllowAll: true,
                setCurrentPage: 2,
                nextLabel: 'siguiente',
                prevLabel: 'anterior',
                rowsPerPageLabel: 'Rregistros por página',
                ofLabel: 'de',
                pageLabel: 'página', // for 'pages' mode
                allLabel: 'Todos',
            },
            nameFile:'ordenes abiertas.xls',
            json_fieldsRLE: {
                'Fecha': 'fecha',
                'Folio': 'DocNum',
                'Codigo': 'CardCode',
                'Cliente': 'CardName',
                'Numero de partidas': 'partidas',
            },
            columnsTable: [
                { 
                    label: 'Fecha', 
                    field: 'fecha',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Fecha',  // placeholder for filter input
                    }, 
                },
                { 
                    label: 'Folio', 
                    field: 'DocNum',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'DocNum',  // placeholder for filter input
                    },
                },
                { 
                    label: 'Código', 
                    field: 'CardCode', 
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Código',  // placeholder for filter input
                    },
                },
                { 
                    label: 'Cliente', 
                    field: 'CardName', 
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Cliente',  // placeholder for filter input
                    },
                },
                { 
                    label: 'Número de partidas', 
                    field: 'partidas',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Partidas',  // placeholder for filter input
                    },
                },
            ],
	    }
  	},
  	components: { Loading,VueSingleSelect },
	mounted() {
		this.userData = this.user;
        this.callApi(this.userData.slpcode); 
        this.PrintSalesMan();
    },
    methods: {
        async callApi(slpcode){
            this.isLoading = true
            this.dataTable = [];
            let date1 = moment().subtract(1, 'months').format('YYYY/MM/DD');
			let date2 =  moment().format('YYYY/MM/DD');
			var SPName = "spReports"
            var Param1 = "OPEN-ORDER-HEAD";
            var Param2 = `${slpcode}`;
            var Param3 = '';
            var Param4 = '';
            var Param5 = '';
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
			await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                //console.log(result);
                this.dataTable = result;
                this.json_data = result; //by export to excel
                this.totalRecords = result.lenght;
                this.isLoading = false;
            })
            .catch(error => { console.log("error", error); });
        },
        async PrintSalesMan(){
            this.header = [];
            var SPName = "spReports"
            var Param1 = "SELL-ALL";
            var Param2 = '';
            var Param3 = '';
            var Param4 = '';
            var Param5 = '';
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                result.forEach(element=>{ 
                    this.users.push({ 
                        "slpcode":element.SlpCode, 
                        "cardname":element.SlpName, 
                        "data":element
                    }); 
                }); 
            })
            .catch(error => { console.log("error", error); });
        },
        async sendUser(){
            if(this.userd != null)this.callApi(this.userd.slpcode);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        },
        async loadmodal(donentry,doc){
            this.loadingText = true;
            this.docEntry = donentry;
            this.details = [];
            var Param1 = 'Orders_items';
            if(doc==='B')Param1 = 'Backorder_items';
            this.typeDoc = doc;
            var Param2 = `${donentry}`;
            var Param3 = '';
            var Param4 = '';
            var SPName = 'spWebOptionsPublic'
            let data = {SPName, Param1,Param2,Param3,Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            let headerdata = [];
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data
                //console.log(result);
                let i=0;
                result.forEach(element=>{
                    this.details.push(element);
                    headerdata = { 
                        "salesman":element.salesman, 
                        "cardcode":element.CardCode, 
                        "cardname":element.CardName, 
                        "date":element.DocDate,
                        "docnum":element.DocNum,
                    };
                    i++;
                });
                //console.log(headerdata);
                this.detailsHead = headerdata;
                this.totaldetails=i;
            })
            .catch(error => {
                console.log("error", error);
            })
            .finally(() => {
                this.loadingText = false;
            });
        },
    },
};
</script>

<style lang="css" scoped>
</style>