<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<h5 class="text-success" v-if="editOrder>0">{{editOrder}} No. de Cotización a editar.</h5>
        <div class="text-right mb-1" v-show="items.length>0">
            <button type="button" class="btn btn-danger btn-sm" v-on:click="onDelete('all','0')">
                Eliminar todo
            </button>
        </div>
		<table class="cart__table cart-table">
            <thead class="cart-table__head">
                <tr class="cart-table__row">
                    <th class="cart-table__column cart-table__column--image">Imagen</th>
                    <th class="cart-table__column cart-table__column--product">Artículo</th>
                    <th class="cart-table__column cart-table__column--price">Precio</th>
                    <th class="cart-table__column cart-table__column--quantity">Cantidad</th>
                    <th class="cart-table__column cart-table__column--total">Total</th>
                    <th class="cart-table__column cart-table__column--remove"></th>
                </tr>
            </thead>
            <tbody class="cart-table__body">
                <tr class="cart-table__row" v-for="(row,index) in items" :key="index">
                    <td class="cart-table__column cart-table__column--image">
                        <div class="product-image">
                            <a :href="ubk+'details/product/'+row.id+'/'+sanitizeTitle(row.name)" class="product-image__body">
                            	<img v-bind:src="ubk+'Items/'+row.id+'.jpg'" v-bind:alt="row.id" class="product-image__img" @error="imageUrlAlt" :alt="row.id">
                            </a>
                        </div>
                    </td>
                    <td class="cart-table__column cart-table__column--product">
                        <a :href="ubk+'details/product/'+row.id+'/'+sanitizeTitle(row.name)" class="cart-table__product-name">{{row.name}}</a>
                        <ul class="cart-table__options">
                            <li>{{row.id}}</li>
                            <!--
                            <li>
                                <a :href="links+row.options.Categorie">
                                    {{row.options.Categorie | truncate(30) }}
                                </a>
                            </li> -->
                            <div v-if="seeQty">
                                <Stockcomponent op="Cart" :item="row.id" :stock="row.options.Stock" :options="userData" :qty="row.qty" />
                            </div>
                        </ul>
                        <input type="text" class="form-control" placeholder="Alguna nota" :id="'note-'+row.rowid" :value="row.options.Notes" maxlength="60">
                    </td>
                    <td class="cart-table__column cart-table__column--price" data-title="Price">
                    	{{ row.price | currency}}
                    </td>
                    <td class="cart-table__column cart-table__column--quantity" data-title="Quantity">
                        <div class="input-number">
                            <input class="form-control input-number__input Number" :id="'qty_'+row.rowid" type="number" min="1" :value="row.qty">
                            <button type="button" class="btn btn-primary" @click="onAction(row.rowid,row.id,row.options.Stock,row.price,'W')">Actualizar</button>
                        </div>
                    </td>
                    <td class="cart-table__column cart-table__column--total" data-title="Total">{{ row.price * row.qty | currency}}</td>
                    <td class="cart-table__column cart-table__column--remove">
                        <button type="button" class="btn btn-light btn-sm btn-svg-icon" v-on:click="onDelete(row.rowid,row)">
                            <svg width="12px" height="12px">
                                <use :xlink:href="ubk+'images/sprite.svg#cross-12'"></use>
                            </svg>
                        </button>
                    </td>
                </tr>
            </tbody>
            <tfoot v-show="items.length<1">
                <tr>
                    <td colspan="6" class="text-center text-muted">
                        <br>
                        No hay artículos en su carrito*
                    </td>
                </tr>
            </tfoot>
        </table>


        <div class="row justify-content-end pt-5">
            <div class="col-12 col-md-7 col-lg-6 col-xl-5">
                <div class="card">
                    <div class="card-body">
                        <h3 class="card-title">Totales</h3>
                        <table class="cart__totals">
                            <thead class="cart__totals-header">
                                <tr>
                                    <th>SubTotal</th>
                                    <td>{{ subtotal | currency}}</td>
                                </tr>
                            </thead>
                            <tbody class="cart__totals-body">
                            	<!--
                                <tr>
                                    <th>Shipping</th>
                                    <td>
                                        $25.00
                                        <div class="cart__calc-shipping"><a href="#">Calculate Shipping</a></div>
                                    </td>
                                </tr>
                                 -->
                                <tr v-show="!ivaText">
                                    <th>I.V.A.</th>
                                    <td>
                                        {{ iva | currency}}
                                    </td>
                                </tr>
                            </tbody>
                            <tfoot class="cart__totals-footer">
                                <tr>
                                    <th>Total</th>
                                    <td>{{ total | currency}}</td>
                                </tr>
                                <tr v-if="ivaText">
                                    <td align="right" colspan="2">
                                        <small class="my-2 cart-table__options cartIvaSimple">
                                           *I.V.A. aploximado ya incluido es de {{ (subtotal*.16) | currency }}
                                        </small>
                                    </td>
                                </tr>
                            </tfoot>
                        </table>

                        <a v-if="btn_service_call" class="btn btn-primary btn-xl btn-block cart__checkout-button" :href="ubk+'service'">Servicio</a>
                        <template v-else>
                            <template v-if="!youArePublic">
                            <!--
                                <a class="btn btn-warning btn-xl btn-block cart__checkout-button" title="Crear cotización" :href="ubk+'documents/checkout/oqute'">Crear cotización</a>

                                <a class="btn btn-info btn-xl btn-block cart__checkout-button" title="Crear pedido"  :href="ubk+'documents/checkout/order'">Crear pedido</a>
                            -->
                                <a class="btn btn-primary btn-xl btn-block cart__checkout-button" :href="ubk+'documents/checkout/orders'" title="Pagar">
                                    Continuar
                                </a>
                            </template>
                            <template v-else>
                                <a class="btn btn-primary btn-xl btn-block cart__checkout-button" :href="ubk+'checkout'" title="Pagar">
                                    Pagar
                                </a>
                            </template>
                        </template>
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
import Stockcomponent from "./StockComponent"; 

export default {
  props: ['cart','user','ubk', 'action'],
  	data () {
    	return {
			api:process.env.MIX_API_URL,
    		isLoading: false,
    		loadingText: false,
            youArePublic: false,
    		userd:null,
    		items:[],
    		users:[],
    		assets:'',
            ivaText:false,
    		total:0,
    		iva:0,
    		subtotal:0,
    		editOrder:0,
            links:'',
            seeQty: false,
            btn_service_call: false,
	    }
  	},
  	components: { Loading, Stockcomponent },
  	mounted() {
        /*
        CardCode: "28806", CardName: "Joaquin Barranco Orozco", SALESMAN: "N", WHOLESALER: "N", list_price: 7, list_price_type: "PUBLICO", location: "B", showcart: true, EMPID:0
        */
        this.userData = this.user;
        this.assets = this.ubk;
        this.links = this.ubk+"search?search=";
        this.printCart();
        this.btn_service_call = this.userData.service_call;
        if( this.userData.CardCode==="20000" ){
           this.youArePublic = true;
        }
        if( this.userData.SALESMAN==='Y' || this.userData.WHOLESALER==="Y" ){
            this.youArePublic = false;
        }
        if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==="Y"){ 
            this.seeQty = true; 
        }
    },
    methods: {
    	async printCart () {
    		this.isLoading = true;
            this.ivaText = false;
            this.items = [];
            this.subtotal = 0;
            this.iva = 0;
            this.total = 0;
    		let itemsCart = this.cart;
            let sub=0, iv=0;
    		await axios.get(`${this.ubk}loadcart?action=${this.action}`)
    		.then(({ data }) => {
    			data.forEach(element => {
    				//console.log(element)
	    			this.items.push(element);
	    			let price = element.price;
	    			let qty = element.qty;
	    			if(element.options.Parent!==0){ this.editOrder = element.options.Parent; }

                    if( parseInt(this.userData.list_price)===3 || parseInt(this.userData.list_price)===7 ){
                        sub += qty*price;
                        iv += 0;
                        this.ivaText = true;
                    }else{
                        //var priceNew = parseFloat(price) / 1.16;
                        var priceNew = parseFloat(price);
                        sub += qty*priceNew;
                        iv += (qty*priceNew)*0.16;
                        this.ivaText = false;
                    }
	    		});
                this.subtotal = sub;
	    		this.iva = iv;
	    		this.total = sub + iv;
    		}).catch(error => {
    			console.log("error", error);
            }).finally(() => {
            	this.isLoading = false;
            });// end axios call;
    	},
    	imageUrlAlt(event){ event.target.src = this.ubk+"images/no_found.png"; },
    	onAction(idrow,item,stock,price,action){
            //'qty_'+action+'_'+row.ItemCode
            var id = 'qty_'+idrow;
            var note = 'note-'+idrow;
            var stocks = parseInt(stock);
            if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==='Y'){ 
                stocks = this.userData.maxStockCart;
            }
            moreQty(id,action,stock,stocks);
            var inputqty = window.document.getElementById(id).value;
            console.log("hola hola",inputqty);
            updateCartJS(idrow,inputqty,item,price,this.ubk,note).then((response)=>{
                location.href = this.ubk+'cartupdate';
            });
        },
        onDelete(rowid, product){
            var msg = "Esta seguro de eliminar el articulo?";
            if(rowid==='all') msg = "Esta seguro de eliminar todo el carrito?";
            Swal.fire({
                title:"Eliminar Artículo",
                text: msg,
                icon: "info",
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'SI'
            }).then((result) => {
                if (result.value) {
                    delCartJS(rowid,this.ubk,product,'cart');
                }
            });
        },
        sanitizeTitle: function(title) {
            return this.$func.sanitizeTitleURLVue(title);
        }
    },
};
</script>

<style lang="css" scoped>
.cartIvaSimple {
    display: none;
}
</style>
