<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report last-entries -->
		<h3 class="text-center">Reporte de Pedidos Abiertos</h3>
        <div>
            <h5>Seleccionar un Vendedor</h5>
            <vue-single-select
                name="a_sell"
                placeholder="Selecciona un vendedor"
                v-model="userd"
                :options="users"
                option-key="slpcode"
                option-label="cardname"
                @input="sendUser"
            ></vue-single-select>
            <hr>
        </div>
        <vue-good-table 
            :search-options="searchOptions"
            :globalSearch="true"
            :columns="columnsTable" 
            :rows="dataTable"
            :pagination-options = "paginationOptions"
            styleClass="vgt-table small-table table table-hover table-bordered table-responsive"
            :lineNumbers="true"
            compactMode
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == 'ItemCode'">
                    <a :href="ubk+'details/product/'+props.row.ItemCode+'/'+sanitizeTitle(props.row.Dscription)">
                        {{props.row.ItemCode}}
                    </a>
                </span>
                <span v-else>
                    {{props.formattedRow[props.column.field]}}
                </span>
            </template>
        </vue-good-table>
        <br>
        <export-excel
            class   = "btn btn-warning"
            :data   = "json_data"
            :fields = "json_fieldsRLE"
            worksheet = "Ordenes Abiertas Detalles"
            :name    = "nameFile">
         
            <i class="far fa-file-excel"></i> Descargar Excel.
         
        </export-excel>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment'
import VueSingleSelect from "vue-single-select";
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  	name: 'ReportOpenOrderDetailComponent',
  	props: [ 'user','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
	    	isLoading: false,
            userd:null,
            userData:[],
            users:[],
	    	header:[],
            json_data:[],
            dataTable:[],
            columnsTable:[],
            totalRecords:0,
            searchOptions: {
                enabled: true, 
                skipDiacritics: true,
                placeholder: 'Search this table',
            },
            paginationOptions:{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [10, 100, 1000],
                dropdownAllowAll: true,
                setCurrentPage: 2,
                nextLabel: 'siguiente',
                prevLabel: 'anterior',
                rowsPerPageLabel: 'Rregistros por página',
                ofLabel: 'de',
                pageLabel: 'página', // for 'pages' mode
                allLabel: 'Todos',
            },
            nameFile:'ordenes abiertas detalles.xls',
            json_fieldsRLE: {
                'FOLIO': 'DocNum',
                'FECHA': 'fecha',
                'CODIGO': 'CardCode',
                'CLIENTE': 'CardName',
                'SKU': {
                    field: 'ItemCode', // nested attribute supported
                        callback: (value) => {
                            return `Code: ${value}`
                        }
                },
                'DESCRIPCION': 'Dscription',
                'CANTIDAD': 'Quantity',
                'MONTO': 'LineTotal',
            },
            columnsTable: [
                { 
                    label: 'FOLIO', 
                    field: 'DocNum',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'DocNum',  // placeholder for filter input
                    },
                },
                { 
                    label: 'FECHA', 
                    field: 'fecha',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Fecha',  // placeholder for filter input
                    }, 
                },
                { 
                    label: 'CODIGO', 
                    field: 'CardCode',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'CODIGO',  // placeholder for filter input
                    }, 
                },
                { 
                    label: 'CLIENTE', 
                    field: 'CardName',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'CLIENTE',  // placeholder for filter input
                    }, 
                },
                { 
                    label: 'SKU', 
                    field: 'ItemCode', 
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'ItemCode',  // placeholder for filter input
                    },
                },
                { 
                    label: 'DESCRIPCION', 
                    field: 'Dscription',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'DESCRIPCION',  // placeholder for filter input
                    },
                },
                { label: 'CANTIDAD', field: 'Quantity', type: 'number', },
                { label: 'MONTO', field: 'LineTotal', type: 'number', },
            ],
	    }
  	},
  	components: { Loading,VueSingleSelect },
    mounted() {
        this.userData = this.user;
        this.callApi(this.userData.slpcode); 
        this.PrintSalesMan();
    },
    methods: {
        async callApi(slpcode){
            this.isLoading = true
            this.dataTable = [];
            let date1 = moment().subtract(1, 'months').format('YYYY/MM/DD');
			let date2 =  moment().format('YYYY/MM/DD');
			var SPName = "spReports"
            var Param1 = "OPEN-ORDER-DETAILS";
            var Param2 = `${slpcode}`;
            var Param3 = '';
            var Param4 = '';
            var Param5 = '';
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
			await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                //console.log(result);
                this.dataTable = result;
                this.json_data = result; //by export to excel
                this.totalRecords = result.lenght;
                this.isLoading = false;
            })
            .catch(error => { console.log("error", error); });
        },
        async PrintSalesMan(){
            this.header = [];
            var SPName = "spReports"
            var Param1 = "SELL-ALL";
            var Param2 = '';
            var Param3 = '';
            var Param4 = '';
            var Param5 = '';
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                result.forEach(element=>{ 
                    this.users.push({ 
                        "slpcode":element.SlpCode, 
                        "cardname":element.SlpName, 
                        "data":element
                    }); 
                }); 
            })
            .catch(error => { console.log("error", error); });
        },
        async sendUser(){
            if(this.userd != null)this.callApi(this.userd.slpcode);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        }
    },
};
</script>

<style lang="css" scoped>
</style>