<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report last-entries -->
		<h3 class="text-center">Reporte Últimas entradas</h3>
        <vue-good-table 
            :search-options="searchOptions"
            :globalSearch="true"
            :columns="columnsTable" 
            :rows="dataTable"
            :pagination-options = "paginationOptions"
            styleClass="vgt-table small-table table table-hover table-bordered table-responsive"
            :lineNumbers="true"
            compactMode
        >
            <template slot="table-row" slot-scope="props">
                <span v-if="
                            props.column.field == 'Articulo' || 
                            props.column.field == 'Categorie' || 
                            props.column.field == 'SubCategorie' || 
                            props.column.field == 'Brand' 
                ">
                    <span v-if=" props.column.field == 'Articulo'">
                        <a :href="ubk+'details/product/'+props.row.Articulo+'/'+sanitizeTitle(props.row.Descripcion)">
                            {{props.row.Articulo}}
                        </a>
                    </span>
                    <span v-if=" props.column.field == 'Categorie'">
                        <a :href="ubk+'search?search='+props.row.Categorie">{{props.row.Categorie}}</a>
                    </span>
                    <span v-if=" props.column.field == 'SubCategorie'">
                        <a :href="ubk+'search?search='+props.row.SubCategorie">{{props.row.SubCategorie}}</a>
                    </span>
                    <span v-if="props.column.field == 'Brand'">
                        <a :href="ubk+'search?search='+props.row.Brand">{{props.row.Brand}}</a>
                    </span>
                </span>
                <span v-else>
                  {{props.formattedRow[props.column.field]}}
                </span>

            </template>
        </vue-good-table>
        <br>
        <export-excel
            class   = "btn btn-warning"
            :data   = "json_data"
            :fields = "json_fieldsRLE"
            worksheet = "Últimas Entradas"
            :name    = "nameFile">
         
            <i class="far fa-file-excel"></i> Descargar Excel.
         
        </export-excel>
		<!-- End Report last-entries -->
		
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'ReportTablesComponent',
	props: ['action', 'user','ubk'],
	data () {
	    return {
	    	api:process.env.MIX_API_URL,
	    	isLoading: false,
	    	header:[],
            json_data:[],
            dataTable:[],
            columnsTable:[],
            totalRecords:0,
            searchOptions: {
                enabled: true, 
                skipDiacritics: true,
                placeholder: 'Search this table',
            },
            paginationOptions:{
                enabled: true,
                mode: 'records',
                perPage: 10,
                position: 'bottom',
                perPageDropdown: [10, 100, 1000],
                dropdownAllowAll: true,
                setCurrentPage: 2,
                nextLabel: 'siguiente',
                prevLabel: 'anterior',
                rowsPerPageLabel: 'Rregistros por página',
                ofLabel: 'de',
                pageLabel: 'página', // for 'pages' mode
                allLabel: 'Todos',
            },
            nameFile:'ultimas entradas.xls',
            json_fieldsRLE: {
                'Artículo': {
                    field: 'Articulo', // nested attribute supported
                        callback: (value) => {
                            return `Code: ${value}`
                        }
                },
                'Descripción': 'Descripcion',
                'Categoría': 'Categorie',
                'SubCategoría': 'SubCategorie',
                'Marca': 'Brand',
                'Cantidad': 'Cantidad',
                'Almacén': 'Almacen',
                'Fecha entrada': 'FechaEntrada',
            },
            columnsTable: [
                { 
                    label: 'Artículo', 
                    field: 'Articulo', 
                    filterOptions: {
                      enabled: true, // enable filter for this column
                      placeholder: 'Código', // placeholder for filter input
                    }, 
                },
                { 
                    label: 'Descripción', 
                    field: 'Descripcion',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Descripción',  // placeholder for filter input
                    }, 
                },
                { 
                    label: 'Categoría', 
                    field: 'Categorie',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Descripción',  // placeholder for filter input
                    },
                },
                { 
                    label: 'SubCategoría', 
                    field: 'SubCategorie', 
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Descripción',  // placeholder for filter input
                    },
                },
                { 
                    label: 'Marca', field: 'Brand',
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Descripción',  // placeholder for filter input
                    },
                },
                { label: 'Cantidad', field: 'Cantidad', type: 'number', },
                { label: 'Almacén', field: 'Almacen', },
                { 
                    label: 'FechaEntrada', 
                    field: 'FechaEntrada', 
                    filterOptions: { 
                        enabled: true, // enable filter for this column
                        placeholder: 'Fecha Entrada',  // placeholder for filter input
                    },
                },
            ],
	    }
	},
  	components: { Loading },
	mounted() {
		this.userData = this.user;
        this.lastEntries(); 
    },
    methods: {
        async lastEntries(){
            this.isLoading = true
            this.dataTable = [];
            let date1 = moment().subtract(1, 'months').format('YYYY/MM/DD');
			let date2 =  moment().format('YYYY/MM/DD');
			var SPName = 'spReports'
            var Param1 = 'last-entries';
            var Param2 = date1;
            var Param3 = date2;
            var Param4 = '';
            var Param5 = '';
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
			await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                //console.log(result);
                this.dataTable = result;
                this.json_data = result; //by export to excel
                this.totalRecords = result.lenght;
                this.isLoading = false;
            })
            .catch(error => { console.log("error", error); });
        },
		seeReportClick(option){
        	this.seeReport = option;
        	this.callApi(option);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        }
    },
};
</script>

<style >
    .small-table{
        font-size: 12px!important;
    }
</style>