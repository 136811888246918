<template>
	<div v-show="showHeader" class="tab-content">
		<span v-show="isLoading" class="text-center text-muted">Loading...</span>
		<div class="row font-type" v-for="(row,index) in header" :key="index">
			<div class="col-12 col-md-6">
				<b class="pr-1">Código del cliente:</b> {{row.CardCode}}<br>
				<b class="pr-1">Nombre del cliente:</b> {{row.CardName}}<br>
				<template v-if="row.ConditionCode !== 209">
					<b class="pr-1">Dias descuento P.P:</b> {{row.discount_day}}<br>
				</template>
				<b class="pr-1">Condiciones crédito:</b> {{row.Condition}}<br>
				
				<!-- <span v-if="userdata.SALESMAN==='Y'"><b class="pr-1">Lista de precios:</b> {{lprice}}<br></span>
				
 -->
				<span v-if="row.NumAccount">
					<b class="pr-1">No. referencia bancaria:</b> {{row.NumAccount}} <br>
				</span>
				<b class="pr-1">Régimen Fiscal:</b> {{row.regfis}} <br>
				<span v-if="row.sendtype">
					<b class="pr-1">Tipo Envío:</b> <small>({{row.sendtype}}) {{row.namesend}}</small> <br>
				</span>
				<span v-if="row.subsendtype">
					<b class="pr-1">SubTipo Envío:</b> <small>({{row.subsendtype}}) {{row.subnamesend}}</small>
				</span>
			</div>
			<div class="col-12 col-md-6 text-right">
				<b class="pr-1">Fecha:</b> {{ new Date() | dateFormat('DD/MM/YYYY') }}<br>
				<b class="pr-1">Limite de crédito:</b> {{ row.Credit | currency }}<br>
				<b class="pr-1">Crédito disponible:</b> {{  row.Credit - (row.Balance + row.Interes) | currency }} <br>
				<b class="pr-1">Saldo:</b> {{ row.Balance | currency }}<br>
				<template v-if="row.ConditionCode !== 209">
					<b class="pr-1">Intereses:</b> {{ row.Interes | currency }}<br>
					<h4><b>Saldo total:</b> {{  (row.Balance + row.Interes) | currency }}</h4><br>
				</template>
				<template v-else>
					<h4><b>Saldo total:</b> {{  (row.Balance) | currency }}</h4><br>
				</template>
			</div>
		</div>
		<hr>
	</div>
</template>

<script>

export default {
  	name: 'ReportTop30Component',
  	props: ['show', 'cardcode','lprice','userdata'],
  	data () {
  	  	return {
  	  		api:process.env.MIX_API_URL,
  	  		isLoading: false,
  	  		showHeader: false,
  	  		header: [],
    	}
  	},
  	mounted() {
  		console.log('data',this.userdata.SALESMAN);
  		this.showHeader = this.show;
  		if(this.showHeader)this.callHeaders();
  	},
   	methods: {
   		async callHeaders(){
   			this.isLoading = true;
            this.header = [];
            var Param1 = 'headerUser';
            var Param2 = this.cardcode;
            var Param3 = `${this.lprice}`;
            var Param4 = '';
            var Param5 = '0';
            var SPName = 'spWebOptionsPublic'
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data;
                //console.log(response);
                if(result.length>0){ result.forEach(element=>{ this.header.push(element); }); }
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
   },
};
</script>

<style lang="css" scoped>
</style>
