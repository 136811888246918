<template>
<div>
  <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
  <div class="addresses-list">
    <!---->
    <a class="addresses-list__item addresses-list__item--new" data-toggle="modal" data-target="#staticBackdrop" title="Agregar nueva direccion" @click="actions('N',0)">
      <div class="addresses-list__plus"></div>
      <div class="btn btn-secondary btn-sm">Agregar contacto</div>
    </a>
    
    <template v-for="(row, index) in contacts">
    <div class="addresses-list__divider" :key="index"></div>
    <div class="addresses-list__item card address-card ">
      <div class="address-card__body">
        <div class="address-card__name">{{row.type}}</div>
        <div class="address-card__row">
          <div class="address-card__row-title">Nombre</div>
          <div class="address-card__row-content">
            {{row.name}} {{row.lastname}}
          </div>
        </div>
        <div class="address-card__row">
          <div class="address-card__row-title">Teléfono</div>
          <div class="address-card__row-content">
            {{row.phone}}
          </div>
        </div>
        <div class="address-card__row">
          <div class="address-card__row-title">Celular</div>
          <div class="address-card__row-content">
            {{row.cel}}
          </div>
        </div>
        <div class="address-card__row">
          <div class="address-card__row-title">Correo electrónico</div>
          <div class="address-card__row-content">{{row.email}}</div>
        </div>
        <!---->
        <div class="address-card__footer">
          <a href="javascript:;"  data-toggle="modal" data-target="#staticBackdrop" @click="actions('E',row)">Editar</a>
        </div>
      </div>
    </div>
    </template>
  </div>
  <!-- Modal details -->
  <div class="modal fade" id="staticBackdrop" data-backdrop="static" data-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
      <div class="modal-content">
        <div class="modal-header">
          Contactos
          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form class="form-group"  @submit.prevent="handleSubmit">
            <div class="form-group">
              <label for="type">Identificador <span class="text-danger">*</span></label>
              <input
              type="text"
              class="form-control"
              :class="{'is-invalid': submitted && $v.inputs.type.$error}"
              v-model="inputs.type"
              id="type"
              name="type"
              aria-describedby="nameHelp"
              maxlength="20"
              >
              <small id="nameHelp" class="form-text text-muted">Favor de poner un nombre identificador no tiene que ser igual alguna contacto anterior. y no mayor a 20 caracteres</small>
              <div v-if="submitted && !$v.inputs.type.required" class="invalid-feedback">
                Identificador ya existe o es obligatorio!
              </div>
              <div v-if="submitted && !$v.inputs.type.maxLength" class="invalid-feedback">
                Máximo de 20 caracteres
              </div>
            </div>
            <div class="form-group">
              <label for="firstname">Nombre del contacto <span class="text-danger">*</span></label>
              <input
              type="text"
              class="form-control"
              :class="{'is-invalid': submitted && $v.inputs.firstname.$error}"
              v-model="inputs.firstname"
              id="firstname"
              name="firstname"
              maxlength="80"
              >
              <div v-if="submitted && !$v.inputs.firstname.required" class="invalid-feedback">
                Nombre de la contacto es obligatorio!
              </div>
              <div v-if="submitted && !$v.inputs.firstname.maxLength" class="invalid-feedback">
                Máximo de 80 caracteres
              </div>
            </div>
            <div class="form-group">
              <label for="lastname">Apellidos <span class="text-danger">*</span></label>
              <input
              type="text"
              class="form-control"
              :class="{'is-invalid': submitted && $v.inputs.lastname.$error}"
              v-model="inputs.lastname"
              id="lastname"
              name="lastname"
              maxlength="100"
              >
              <div v-if="submitted && !$v.inputs.lastname.required" class="invalid-feedback">
                Apellidos es obligatorio!
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12 col-md-6">
                <label for="phone">Teléfono</label>
                <input
                type="tel"
                class="form-control"
                v-model="inputs.phone"
                id="phone"
                name="phone"
                maxlength="100"
                >
              </div>
              <div class="col-12 col-md-6">
                <label for="mobile">Celular</label>
                <input
                type="tel"
                class="form-control"
                v-model="inputs.mobile"
                id="mobile"
                name="mobile"
                maxlength="100"
                >
              </div>
            </div>
            <div class="form-group">
              <label for="email">Correo electrónico</label>
              <input
              type="email"
              class="form-control"
              v-model="inputs.email"
              id="email"
              name="email"
              maxlength="100"
              >
            </div>
            <button class="btn btn-primary btn-block">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
</template>
<script>
import { required, email, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'ContactsComponents',
  props: ['user','ubk'],
  data () {
    return {
      api:process.env.MIX_API_URL,
      isLoading: false,
      userData:[],
      contacts:[],
      inputs: {
        action:"",
        cardcode:"",
        rows:"",
        type:"",
        firstname:"",
        lastname:"",
        phone:"",
        mobile:"",
        email:"",
      },
      submitted: false,
    }
  },
  components: { Loading },
  mounted() {
    this.userData = this.user;
    this.callApi();
  },
  validations: {
    inputs: {
      type: { required, maxLength: maxLength(30) },
      firstname: { required, maxLength: maxLength(80) },
      lastname: { required, maxLength: maxLength(100) },
    }
  },
  methods: {
    async callApi(){
      this.isLoading = true;
      this.contacts = [];
      var SPName = 'spReports'
      var Param1 = 'MY-CONTACTS';
      var Param2 = this.userData.CardCode;
      var Param3 = "";
      var Param4 = "";
      var Param5 = "";
      let dataSend = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
      await axios.post(this.api + "/spmodule/callsp",dataSend)
      .then(response => {
        let result = response.data;
        //console.log(result);
        this.contacts = result;
      })
      .catch(error => { console.log("error", error); })
      .finally(() => { this.isLoading = false; });
    },
    async senddata(data){
      this.isLoading = true;
      await axios.post(this.ubk + "profile/contacts",data).then(response => {
        //let result = response.data.result;
        //console.log(response);
        $('#staticBackdrop').modal('hide');
      }).catch(error => {
        //Swal.fire("Error", "Intentar más tarde, disculpe las molestias", "error");
        console.log("error", error);
      }).finally(() => {
        this.callApi();
      });
    },
    clean(){
        this.inputs.cardcode = "";
        this.inputs.rows = "";
        this.inputs.type = "";
        this.inputs.firstname = "";
        this.inputs.lastname = "";
        this.inputs.phone = "";
        this.inputs.mobile = "";
        this.inputs.email = "";
    },
    actions(op,records){
      //console.log(records);
      this.clean();
      document.getElementById("type").disabled = false;
      if(op==='N'){ // new record
        this.inputs.action = "N";
      }else{
        this.inputs.action = "E";
        this.inputs.type = records.type;
        this.inputs.firstname = records.name;
        this.inputs.lastname = records.lastname;
        this.inputs.phone = records.phone;
        this.inputs.mobile = records.cel;
        this.inputs.email = records.email;
        document.getElementById("type").disabled = true;
      }
    },
    handleSubmit(e){
      this.submitted = true;
      this.$v.$touch();
      var rows = "";
      this.contacts.forEach(element=>{
        var dbName = element.type.toLowerCase();
        var prName = this.inputs.type;
        if(this.inputs.action==='E'){
          if(prName.toLowerCase()===dbName.toLowerCase()){ 
            rows += `<row><CardCode>${this.userData.CardCode}</CardCode><E_Mail>${this.inputs.email}</E_Mail><Phone1>${this.inputs.phone}</Phone1><MobilePhone>${this.inputs.mobile}</MobilePhone><Active>Y</Active><FirstName>${this.inputs.firstname}</FirstName><LastName>${this.inputs.lastname}</LastName></row>`;
          }else{ rows += "<row/>"; }
        }else{
          rows += "<row/>";
          if(prName.toLowerCase()===dbName.toLowerCase()){ this.inputs.type = "";}
        }
      });
      if (this.$v.$invalid) { return; }
      /**/
      this.inputs.cardcode = this.userData.CardCode;
      this.inputs.rows = rows;
      this.senddata(this.inputs);
    },
  },
};
</script>
<style lang="css" scoped>
</style>