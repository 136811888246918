<template>
	<div ref="documentToExportPdf">
		<vue-html2pdf
	        :show-layout="true"
	        :float-layout="true"
	        :enable-download="true"
	        :preview-modal="true"
	        :paginate-elements-by-height="1400"
	        :filename="filename"
	        :pdf-quality="2"
	        :manual-pagination="false"
	        :pdf-format="format"
	        :pdf-orientation="orientation"
	        pdf-content-width="800px"
	        ref="html2Pdf"
        	@hasGenerated="hasGenerated($event)"
	    >
	        <section slot="pdf-content">
	            <!-- PDF Content Here -->
	            <div>
	            	<img>
	            	hola mundo<br>
	            	es algo de prueba <br>
	            	para saber si lo convierte a PDF<br>
	            </div>
	            <button @click="generateReport">Export to PDF</button>
	        </section>
    	</vue-html2pdf>
	</div>
</template>
	
<script>
import VueHtml2pdf from 'vue-html2pdf'

export default {
  	name: 'ExportToPDFComponent',
  	props: ['filename','format','orientation'],
  	data () {
    	return {

    	}
  	},
  	components: { VueHtml2pdf },
  	methods: {
		generateReport () {
            this.$refs.html2Pdf.generatePdf()
        }
	}
};
</script>

<style lang="css" scoped>
</style>