/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue');
import Vue2Filters from 'vue2-filters';
Vue.use(Vue2Filters);
import VueFilterDateFormat from 'vue-filter-date-format';
Vue.use(VueFilterDateFormat);

import infiniteScroll from 'vue-infinite-scroll';
Vue.use(infiniteScroll);

import VueGoodTablePlugin from 'vue-good-table';
Vue.use(VueGoodTablePlugin);

import excel from 'vue-excel-export'
Vue.use(excel);

import VueGraph from 'vue-graph'
Vue.use(VueGraph)

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import VuePaginate from 'vue-paginate'
Vue.use(VuePaginate)


//import VuePaginate from 'vue-paginate'; Vue.use(VuePaginate);
/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

import {func} from './functions.js';
Vue.prototype.$func = func;

// components all site.
Vue.component('Exportfile',require('./components/ExportToPDFComponent.vue').default);

Vue.component('infinite', 	require('vue-infinite-loading'));
Vue.component('Categories', require('./components/CategoriesComponent.vue').default);
Vue.component('Products', require('./components/ProductsComponent.vue').default);
Vue.component('Prices', require('./components/PricesComponent.vue').default);
Vue.component('Relationsproductscomponent',require('./components/RelationsProductsComponent.vue').default);
Vue.component('Categoriebrandscomponent',require('./components/CategoriesBrandsComponent.vue').default);
Vue.component('Quickstore',require('./components/QuickStoreComponent.vue').default);

Vue.component('Offert',require('./components/SpecialPricesComponent.vue').default);
Vue.component('Cartcomponent',require('./components/CartComponent.vue').default);
Vue.component('Filtercomponent',require('./components/FiltersComponent.vue').default);
Vue.component('Searchcomponent',require('./components/SearchComponent.vue').default);
Vue.component('Alternativecomponent',require('./components/AlternativeProductsComponent.vue').default);
Vue.component('homeproductscomponent',require('./components/HomeProductsComponent.vue').default);
Vue.component('showcartcomponent',require('./components/ShowCartComponent.vue').default);

// Reports
Vue.component('Docscomponent',require('./components/reports/ReportDocsComponent.vue').default);
Vue.component('reportheadercomponent',require('./components/reports/ReportHeaderComponent.vue').default);
Vue.component('Reportedoctacomponent',require('./components/reports/ReportEstadoCuentaComponent.vue').default);
Vue.component('Reporttop30component',require('./components/reports/ReportTop30Component.vue').default);
Vue.component('Reportaccountpaycomponent',require('./components/reports/ReportAccountPayComponent.vue').default);
Vue.component('Reportlastentriescomponent',require('./components/reports/ReportLastEntriesComponent.vue').default);
Vue.component('report-arrive-component',require('./components/reports/ReportArriveComponent.vue').default);
Vue.component('report-compare-component',require('./components/reports/ReportThreeCompareComponent.vue').default);
Vue.component('report-openorder-component',require('./components/reports/ReportOpenOrderComponent.vue').default);
Vue.component('report-openorderdetail-component',require('./components/reports/ReportOpenOrderDetailComponent.vue').default);
Vue.component('report-listprices-component',require('./components/reports/ReportListPriceComponent.vue').default);
Vue.component('report-surtido-component',require('./components/reports/ReportSurtidoComponent.vue').default);

// Users
Vue.component('listuserscomponent',require('./components/users/ListUsersComponent.vue').default);
Vue.component('mywalletcomponent',require('./components/users/MyWalletComponent.vue').default);
Vue.component('address-component',require('./components/users/AddressComponent.vue').default);
Vue.component('contacts-component',require('./components/users/ContactsComponents.vue').default);

// catalogos
Vue.component('catalogmotocomponent',require('./components/CatalogComponent.vue').default);
// stock
Vue.component('Stockcomponent',require('./components/StockComponent.vue').default);
Vue.component('Settingscomponent',require('./components/SettingsComponent.vue').default);
Vue.component('Searchproductscomponent',require('./components/SearchProductsComponent.vue').default);
Vue.component('seemorecomponent',require('./components/SeeMoreProductsComponent.vue').default);

// backorders list
Vue.component('seebackordercomponent',require('./components/SeeBackOrderArriveComponent.vue').default);
Vue.component('catalogcomponent',require('./components/users/CatalogComponent.vue').default);
Vue.component('tracking-component',require('./components/users/TrackingComponent.vue').default);


/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
