<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report top30 -->
		<h3 class="text-center">Reporte de pedidos en surtido</h3>
		<div class="row mb-2">
            <div class="col-12 col-md-3" style="text-align: center;">
                <input type="date" class="form-control" name="date1" v-model="date1">
            </div>
            <div class="col-12 col-md-3" style="text-align: center;">
                <input type="date" class="form-control" name="date2" v-model="date2">
            </div>
            <div class="col-12 col-md-3" style="text-align: center;">
                <select name="status" id="status" class="form-control" v-model="status">
                	<option value="T">Docs. abiertos y cerrados</option>
    				<option value="O">Documentos abiertos</option>
    				<option value="C">Documentos cerrados</option>
    			</select>
            </div>
            <div class="col-12 col-md-3" style="text-align: center;">
                <select name="pv" id="pv" class="form-control" v-model="pv">
    				<option value="1">Solo punto de venta</option>
    				<option value="0">Sin punto de venta</option>
    			</select>
            </div>
            <div class="col-12 col-md-6 mt-2" style="text-align: center;">
                <select name="whscode" id="whscode" class="form-control" v-model="whscode">
    				<option value="0" selected>Favor de seleccionar un almacén</option>
    				<option v-for="(whs, index) in warehouse" v-bind:value="whs.name" v-bind:key="index"> {{ whs.value }} </option>
    			</select>
            </div>
            <div class="col-12 col-md-3 mt-2" style="text-align: center;">
                <select name="status_db" id="status_db" class="form-control" v-model="status_db">
    				<option value="0" selected>Todos los estatus</option>
    				<option v-for="(str, index) in strs" v-bind:value="str.name" v-bind:key="index"> {{ str.value }} </option>
    				<option value="" selected>Null</option>
    			</select>
            </div>
            <div class="col-12 col-md-3 mt-2" style="text-align: left;">
                <select name="destine_db" id="destine_db" class="form-control" v-model="destine_db">
    				<option value="0" selected>Todos los destinos</option>
    				<option v-for="(dest, index) in destines" v-bind:value="dest" v-bind:key="index"> {{ dest }} </option>
    			</select>
            </div>
            <div class="col-12 col-md-3 mt-2" style="text-align: center;">
            	<button class="btn btn-outline-primary btn-block" type="button" id="button-addon2"  @click="call()">
		        	Buscar
		    	</button>
            </div>
        </div>
        
		<div class="table-responsive">
			<table class='' style="font-size:10px; border:1px solid; width: 100%;">
				<thead>
					<tr style="border:1px solid">
						<th class='text-center'>Folio Entrega</th>
						<th class='text-center'>Fecha Entrega</th>
						<th class='text-center'>Código</th>
						<th class='text-center'>Cliente</th>
						<th class='text-center'>Empacador</th>
						<th class='text-center'>Tipo Documento</th>
						<th class='text-center'>Folio Destino</th>
						<th class='text-center'>Fecha Destino</th>
						<th class='text-center'>Estatus</th>
						<th class='text-center'>Destino</th>
						<th class='text-center'>Envío</th>
						<th class='text-center'>Ticket</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row,index) in report" :key="index" style="border:1px solid">
						<td>{{ row.Entrega }}</td>
						<td>{{ row.FechaFolio }}</td>
						<td>{{ row.Codigo }}</td>
						<td>{{ row.Cliente }}</td>
						<td>{{ row.Empacador }}</td>
						<td>{{ row.Tipo }}</td>
						<td>{{ row.DocDestino }}</td>
						<td>{{ row.FechaDestino }}</td>
						<td>{{ row.U_Bcx_EstEntr }}</td>
						<td>{{ row.Destino }}</td>
						<td>{{ row.U_COK1_03TIPOENVC }}</td>
						<td>{{ row.U_COK1_03UID }}</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- End Report top30 -->
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
	name: 'ReportSurtidoComponent',
  	props: ['optionsend','user','ubk','warehouse','strs','destines'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		userData:[],
    		report: [],
    		whscode: '0',
    		status: 'T',
    		status_db: '0',
    		destine_db: '0',
    		pv: 0,
    		date1: moment().format('YYYY/MM/DD'),
    		date2: moment().format('YYYY/MM/DD'),
    	}
   	},
   	components: { Loading },
  	mounted() {
  		this.userData = this.user;
  		console.log(this.warehouse);
  	},
   	methods: {
   		async callReport(){
            this.isLoading = true
            this.report = [];
			var SPName = 'spReports'
            var Param1 = this.optionsend;
            var Param2 = this.date1;
            var Param3 = this.date2;
            var Param4 = this.status;
            var Param5 = this.whscode;
            var Param6 = `${this.pv}`;
            var Param7 = `${this.status_db}`;
            var Param8 = `${this.destine_db}`;
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,Param6,Param7,Param8,'Param9':'','Param10':''};
            //console.log(data);
			await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let result = response.data;
                console.log(result);
                let destines = [];
                if(result.length>0){
                	result.forEach(element=>{
                		this.report.push(element);
                	});
                }
                this.isLoading = false;
            })
            .catch(error => { console.log("error", error); });
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        },
        call: function(){
        	console.log(this.date1, this.date2, this.status, this.whscode);
        	if(this.whscode!=='0'){
        		this.callReport();
        	}else{
        		alert('Favor de seleccionar un almacén, disculpe las molestias...');
        	}
        }
   	},
};
</script>

<style lang="css" scoped>
</style>	