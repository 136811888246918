<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<div class="products-view">
			<div  infinite-wrapper class="products-view__list products-list" data-layout="grid-5-full" data-with-features="false" data-mobile-grid-columns="2">
	            <div class="products-list__body" >
	                <div class="products-list__item" v-for="(item, index) in items" :key="index">
	                    <product
	                        :item = "item"
	                        :userData="userData"
	                        :action="action"
	                        :ubk="ubk"
	                        :isSugerido="isSugerido"
	                    />
	                </div>
	            </div>
	        </div>
    	</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import pricescomponent from './PricesComponent';
import product from './PrintProductComponent';
export default {
    name: 'SeeMoreProductsComponent',
    props: ['action','options', 'ubk', 'items_home'],
    data () {
	    return {
	    	api:process.env.MIX_API_URL,
	        userData:null,
	        isLoading: false,
	        totals:0,
	        showPrice:false,
	        items:[],
	        principal:[],
	        links:'',
	        seeFav: true,
	        isSugerido:false,
	    }
	},
  	components: { Loading, product },
    mounted() {
        this.userData = this.options;
        this.showPrice = this.userData.showcart;
        this.links = this.ubk+"search?init=0&search=*";
        this.loadItems();
        if(this.userData.CardCode === "20000"){ this.seeFav = false; }
    },
    methods: {
    	async loadItems(){
            this.isLoading = true;
            let vm = this;
            let Param1 = 'GET-DB';
            let Param2 = '';
            let Param3 = '';
            let Param4 = 'ALL'
            if(this.userData.location==='M'){ // moto
                Param2 = 'N'; Param3 = 'Y';
            }else if(this.userData.location==='C'){ // bici
                Param2 = 'Y'; Param3 = 'N';
            }else{
                Param2 = 'Y'; Param3 = 'Y';
            }
            let Param5 = this.userData.CardCode;
            let Param6 = this.items_home;
            let data = { 'SPName':'spWebOptionsPublic',Param1,Param2,Param3,Param4,Param5,Param6,'Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            await axios
                .post(this.api + "/spmodule/callsp",data)
                .then(response => {
                    let result = response.data
                    //console.log(response);
                    let i=0;
                    result.forEach(element=>{
                        this.items.push(element);
                        i++;
                    });
                    this.totals=i;
                    //console.log('Item',this.items);
                })
                .catch(error => {
                    console.log("error", error);
                })
                .finally(() => {
                    this.isLoading = false;
                } );
        },// end function load api   this.loading = false
        imageUrlAlt(event) {
            event.target.src = this.ubk+"images/no_found.png";
        },
        loadModalProduct(item){
            // quickview.clickHandler.apply(this, arguments);
        },
        addFavorite(event, item, product){
            let str = event.currentTarget.innerHTML;
            //heart-20 wishlist-16
            if(str.includes("heart-20")){
                var newstr = str.replace("heart-20", "wishlist-16");
                event.currentTarget.innerHTML = newstr;
            }else{
                var newstr = str.replace("wishlist-16","heart-20");
                event.currentTarget.innerHTML = newstr;
            }
            addFavorite(item,this.ubk,product);
        },
        addProductCart(item,itemname,categorie,price,qty,priceid, stock, product){
            var sendQty = $('#'+qty).val();
            var sendPrice = $('#'+priceid).val();
            //console.log(sendQty,sendPrice,price);
            addCartJS(item,itemname,categorie,price,sendQty,this.ubk,stock,product,'Home Page Products');
            //console.log('Add Cart Item ',item);
        },
        onAction(ubk,item,stock,action){
            //'qty_'+action+'_'+row.ItemCode
            var id = 'qty_'+ubk+'_'+item;
            var stocks = stock;
            if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==='Y'){ 
                stocks = this.userData.maxStockCart;
            }
            moreQty(id,action,stock,stocks);
        },
        sanitizeTitle: function(title) {
          return this.$func.sanitizeTitleURLVue(title);
        }
    }
}
</script>

<style lang="css" scoped>
</style>