<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<div v-if="showData">
			<div class="row">
				<div class="col-12 col-md-6">
					<input class="form-check-inputs" type="checkbox" name="bike" id="bike" v-model="bike" @change="checkForm('A')">
				    <label for="bike" class="form-check-label">Articulos de Bicicleta</label>
				</div>
				<div class="col-12 col-md-6">
					<input class="form-check-inputs" type="checkbox" name="moto" id="moto" v-model="moto" @change="checkForm('A')">
				    <label for="moto" class="form-check-label">Articulos de Motocicletas</label>
				</div>
			</div>
			<div v-if="showStep1" class="row">
				<div class="col-12">
					<hr/>
				</div>
				<div class="col-12 col-md-2"  v-if="showBike">
	              	<input class="form-check-inputs" type="checkbox" name="bicycle" id="bicycle" value="B" v-model="bicycle" @change="checkForm('A')">
	              	<label for="bicycle" class="form-check-label">Bicicletas</label>
	            </div>
	            <div class="col-12 col-md-2"  v-if="showMoto">
	              	<input class="form-check-inputs" type="checkbox" name="motocycle" id="motocycle" v-model="motocycle" @change="checkForm('A')">
	              	<label for="motocycle" class="form-check-label">Motocicletas</label>
	            </div>
	            <div class="col-12 col-md-2">
	              	<input class="form-check-inputs" type="checkbox" name="acce" id="acce" v-model="acce" @change="checkForm('A')">
	              	<label for="acce" class="form-check-label">Accesorios</label>
	            </div>
	            <div class="col-12 col-md-2">
	              	<input class="form-check-inputs" type="checkbox" name="tools" id="tools" v-model="tools" @change="checkForm('A')">
	              	<label for="tools" class="form-check-label">Refacciones</label>
	            </div>
	            <div class="col-12 col-md-2">
	              	<input class="form-check-inputs" type="checkbox" name="toys" id="toys" v-model="toys" @change="checkForm('A')">
	              	<label for="toys" class="form-check-label">Juguetes</label>
	            </div>
			</div>
			<div v-if="showStep1" class="row">
				<div class="col-12">
					<hr/>
					<h4>Lista</h4>
				</div>
				<div class="col-12 col-md-3">
        		<input type="radio" value="N" v-model="op9" id="op32" checked @change="checkForm('A')">
          	<label for="op32" class="form-check-label">Completa</label>
        </div>
        <div class="col-12 col-md-3">
        		<input type="radio" value="Y" v-model="op9" id="op9" @change="checkForm('A')">
          	<label for="op9" class="form-check-label">Solo productos de mayoreo</label>
        </div>
        <div class="col-12">
        	<label for="stock"> Stock
        		<select name="stock" id="stock" v-model="stock" class="form-control">
        			<option value="N">Todos los productos</option>
        			<option value="Y">Solo productos con stock</option>
        		</select>
        	</label>
        </div>
        <div class="col-12">
        		<div class="d-flex justify-content-between mt-3">
        			<button class="btn btn-warning" @click="PrintButtons('ALL')">Imprimir Todo</button>
        			<button :class="'btn ml-1 '+btn_brand_css" @click="PrintButtons('B')">Imprimir X Marca</button>
        			<button :class="'btn ml-1 '+btn_fam_css" @click="PrintButtons('F')">Imprimir X Categoría</button>
        		</div>
        </div>
			</div>

			<div v-if="showBrands" class="row">
				<div class="col-12">
					<hr/>
					<div class="row">
						<div class="col-6"><h4>Marca</h4></div>
					</div>
				</div>
				<div class="col-12 col-md-3" v-for="(row, index) in printallbrands" :key="index">
					<input class="form-check-inputs" type="checkbox" :id="'brnA_'+row.code" :value="row.code" v-model="brand">
		      <label :for="'brnA_'+row.code" class="form-check-label">{{row.Name}}</label>
		    </div>
		    <br>
	      <div class="col-12" style="padding-top: 20px;">
	      	<button class="btn btn-lg btn-warning btn-block text-uppercase" type="button" @click="save('S-B')">
		      	<i class="fas fa-check-circle"></i> Generar Documento
		      </button>
	      </div>
	    </div>

	    <div v-if="showFamiles" class="row">
				<div class="col-12">
					<hr/>
					<div class="row">
						<div class="col-6"><h4>Categorías</h4></div>
					</div>
				</div>
				<div class="col-12 col-md-3" v-for="(row, index) in printallcategories" :key="index">
					<input class="form-check-inputs" type="checkbox" :id="'catA_'+row.code" :value="row.code" v-model="category">
		      <label :for="'catA_'+row.code" class="form-check-label">{{row.Name}}</label>
		    </div>
		    <br>
	      <div class="col-12" style="padding-top: 20px;">
	      	<button class="btn btn-lg btn-warning btn-block text-uppercase" type="button" @click="save('S-F')">
		      	<i class="fas fa-check-circle"></i> Generar Documento
		      </button>
	      </div>
	    </div>
	    
	    <div v-if="showPrintAll" class="row">
	    	<br>
	      <div class="col-12" style="padding-top: 20px;">
	      	<button class="btn btn-lg btn-warning btn-block text-uppercase" type="button" @click="save('S-ALL')">
		      	<i class="fas fa-check-circle"></i> Generar Documento
		      </button>
	      </div>
	    </div>

			<div v-if="showStep1 && !showBrands && !showFamiles && !showPrintAll" class="row">
				<div class="col-12">
					<hr/>
					<div class="row">
						<div class="col-6">
							<h4>Familia</h4>
						</div>
						<div class="col-6 text-right" style="font-size: 12px;">
							<label for="allC" class="form-check-label">Seleccionar/Quitar Todo?</label>
							<input type="checkbox" id="allC" @click="selectAll('C')" v-model="allSelectedCategories">
						</div>
					</div>
				</div>
				<div class="col-12 col-md-3" v-for="(row, index) in categories" :key="index">
	        	<input class="form-check-inputs" type="checkbox" :id="'cat_'+row.code" :value="row.code" v-model="category" @change="loadSub1">
	        	<label :for="'cat_'+row.code" class="form-check-label">{{row.Name}}</label>
	      </div>
			</div>
			<div v-if="!showBrands && !showFamiles && !showPrintAll">
				<div v-if="showStep1 && category.length>=1" class="row">
						<!-- SubFamilia1 -->
						<div class="col-12">
			      	<hr/>
							<div class="row">
								<div class="col-6"> <h4>Sub-Categoría 1</h4></div>
								<div class="col-6 text-right" style="font-size: 12px; display: none;">
									<label for="allS1" class="form-check-label">Seleccionar/Quitar Todo?</label>
									<input type="checkbox" @click="selectAll('S1')" id="allS1" v-model="allSelectedSubCat1">
								</div>
							</div>
						</div>
						<div class="col-12 col-md-3" v-for="(row, index) in subCategorie1" :key="'S1-'+index">
			        	<input class="form-check-inputs" type="checkbox" :id="'sc1_'+row.code" :value="row.code" v-model="subcat1" @change="loadSub2">
			        	<label :for="'sc1_'+row.code" class="form-check-label">{{row.Name}}</label>
			      </div>
			      <!-- End-SubFamilia1 -->

			      <!-- SubFamilia2 -->
						<div class="col-12" v-if="subCategorie2.length>=1 && subCategorie1.length>=1">
			      	<hr/>
							<div class="row">
								<div class="col-6"> <h4>Sub-Categoría 2</h4></div>
								<div class="col-6 text-right"  style="font-size: 12px; display: none;">
									<label for="allS2" class="form-check-label">Seleccionar/Quitar Todo?</label>
									<input type="checkbox" @click="selectAll('S2')" id="allS2" v-model="allSelectedSubCat2">
								</div>
							</div>
						</div>

						<div class="col-12 col-md-3" v-for="(row, index) in subCategorie2" :key="'S2-'+index">
			        	<input class="form-check-inputs" type="checkbox" :id="'sc2_'+row.code" :value="row.code" v-model="subcat2"  @change="loadBrands('sub2')">
			        	<label :for="'sc2_'+row.code" class="form-check-label">{{row.Name}}</label>
			      </div>
			      <!-- End-SubFamilia2 -->

			     <div class="col-12" v-if="brandCategorie.length>=1 || brandCategorie2.length>=1">
						<hr/>
						<div class="row">
							<div class="col-6">
								<h4>Marca</h4>
							</div>
							<div class="col-6 text-right" style="font-size: 12px; display: none;">
								<label for="allB" class="form-check-label">Seleccionar/Quitar Todo?</label>
								<input type="checkbox" @click="selectAll('B')" id="allB" v-model="allSelectedBrands">
							</div>
						</div>
					</div>
					<div class="col-12 col-md-3" v-for="(row, index) in brandCategorie" :key="index">
							<input class="form-check-inputs" type="checkbox" :id="'brn_'+row.code" :value="row.code" v-model="brand">
		        	<label :for="'brn_'+row.code" class="form-check-label">{{row.Name}}</label>
		      </div>
		      <br>
		      <div class="col-12" style="padding-top: 20px;" v-if="category.length>=1">
		      	<button class="btn btn-lg btn-primary btn-block text-uppercase" type="button" @click="save('I')">
			      	<i class="fas fa-check-circle"></i> Generar Documento
			      </button>
		      </div>
				</div>
			</div>
		</div>
		<div v-else>
				<h4>Favor de agregar su tema, margen y logotipo(opcional)</h4>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'CatalogComponent',
  props: {
    cardcode: String,
    listprice: String,
    ubk: String,
    pdf: String,
    id: String,
  },
  data () {
    return {
    	api:process.env.MIX_API_URL,
    	bike: false,
    	moto: false,
    	stock: "N", // N - Y
    	bicycle: false,
    	motocycle: false,
    	op9: 'N',
    	op32: true,
    	acce: false,
    	tools: false,
    	toys: false,
    	isLoading: false,
    	showStep1: false,
    	showBike: false,
    	showMoto: false,
    	showBrands: false,
    	showFamiles: false,
    	showSubFamiles: false,
    	showPrintAll: false,
    	categories:[],
    	printallcategories:[],
    	category:[],
    	brands:[],
    	brand:[],
    	printallbrands:[],
    	subcats1:[],
    	subcat1:[],
    	subcats2:[],
    	subcat2:[],
    	brandCategorie:[],
    	brandCategorie2:[],
    	subCategorie1:[],
    	subCategorie2:[],
    	origin:[],
    	origines:[],
    	items:[],
    	allSelectedCategories: false,
    	allSelectedBrands: false,
    	allSelectedSubCat1: false,
    	allSelectedSubCat2: false,
    	showData: false,
    	btn_fam_css:'btn-warning',
    	btn_brand_css: "btn-warning",
    }
  },
  components: { Loading },
  mounted() {
  	if(parseInt(this.id)>0) this.showData=true;
  },
  methods: {
  	selectAll(option) {
  		if(option==='C'){
  			this.category = [];
  			this.subcat1 = [];
  			this.subcat2 = [];
  			this.brand = [];
  			this.subCategorie1 = [];
  			this.subCategorie2 = [];
    		this.brandCategorie = [];
    		this.brandCategorie2 = [];

  			if (!this.allSelectedCategories) {
  				this.categories.forEach(element => {
  					this.category.push(element.code);
  				});
        }
        this.loadSub1();
  		}
  		if(option==='S1'){
  			this.subcat1 = [];
  			if (!this.allSelectedSubCat1) {
  				this.subcats1.forEach(element => {
  					this.subcat1.push(element.code);
  				});
        }
        this.loadSub2();
  		}
  		if(option==='S2'){
  			this.subcat2 = [];
  			if (!this.allSelectedSubCat2) {
  				this.subcats2.forEach(element => {
  					this.subcat2.push(element.code);
  				});
        }
        this.loadBrands('sub2');
  		}
  		if(option==='B'){
  			this.brand = [];
  			if (!this.allSelectedBrands) {
  				this.brands.forEach(element => {
  					this.brand.push(element.code);
  				});
        }
  		}
    },
    loadSub1(){
    	console.log('Select1:');
    	this.subCategorie1 = [];
    	this.subCategorie2 = [];
    	this.brandCategorie = [];
    	this.brand = [];
    	
    	this.category.forEach(cat => {
          this.subcats1.forEach(row => {
              if(cat===row.father){
              		//console.log("Data1",cat, row);
                  this.duplicate(this.subCategorie1, row.code, row);
              }
          });
      });
      this.subCategorie1.sort((a, b) => {
          let fa = a.Name.toLowerCase(),
              fb = b.Name.toLowerCase();
          if (fa < fb) {
              return -1;
          }
          if (fa > fb) {
              return 1;
          }
          return 0;
      });
    },
    loadSub2(){
    	console.log('Select2:');
    	this.subCategorie2 = [];
    	this.brandCategorie = [];
    	this.brandCategorie2 = [];

    	this.category.forEach(cat => {
          this.subcat1.forEach(sub1 => {
              this.subcats1.forEach(element => {
              		//console.log("Data2",cat, sub1, element);
                  if(cat===element.father && sub1===element.sub1){
                      this.duplicate(this.subCategorie2, element.code, element);
                      this.loadBrands('sub1');
                  }else{
                  	this.brands.forEach(element2 => {
											if(cat===element2.father && sub1===element2.sub1){
												this.duplicate(this.brandCategorie2, element2.code, element2);
												this.loadBrands('sub1');
												//if(element2.code===56)console.log('Found',element2, element2.father, element2.sub1, element2.sub2);
											}
										});
                  }
              });
          });
      });
			this.subCategorie2.sort((a, b) => {
			    let fa = a.Name.toLowerCase(),
			        fb = b.Name.toLowerCase();
			    if (fa < fb) {
			        return -1;
			    }
			    if (fa > fb) {
			        return 1;
			    }
			    return 0;
			});
    },
    loadBrands(op){
    	console.log('Select3:');
    	this.brandCategorie = [];
    	this.brand = [];

			if(op==="sub2"){
				this.category.forEach(cat => {
					this.subcat1.forEach(sub1 => {
						this.subcat2.forEach(sub2 => {
							this.brands.forEach(row => {
								//console.log("Data3",cat, sub1, sub2, row);
								if(cat===row.father && sub1===row.sub1 && sub2===row.sub2){
									this.duplicate(this.brandCategorie, row.code, row);
								}
							});
						});
					});
				});
			}
			//if(op==="sub1"){
				if(this.brandCategorie.length===0){
					this.brandCategorie = this.brandCategorie2;
				}else{
					this.brandCategorie.concat(this.brandCategorie2); 
					/*
					this.brandCategorie2.forEach(row => {
						this.duplicate(this.brandCategorie, row.code, row);
					}); */
				}
			//}
			this.brandCategorie.sort((a, b) => {
			    let fa = a.Name.toLowerCase(),
			        fb = b.Name.toLowerCase();
			    if (fa < fb) {
			        return -1;
			    }
			    if (fa > fb) {
			        return 1;
			    }
			    return 0;
			});
    },
    duplicate(arr, code, object){
		  const { length } = arr;
		  const id = length + 1;
		  const found = arr.some(el => el.code === code);
		  if (!found) arr.push(object);
		  return arr;
    },
  	checkForm(op){
  		var opBikes = this.bike ? 'Y' : 'L';
  		var opMotos = this.moto ? 'Y' : 'L';
  		var opBicycles = this.bicycle ? 'Y' : 'L';
  		var opMotocycle = this.motocycle? 'Y' : 'L';
  		var opAcc = this.acce? 'Y' : 'L';
  		var opTools = this.tools? 'Y' : 'L';
  		var opToys = this.toys? 'Y' : 'L';
  		var opOp9 = this.op9? 'Y' : 'N';
  		var opOp32 = this.op32? 'Y' : 'N';
  		this.showStep1 = false;
  		this.showBrands = false
  		this.showFamiles = false
  		this.showSubFamiles = false
  		this.showPrintAll = false
  		this.showBike = false;
  		this.showMoto = false;
  		var callEndpoint = 'GET-OPTIONS-0';
  		if(opBikes==='Y'){ 
  			this.showStep1 = true; 
  			this.showBike = true;
  			callEndpoint = 'GET-OPTIONS-1';
  		}
  		if(opMotos==='Y'){ 
  			this.showStep1 = true; 
  			this.showMoto = true;
  			callEndpoint = 'GET-OPTIONS-1';
  			setBikeMoto += 'A-'+opBikes;
  		}
  		if(opAcc==='Y') callEndpoint = 'GET-OPTIONS-1';
  		if(opTools==='Y') callEndpoint = 'GET-OPTIONS-1';
  		var op9Send = this.op9;

    	var setBikeMotoItems = 'A-'+opBikes+',B-'+opMotos;
  		var setBikeMoto = 'A-'+opBicycles+',B-'+opMotocycle;
  		var setCatAccTools = 'A-'+opAcc+',B-'+opTools;
  		var setCatToys = opToys;
  		var setOrigin = op9Send;
  		var getBrands = "";
  		var getCategories = "";
  		var getSubCat1 = "";
  		var getSubCat2 = "";
  		if(op=='I'){
  			var callEndpoint = 'GET-ITEMS';
	    	
	    	this.category.forEach(element => {
	    		getCategories += element+',';
	    	});
	    	
	    	this.brand.forEach(element => {
	    		getBrands += element+',';
	    	});

	    	this.subcat1.forEach(element => {
	    		getSubCat1 += element+',';
	    	});

	    	this.subcat2.forEach(element => {
	    		getSubCat2 += element+',';
	    	});
  		}
  		if(op==='S-B'){
  			var callEndpoint = 'GET-ITEMS';
  			this.brand.forEach(element => {
	    		getBrands += element+',';
	    	});
	    	this.brand = [];
  		}
  		if(op==='S-F'){
  			var callEndpoint = 'GET-ITEMS';
  			this.category.forEach(element => {
	    		getCategories += element+',';
	    	});
	    	this.category = [];
  		}
  		if(op==='S-ALL'){
  			var callEndpoint = 'GET-ITEMS';
  			getCategories = 'ALL';
  		}
  		this.callApi(callEndpoint, setBikeMotoItems, setBikeMoto, setCatAccTools, setCatToys, setOrigin, getCategories, getBrands, getSubCat1, getSubCat2);
    },
    PrintButtons(op){
  		var opBikes = this.bike ? 'Y' : 'L';
  		var opMotos = this.moto ? 'Y' : 'L';
  		var opBicycles = this.bicycle ? 'Y' : 'L';
  		var opMotocycle = this.motocycle? 'Y' : 'L';
  		var opAcc = this.acce? 'Y' : 'L';
  		var opTools = this.tools? 'Y' : 'L';
  		var opToys = this.toys? 'Y' : 'L';
  		var opOp9 = this.op9? 'Y' : 'N';
  		var opOp32 = this.op32? 'Y' : 'N';
  		var callEndpoint = 'GET-OPTIONS-1';
  		this.btn_fam_css = 'btn-warning';
  		this.btn_brand_css = 'btn-warning';
  		this.showStep1 = false;
  		this.showBike = false;
  		this.showMoto = false;
  		if(opBikes==='Y'){ 
  			this.showStep1 = true; 
  			this.showBike = true;
  		}
  		if(opMotos==='Y'){ 
  			this.showStep1 = true; 
  			this.showMoto = true;
  			setBikeMoto += 'A-'+opBikes;
  		}
  		var op9Send = this.op9;
  		var setBikeMotoItems = 'A-'+opBikes+',B-'+opMotos;
  		var setBikeMoto = 'A-'+opBicycles+',B-'+opMotocycle;
  		var setCatAccTools = 'A-'+opAcc+',B-'+opTools;
  		var setCatToys = opToys;
  		var setOrigin = op9Send;
  		var getBrands = "";
  		var getCategories = "";
  		var getSubCat1 = "";
  		var getSubCat2 = "";
  		var runQuery = true
  		this.showBrands = false
  		this.showFamiles = false
  		this.showSubFamiles = false
  		this.showPrintAll = false
  		this.printallbrands = []
  		if(op==='B'){
  			this.showBrands = true
  			this.btn_brand_css = 'btn-primary';
  		}
  		if(op==='F'){
  			this.showFamiles = true
  			this.btn_fam_css = 'btn-primary';
  		}
  		if(op=='ALL'){
  			this.showPrintAll = true
  			runQuery = false
  		}
  		if(runQuery){
	  		this.callApi(
	  			callEndpoint,
	  			setBikeMotoItems, 
	  			setBikeMoto, 
	  			setCatAccTools, 
	  			setCatToys, 
	  			setOrigin, 
	  			getCategories, 
	  			getBrands, 
	  			getSubCat1, 
	  			getSubCat2
	  		);
		  }
    },
    async callApi(option, setBikeMotoItems, setBikeMoto, setCatAccTools, setCatToys, setOrigin, setcategorie, setBrand, setCat1, setCat2){
        this.isLoading = true;
        if(option!=='GET-ITEMS'){
					this.categories = [];
					this.category = [];
					this.brand = [];
        	this.brands = [];
        	this.brandCategorie = [];
        	this.origin = [];
        	this.subcats1 = [];
        	this.subcat1 = [];
        	this.subcats2 = [];
        	this.subcat2 = [];
        }
        var stock_option = this.stock;
        var SPName = 'spUsersCatalogs';
        var Param1 = option;
        var Param2 = setBikeMotoItems;
        var Param3 = setBikeMoto;
        var Param4 = setCatAccTools;
        var Param5 = setCatToys;
        var Param6 = setOrigin;
        var Param7 = 'A-'+this.cardcode+',B-'+this.listprice+',C-'+stock_option;
        var Param8 = setcategorie;
        var Param9 = setBrand;
        var Param10 = 'A-'+setCat1+':B-'+setCat2;
        let data = { 
        	SPName, 
        	Param1, 
        	Param2, 
        	Param3, 
        	Param4, 
        	Param5,
        	Param6,
        	Param7,
        	Param8,
        	Param9,
        	Param10,
        };
        //console.log(data);
        await axios
        .post(this.api + "/spmodule/callsp",data)
        .then(({ data })=> { 
            //console.log(data);
            if(option==='GET-ITEMS'){
            	this.items = data;
            	//console.log(JSON.stringify(data));
            	this.saveData();
            }else{
	            data.forEach(element => {
	            	if(element.op==='C'){ this.categories.push(element); this.duplicate(this.printallcategories, element.code, element); }
	            	if(element.op==='B'){ this.brands.push(element); this.duplicate(this.printallbrands, element.code, element); }
	            	if(element.op==='O'){ this.origin.push(element); }
	            	if(element.op==='S1'){ this.subcats1.push(element); }
	            	if(element.op==='S2'){ this.subcats2.push(element); }
	            });
            }
        })
        .catch(error => { console.log("error", error); })
        .finally(() => { this.isLoading = false; });
    },
    save(op='I'){
    	this.checkForm(op);
    },
    async saveData(){
    	this.isLoading = true;
    	let data = { 
        	'cardcode': this.cardcode,
        	'id': this.id,
        	'items': JSON.stringify(this.items), 
      };
    	await axios.post(`${this.ubk}`,data)
    		.then(({ data }) => {
    			//console.log('Return Data:',data);
    			document.body.scrollTop = document.documentElement.scrollTop = 0;
    			window.open(this.pdf, "_blank");
    		}).catch(error => {
    			console.log("error", error);
        }).finally(() => {
        	this.isLoading = false;
        });// end axios call;
    },
  }
}
</script>

<style lang="css" scoped>
</style>