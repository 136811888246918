<template>
    <div>
        <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
        <div class="mt-3 table-responsive" v-if="show==='ALL'">
            <table class="table" style="font-size: 12px;">
                <thead>
                    <tr>
                        <th>Fecha</th>
                        <th>Cliente</th>
                        <th>Pedido</th>
                        <th>Facturado</th>
                        <th>Almacén</th>
                        <th>Fecha Surtido</th>
                        <th>Guia</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(order, index) in orders" :key="index">
                        <td>{{order.fecha}}</td>
                        <td>{{order.CardCode}}<br>{{order.CardName}}</td>
                        <td>{{order.pedido}}</td>
                        <td>{{order.factura}}</td>
                        <td>{{order.almacen}}</td>
                        <td>{{order.fecha_surtido}}</td>
                        <td>Guia:{{order.guia}}<br>Fecha entrega:{{order.fecha_entrega}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div v-else>
        	<div class="mb-3" style="display: flex;">
			  	<input type="number" v-model="search" class="form-control" placeholder="Búscar número del pedido" aria-label="Búscar número del pedido" aria-describedby="basic-addon2">
			  	<div class="input-group-append">
			    	<button class="btn btn-outline-secondary" type="button" @click="searchOrder()">Búscar</button>
			  	</div>
			</div>
			<div v-if="showDiv">
				<div class="row justify-content-between" v-if="orders.length>0">
		        	<div class="col-12 col-md-6 p-1" v-for="(order, index) in orders" :key="index" v-if="order.tipo!=='D'">
		        		<div class="card">
		                	<div class="card-body">
		                    	<h5 class="card-title">Seguimiento del pedido {{search}}</h5>
		                        <div class="vertical-timeline vertical-timeline--animate vertical-timeline--one-column">
		                            
		                            <div class="vertical-timeline-item vertical-timeline-element text-gray">
		                                <div>
		                                    <span class="vertical-timeline-element-icon bounce-in">
		                                        <i class="badge badge-dot badge-dot-xl badge-success"></i>
		                                    </span>
		                                    <div class="vertical-timeline-element-content bounce-in">
		                                        <h4 class="timeline-title">Pedido confirmado</h4>
		                                        <p class="text-gray">
		                                        	Tu pedido con no. <span class="text-success">{{order.pedido}}</span> elaborado {{order.fecha}}, está en proceso. estamos validando tu compra e inventario
		                                        </p>
		                                        <span class="vertical-timeline-element-date text-gray text-left" >Inicio <br> {{order.fecha}}</span>
		                                    </div>
		                                </div>
		                            </div>

		                            

		                            <template v-if="order.estatus==='Entregado'">

		                            	<div class="vertical-timeline-item vertical-timeline-element">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-gray">Preparando tu pedido</h4>
			                                        <p class="text-gray">Estamos surtiendo tu pedido desde  <span class="text-success">{{order.almacen}} - {{order.entrega}}</span></p>
			                                        <span class="vertical-timeline-element-date text-gray text-left">Surtiendo <br> {{order.fecha_surtido}}</span>
			                                    </div>
			                                </div>
			                            </div>

		                            	<div class="vertical-timeline-item vertical-timeline-element" v-if="order.tipo_envio!=='CR' && order.factura">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in text-gray">
			                                        <h4 class="timeline-title">Pedido listo para envíarse</h4>
			                                        <p>Afinando detalles para su envío.</p>
			                                        <span class="vertical-timeline-element-date text-left">Validando<br>&nbsp;</span>
			                                    </div>
			                                </div>
			                            </div>

			                            <div class="vertical-timeline-item vertical-timeline-element" v-if="order.factura">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in text-gray">
			                                        <h4 class="timeline-title" v-if="order.entrega">Pedido listo para ser recogido</h4>
			                                        <h4 class="timeline-title text-success" v-else>Pedido listo para ser recogido</h4>
			                                        <p>Tu pedido está facturado y se esta preparando pero su envío en {{order.tipo_nombre}}</p>
			                                        <span class="vertical-timeline-element-date text-left">Facturado <br>{{order.fecha_factura}}</span>
			                                    </div>
			                                </div>
			                            </div>

			                            <div class="vertical-timeline-item vertical-timeline-element" v-if="order.entrega">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-success"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-success">Pedido Entregado en <span class="text-success">{{order.pack}}</span>.</h4>
			                                        <p>
			                                        	Tu pedido ha sido entregado con el no. <span class="text-success">{{order.entrega}}</span>. 
														<span v-if="order.guia">
															<br>Guía de seguimiento es: <span class="text-success">{{order.guia}}</span>
														</span>
														<br> 
														Agradecemos su preferencia
													</p>
			                                        <span class="vertical-timeline-element-date text-success text-left">Entregado <br>{{order.fecha_entrega}}</span>
			                                    </div>
			                                </div>
			                            </div>
		                            </template>

		                            <template v-else>
		                            	<div class="vertical-timeline-item vertical-timeline-element" v-if="order.factura">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-gray">Preparando tu pedido</h4>
			                                        <p class="text-gray">Estamos surtiendo tu pedido desde  <span class="text-success">{{order.almacen}} - {{order.entrega}}</span>  {{order.fecha_surtido}}</p>
			                                        <span class="vertical-timeline-element-date text-gray text-left">Surtiendo <br> {{order.fecha_surtido}}</span>
			                                    </div>
			                                </div>
			                            </div>
			                            <div class="vertical-timeline-item vertical-timeline-element" v-else>
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-success">Preparando tu pedido</h4>
			                                        <p class=" text-success">Estamos surtiendo tu pedido desde  <span class="text-success">{{order.almacen}} - {{order.entrega}}</span></p>
			                                        <span class="vertical-timeline-element-date text-success text-left">Surtiendo <br>   {{order.fecha_surtido}}</span>
			                                    </div>
			                                </div>
			                            </div>

		                            	<div class="vertical-timeline-item vertical-timeline-element" v-if="order.factura && order.tipo_envio!=='CR'">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-danger"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-success">Pedido listo para envíarse</h4>
			                                        <p>Afinando detalles para su envío.</p>
			                                        <span class="vertical-timeline-element-date text-success text-left">Validando<br></span>
			                                    </div>
			                                </div>
			                            </div>

			                            <div class="vertical-timeline-item vertical-timeline-element" v-if="order.factura && order.tipo_envio==='CR'">
			                                <div>
			                                    <span class="vertical-timeline-element-icon bounce-in">
			                                        <i class="badge badge-dot badge-dot-xl badge-primary"> </i>
			                                    </span>
			                                    <div class="vertical-timeline-element-content bounce-in">
			                                        <h4 class="timeline-title text-success" >Pedido listo para ser recogido</h4>
			                                        <p>Tu pedido está listo en la sucursal <b class="text-success">{{order.pack}}</b></p>
			                                        <p>Recuerda que debes dirigirte al módulo y tendrás que presentar una identificación oficial</p>
			                                        <p>El plazo para recogerlo es de 3 días hábiles</p>
			                                        <span class="vertical-timeline-element-date text-left text-success">Facturado <br>{{order.fecha_factura}}</span>
			                                    </div>
			                                </div>
			                            </div>

		                            </template>
		                        </div>
		                	</div>
		            	</div>
		        	</div>
	        	</div>
	        	<p v-else>Número de pedido no encontrado</p>
			</div>
        </div>
    </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
    props: ['user','ubk','show'],
    data () {
        return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            orders:[],
            search: '',
            showDiv: false,
        }
    },
    components: { Loading },
    mounted() {
        /*
        CardCode: "28806", CardName: "Joaquin Barranco Orozco", SALESMAN: "N", WHOLESALER: "N", list_price: 7, list_price_type: "PUBLICO", location: "B", showcart: true, EMPID:0
        */
        this.userData = this.user;
    },
    methods: {
        async callApi(){
            this.isLoading = true;
            this.header = [];
            var SPName = 'spReports';
            var Param1 = 'TRACK-ORDER';
            var Param2 = this.show;
            var Param3 = this.userData.CardCode;
            var Param4 = this.search;
            var Param5 = this.userData.WHOLESALER;
            let data = {SPName,Param1,Param2,Param3, Param4, Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            var enter = false;
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(({ data })=> { 
            	data.forEach(element1 => {
            		let status = "S";
            		if(element1.factura){
            			if(element1.estatus==='Entregado'){
            				status = "E";
            			}
            		}
            		enter = true;
            	});
                this.orders = data; 
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { 
            	this.isLoading = false; this.showDiv = true;
            	if(!enter){
            		this.callApiInv();
            	}
            });
        },
        async callApiInv(){
            this.isLoading = true;
            this.showDiv = false;
            this.header = [];
            var SPName = 'spReports';
            var Param1 = 'TRACK-INV';
            var Param2 = this.show;
            var Param3 = this.userData.CardCode;
            var Param4 = this.search;
            var Param5 = this.userData.WHOLESALER;
            let data = {SPName,Param1,Param2,Param3, Param4, Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            //console.log(data);
            var pedidos = [];
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(({ data })=> { 
            	data.forEach(element1 => {
            		let status = "S";
            		if(element1.factura){
            			if(element1.estatus==='Entregado'){
            				status = "E";
            			}
            		}
            	});
                this.orders = data; 
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { 
            	this.isLoading = false; this.showDiv = true;
            });
        },
        goToUrl(type){
            location.href = `${this.ubk}newuser/${type}`;
        },
        searchOrder(){
        	this.showDiv = false;
        	if(this.search.length>4){
        		this.callApi();
        	}else{
        		msgShow("error", "Error", "Favor de poner un número de pedido");
        	}
        }
    },
};
</script>

<style lang="css" scoped>
.text-gray {
	color: #cccccc;
}
.vertical-timeline {
    width: 100%;
    position: relative;
    padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 67px;
    height: 100%;
    width: 4px;
    background: #e9ecef;
    border-radius: .25rem;
}

.vertical-timeline-element {
    position: relative;
    margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
    visibility: visible;
    animation: cd-bounce-1 .8s;
}
.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
    box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
    width: 18px;
    height: 18px;
    position: relative;
}
.badge:empty {
    display: none;
}


.badge-dot-xl::before {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: .25rem;
    position: absolute;
    left: 50%;
    top: 50%;
    margin: -5px 0 0 -5px;
    background: #fff;
}

.vertical-timeline-element-content {
    position: relative;
    margin-left: 90px;
    font-size: .8rem;
}

.vertical-timeline-element-content .timeline-title {
    font-size: .8rem;
    text-transform: uppercase;
    margin: 0 0 .5rem;
    padding: 2px 0 0;
    font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: -90px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: #adb5bd;
    font-size: .7619rem;
    white-space: nowrap;
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}
</style>
