<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report last-entries -->
		<h3 class="text-center">Descargar Lista de Precios</h3>
		<div class="row">
			<div class="col-12">
	            <label for="sentEmail" class="text-left">Correo Alternativo:</label><br>
	            <input type="text" name="sentEmail" id="sentEmail" class="form-control" placeholder="Correo Alternativo" v-model="email">
	            <hr>
	        </div>
			<div class="col-12 col-md-6 mt-4">
	            <button type="button" class="btn btn-success btn-block" @click="sendEmail('Y,N');">Enviar Precios Completa</button>
	        </div>
	        <div class="col-12 col-md-6 mt-4">
	            <button type="button" class="btn btn-primary btn-block" @click="sendEmail('Y');">Enviar Precios Importación</button>
	        </div>
	        <div class="col-12 col-md-6 mt-4">
	            <button type="button" class="btn btn-success btn-block" @click="downloadFile('Y,N');">Descargar Precios Completa</button>
	        </div>
	        <div class="col-12 col-md-6 mt-4">
	            <button type="button" class="btn btn-primary btn-block" @click="downloadFile('Y');">Descargar Precios Importación</button>
	        </div>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment'
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
	name: 'ReportListPriceComponent',
	props: ['user','ubk'],
	data () {
	    return {
	    	api:process.env.MIX_API_URL,
		    isLoading: false,
		    email:"",
	    }
	},
  	components: { Loading },
	mounted() {
		this.userData = this.user;
		this.email = this.user.email_client;
    },
    methods: {
    	async sendEmailAPI(url,type){
            this.isLoading = true
            let data = {url, "email":this.email, type };
            //console.log(data);
			await axios.post(this.ubk + "sendemail/lprices",data).then(response => {
                let result = response.data;
                Swal.fire("Alertas", result.data, result.status);
                this.isLoading = false;
            })
            .catch(error => { 
            	console.log("error", error);
            	this.isLoading = false;
            });
        },
		downloadFile(option){
        	var lp = this.userData.list_price;
        	var lpo = this.userData.list_offert;
        	var crd = this.userData.CardCode;
        	var crn = this.userData.CardName;
        	var url = this.ubk + "download/my-list-prices?all="+option+"&token=f456c659dfc002d190380cba&lp="+lp+"&ofert="+lpo+"&code="+crd+"&name="+crn;
        	//popCenter(url);
        	Swal.fire("Alertas", "* No cerrar o cambiar de ventana hasta que su archivo se termine de generar.", "info");
        	location = url;
        },
        sendEmail(option){
        	var lp = this.userData.list_price;
        	var lpo = this.userData.list_offert;
        	var crd = this.userData.CardCode;
        	var crn = this.userData.CardName;
        	var url = "https://bicimex.com/api/xml/getmyfile/show?all="+option+"&token=f456c659dfc002d190380cba&lp="+lp+"&ofert="+lpo+"&code="+crd+"&name="+crn;
        	var type = "Completa"
        	if(option==='Y'){ type = "Importación" }

        	if(validateEmail(this.email)){
        		this.sendEmailAPI(url,type);
        	}else{
        		Swal.fire("Error", "Email no valido", "warning");
        	}
        },
    },
};
</script>

<style>
	.small-table{
	    font-size: 12px!important;
	}
</style>