export const func = {
   sanitizeTitleURLVue: (title) => {
         var str = title;
         str = str.replace(/^\s+|\s+$/g, ''); // trim
         str = str.toLowerCase();
         // remove accents, swap ñ for n, etc
         var from = "àáäâèéëêìíïîòóöôùúüûñçěščřžýúůďťň·/_,:;";
         var to   = "aaaaeeeeiiiioooouuuuncescrzyuudtn------";
         for (var i=0, l=from.length ; i<l ; i++)
         {
             str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
         }
         str = str.replace('.', '-') // replace a dot by a dash
             .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
             .replace(/\s+/g, '-') // collapse whitespace and replace by a dash
             .replace(/-+/g, '-') // collapse dashes
             .replace( /\//g, '' ); // collapse all forward-slashes
         return str;
    },
}
