<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<h3 class="text-center">Reporte de ventas, comparación últimos 3 años</h3>
		<div class="table-responsive">
			<table class="table table-striped table-bordered small">
				<thead>
					<tr>
						<th colspan="4" class="text-center">
							MENSUAL
						</th>
						<th colspan="3" class="text-center">
							ACUMULADO
						</th>
					</tr>
					<tr>
						<th scope="col">Mes</th>
						<th scope="col">{{anio3}}</th>
						<th scope="col">{{anio2}}</th>
						<th scope="col">{{anio1}}</th>
						<th scope="col">{{anio3}}</th>
						<th scope="col">{{anio2}}</th>
						<th scope="col">{{anio1}}</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(item, index) in dataTable" :key="index">
						<td>{{item.mes}}</td>
						<td>{{item.anio3 | currency}}</td>
						<td>{{item.anio2 | currency}}</td>
						<td>{{item.anio1 | currency}}</td>
						<td>{{item.aanio3 | currency}}</td>
						<td>{{item.aanio2 | currency}}</td>
						<td>{{item.aanio1 | currency}}</td>
					</tr>
				</tbody>
			</table>

		    <graph-line
		            :height="600"
		            :shape="'normal'"
		            :labels="[ 'ENE.', 'FEB.', 'MAR.', 'ABR.', 'MAY.', 'JUN.', 'JUL.', 'AGO.', 'SEP.', 'OCT.', 'NOV.', 'DIC.' ]"
		            :names="names"
		            :values="values">
		        <note :text="'Comparación últimos 3 años'"></note>
		        <tooltip :names="names" :position="'right'"></tooltip>
		        <legends :names="names"></legends>
		        <guideline :tooltip-y="true"></guideline>
		    </graph-line>
		</div>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment'

export default {
  	name: 'ReportThreeCompareComponent',
  	props: ['user','ubk'],
  	data () {
    	return {
			api:process.env.MIX_API_URL,
	    	isLoading: false,
	    	header:[],
	    	dataTable:[],
	    	anio1:"2010",
	    	anio2:"2010",
	    	anio3:"2010",
	    	names: [],
	        values: [
	            [ 10, 0, 5, 5 ],
	            [ 40, 10, 10, 10 ],
	            [ 30, 25, 35, 30 ]
	        ]
    	}
  	},
  	components: { Loading },
	mounted() {
		this.userData = this.user;
        this.callApi(); 
        this.anio3 = moment().subtract(2, 'years').format('YYYY');
        this.anio2 = moment().subtract(1, 'years').format('YYYY');
        this.anio1 = moment().format('YYYY');
        this.names = [ this.anio3, this.anio2, this.anio1 ];
        console.log(this.values);
    },
    methods: {
        async callApi(){
            this.isLoading = true
            this.dataTable = [];
            let date1 = moment().subtract(2, 'years').format('YYYY');
			let date2 =  moment().format('YYYY');
			var SPName = 'report3year'
            var Param1 = this.userData.CardCode;
            var Param2 = `${date1}-01-01`;
            var Param3 = `${date2}-12-31`;
            var Param4 = "";
            var Param5 = "";
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            await axios.post(this.api + "/spmodule/callsp",data).then(response => {
                let data = response.data;
                var comAnio1 = 0.00;
                var comAnio2 = 0.00;
                var comAnio3 = 0.00;
                var newData = [];
                var ani1 = [];
                var ani2 = [];
                var ani3 = [];
                data.forEach(element => {
                	var an1 = element.anio3;// /1.16;
            		var an2 = element.anio2;// /1.16;
            		var an3 = element.anio1;// /1.16;
            		ani1.push(element.anio1);
            		ani2.push(element.anio2);
            		ani3.push(element.anio3);

            		comAnio1 += an1;
            		comAnio2 += an2;
            		comAnio3 += an3;
            		newData.push({
            			mes: element.name,
            			anio3: element.anio3,// /1.16,
            			anio2: element.anio2,// /1.16,
            			anio1: element.anio1,// /1.16,
            			aanio3: comAnio1,
            			aanio2: comAnio2,
            			aanio1: comAnio3,
            		});
                });
                this.values = [ ani3, ani2, ani1 ];
                //console.log('Prices',this.values);
                this.dataTable = newData;
                this.isLoading = false;
            })
            .catch(error => { console.log("error", error); });
        },
		seeReportClick(option){
        	this.seeReport = option;
        	this.callApi(option);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        }
    },
};
</script>

<style lang="css" scoped>
.small{font-size: 0.8rem;}
</style>