<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report statement -->
		<h3 class="text-center">Documentos Abiertos</h3>
		<div class="container-fluid">
			<div class="table-responsive">
				<table class='table table-condensed table-striped font-type'>
					<thead>
						<tr>
							<th class='text-center'>Tipo</th>
							<th class='text-center'>No.Documento</th>
							<th class='text-center'>Fecha Dcto.</th>
							<th class='text-center'>Vencimiento</th>
							
							<th class='text-center'>Cargo</th>
							<th class='text-center'>Abono</th>
							<th class='text-center'>Saldo Documento</th>
							<th class='text-center'>Saldo Acumulado</th>
							<th class='text-center'>Dias Atraso</th>
							<th class='text-center'>Intereses</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in st_docs1" :key="index">
							<td>{{ row.Documento }}</td>
							<td>
								<div class="btn-group btn-group-sm" role="group" aria-label="Third group">
										<div class="input-group-prepend">
									      {{ row.DocNum }}
									    </div>
									    <button v-if="row.showFile>0" type="button" class="btn btngrupes" @click="openFile(row.PDF)" title="DESCARGAR PDF">
											<i class="far fa-file-pdf"></i> PDF
										</button>
										<button v-if="row.showFile>0" type="button" class="btn btngrupes" @click="openFile(row.XML)" title="DESCARGAR XML">
											<i class="far fa-file-pdf"></i> XML
										</button>
									</div>
							</td>
							<td>{{ row.RefDate }}</td>
							<td>{{ row.DueDate }}</td>
							<td>{{ row.Cargo | currency }}</td>
							<td>{{ row.Abono | currency }}</td>
							<td>{{ row.BalanceCargo | currency }}</td>
							<td>{{ row.SaldoAcumulado | currency }}</td>
							<td>{{ row.daycer }}</td>
							<td>{{ row.Intereses | currency }}</td>
						</tr>
					</tbody>
					<tfoot class="thead-dark">
						<tr>
							<td colspan="4" class="text-right"><b>Totales:</b></td>
							<td>{{ totalCargos1 | currency }}</td>
							<td>{{ totalAbonos1 | currency }}</td>
							<td>{{ totalBalanceDocs1 | currency }}</td>
							<td colspan="2"></td>
							<td>{{ totalInteres1 | currency }}</td>
						</tr>
					</tfoot>
				</table>
				<div class="my-2" v-if="st_docs1.length>0">
					<div class="input-group flex-nowrap">
					  	<input type="text" id="email1" name="email1" class="form-control" placeholder="Correo electrónico" aria-label="email" aria-describedby="addon-wrapping" v-model="email1">
					  	<div class="input-group-append">
						    <button class="btn btn-warning" type="button"  @click="sendEmail('open','Documentos Abiertos',st_docs1)">
						    	Enviar
						    </button>
						 </div>
					</div>
				</div>
				<hr>
			</div>
		</div>
		<br>
		<!-- report statement month -->
		<div class="container-fluid">
			<h3 class="text-center">Documentos por mes</h3>
			<div class="input-group mb-3">
				<select class='form-control' v-model="month_term" v-on:change="searchMont()">
					<option :value="month.value" :selected="month.value == month_today" v-for="month in months" :key="month.name">
						{{ month.name }}
					</option>
				</select>
			</div>
			<div class="table-responsive">
				<table class='table table-condensed table-striped font-type'>
					<thead>
						<tr>
							<th class='text-center'>Fecha Documento</th>
							<th class='text-center'>No. Documento</th>
							<th class='text-center'>Tipo</th>
							<th class='text-center'>Cargo</th>
							<th class='text-center'>Abono</th>
							<th class='text-center'>Saldo Documento</th>
							<th class='text-center'>Saldo Acumulado</th>
							<th class='text-center'>Fecha Vencimiento</th>
							<th class='text-center'>Dias Cargo</th>
							<th class='text-center'>Intereses Generados</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="(row,index) in st_docs2" :key="index">
							<td>{{ row.RefDate }}</td>
							<td>
								<div class="btn-group btn-group-sm" role="group" aria-label="Third group">
									<div class="input-group-prepend">
								      {{ row.DocNum }}
								    </div>
								    <button v-if="row.showFile>0" type="button" class="btn btngrupes" @click="openFile(row.PDF)" title="DESCARGAR PDF">
										<i class="far fa-file-pdf"></i> PDF
									</button>
									<button v-if="row.showFile>0" type="button" class="btn btngrupes" @click="openFile(row.XML)" title="DESCARGAR XML">
										<i class="far fa-file-pdf"></i> XML
									</button>
								</div>
							</td>
							<td>{{ row.Documento }}</td>
							<td>{{ row.Cargo | currency }}</td>
							<td>{{ row.Abono | currency }}</td>
							<td>{{ row.BalanceCargo | currency }}</td>
							<td>{{ row.SaldoAcumulado | currency }}</td>
							<td>{{ row.DueDate }}</td>
							<td>{{ row.daycer }}</td>
							<td>{{ row.Intereses | currency }}</td>
						</tr>
					</tbody>
					<tfoot class="thead-dark">
						<tr>
							<td colspan="3" class="text-right"><b>Totales:</b></td>
							<td>{{ totalCargos2 | currency }}</td>
							<td>{{ totalAbonos2 | currency }}</td>
							<td>{{ sal_acumulado2 | currency }}</td>
							<td colspan="3"></td>
							<td>{{ totalInteres2 | currency }}</td>
						</tr>
					</tfoot>
				</table>
				<div class="my-2" v-if="st_docs2.length>0">
					<div class="input-group flex-nowrap">
					  	<input type="text" id="email2" name="email2" class="form-control" placeholder="Correo electrónico" aria-label="email" aria-describedby="addon-wrapping" v-model="email2">
					  	<div class="input-group-append">
						    <button class="btn btn-warning" type="button"  @click="sendEmail('month',month_today,st_docs2)">
						    	Enviar
						    </button>
						 </div>
					</div>
				</div>
			</div>
		</div>
		<!-- End Report statement month -->
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
let today = new Date();

export default {
  	props: ['user','ubk','tax'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		cardcode: '',
    		lprice: 7,
    		userData:[],
    		st_docs1: [],
    		st_docs2: [],
    		sal_acumulado1:0,
    		totalCargos1:0,
    		totalAbonos1:0,
    		totalBalanceDocs1:0,
    		totalInteres1:0,
    		sal_acumulado2:0,
    		totalCargos2:0,
    		totalAbonos2:0,
    		totalBalanceDocs2:0,
    		totalInteres2:0,
    		tax:'N',
    		month_today : `${today.getFullYear()}-${today.getMonth()+1}`,
    		month_term: `${today.getFullYear()}-${today.getMonth()+1}`,
    		months: [],
    		email1:"",
    		email2:"",
    	}
   	},
   	components: { Loading },
  	mounted() {
  		this.userData = this.user;
  		let months_name = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];
        var y = 0;
        months_name.forEach(element=>{
			y++;
			let mont_value = `${today.getFullYear()-1}-${y}`;
			this.months.push({ "value":mont_value, "name":`${element}-${today.getFullYear()-1}` });
		});
		y = 0;
		months_name.forEach(element=>{
			y++;
			let mont_value = `${today.getFullYear()}-${y}`;
			if(today.getMonth()+1>=y){
				this.months.push({ "value":mont_value, "name":`${element}-${today.getFullYear()}` });
			}
		});
		this.email1 = this.userData.email_client;
		this.email2 = this.userData.email_client;
  		this.callStatement();
  	},
   	methods: {
   		async callStatement(){
            this.isLoading = true;
            this.st_docs1 = [];
            //var Param4 = search; var Param5 = search.length > 0 ? 1 : 0;
            var Param1 = 'statement';
            var Param2 = this.userData.CardCode;
            var Param3 = `${this.userData.list_price}`;
            var Param4 = '';
            var Param5 = '0';
            var SPName = 'spWebOptionsPublic'
            let data = {SPName, Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            this.sal_acumulado1 = 0;
		    		this.totalCargos1 = 0;
		    		this.totalAbonos1 = 0;
		    		this.totalBalanceDocs1 = 0;
		    		this.totalInteres1 = 0;
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data
                //console.log(response);
                let i=0;
                if(result.length>0){
                	result.forEach(element=>{
                		this.totalCargos1 += element.Cargo;
        				this.totalAbonos1 += element.Abono;
        				this.totalBalanceDocs1 += element.BalanceCargo;
        				this.tax = element.conditionCode === 209 ? 'N' : 'Y';
        				if(element.Typo){
        					this.sal_acumulado1 += element.BalanceCargo - element.BalanceAbono;
        				}else{
        					this.sal_acumulado1 += element.BalanceCargo - element.Abono;
        				}
        				let interesDb = 0;
        				if(element.BalanceCargo>=0){
        					if(element.daycer>=0){
        						interesDb = ((element.BalanceCargo * 0.115131)/100) * element.daycer;
        					}
        				}
        				if(this.tax==='N'){ interesDb = 0; }
        				this.totalInteres1 += interesDb;
        				this.st_docs1.push({
        					"RefDate":element.RefDate,
        					"DocNum":element.DocNum,
        					"Documento":element.Documento,
        					"Cargo":element.Cargo,
        					"Abono":element.Abono,
        					"BalanceCargo":element.BalanceCargo,
        					"SaldoAcumulado":this.sal_acumulado1,
        					"Intereses": interesDb,
        					"DueDate":element.DueDate,
        					"daycer":element.daycer,
        					"XML":element.XML,
        					"PDF":element.PDF,
        					"showFile":element.ShowFile,
        				});
	                });
                }
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { 
                this.isLoading = false; 
                this.callStatementMonth(this.month_today);
            });
        },
        async callStatementMonth(month){
            this.isLoading = true;
            this.st_docs2 = [];
            var getTerm = month.split('-');
            var days = new Date(getTerm[0],getTerm[1], 0).getDate();
            var Param1 = 'statement_month';
            var Param2 = this.userData.CardCode;
            var Param3 = `${this.userData.list_price}`;
            var Param4 = `${month}-01`;
            var Param5 = `${month}-${days}`;
            var SPName = 'spWebOptionsPublic'
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            this.sal_acumulado2 = 0;
    		this.totalCargos2 = 0;
    		this.totalAbonos2 = 0;
    		this.totalBalanceDocs2 = 0;
    		this.totalInteres2 = 0;
    				//console.log(data);
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data
                //console.log(response);
                let i=0;
                if(result.length>0){
                	result.forEach(element=>{
        				let interesDb = 0;
        				let saldoAcumulado = 0;
        				if(i===0){
        					let saldoInicial = element.SaldoInicial;
        					saldoAcumulado = element.SaldoAcomulado;
        					this.st_docs2.push({
	        					"RefDate":'',
	        					"DocNum":'',
	        					"Documento":'',
	        					"Cargo":'',
	        					"Abono":'',
	        					"BalanceCargo":saldoInicial,
	        					"SaldoAcumulado":saldoAcumulado,
	        					"Intereses": '',
	        					"DueDate":'',
	        					"daycer":'',
	        					"XML":'',
	        					"PDF":'',
	        					"showFile":0,
        					});
        				}
        				this.totalCargos2 += element.Cargo;
        				this.totalAbonos2 += element.Abono;
        				this.totalBalanceDocs2 += element.BalanceCargo;
        				this.sal_acumulado2 += saldoAcumulado+(element.BalanceCargo - element.Abono);
        				this.tax = element.conditionCode === 209 ? 'N' : 'Y';
        				if(element.BalanceCargo>=0){
        					if(element.daycer>=0){
        						interesDb = ((element.BalanceCargo * 0.115131)/100) * element.daycer;
        					}
        				}
        				if(this.tax==='N'){ interesDb = 0; }
        				this.totalInteres2 += interesDb;
        				this.st_docs2.push({
        					"RefDate":element.RefDate,
        					"DocNum":element.DocNum,
        					"Documento":element.Documento,
        					"Cargo":element.Cargo,
        					"Abono":element.Abono,
        					"BalanceCargo":element.BalanceCargo,
        					"SaldoAcumulado":this.sal_acumulado2,
        					"Intereses": interesDb,
        					"DueDate":element.DueDate,
        					"daycer":element.daycer,
        					"XML":element.XML,
        					"PDF":element.PDF,
        					"showFile":element.ShowFile,
        				});
        				i++;
	                });
                }
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        searchMont(){
        	this.callStatementMonth(this.month_term);
        },
        popup(type){
        	let token ="f456c659dfc0e2caa4663588bfe302d190380cba";
        	let lpSendt = this.userData.list_price;
        	let ofertSendt = this.userData.list_price;
        	let codeSendt = this.userData.CardCode;
        	let nameSendt = this.userData.CardName;
        	popUnder(type,lpSendt,ofertSendt,codeSendt,nameSendt);
        },
        openFile(file){
        	let newurl = "https://bicimex.com/bcx/docs/"+file;
        	if(file.includes('Corpotek')){
        		let newAddress = file.replaceAll(/\\/g, "*");
        		newAddress = newAddress.replaceAll("**bcxsap*Corpotek*Bicimex*", "");
        		newAddress = newAddress.replaceAll("*", "/");
        		console.log("newurl",newAddress);
        		newurl = "https://bicimex.com/bcx/retail/"+newAddress
        	}
        	window.open(newurl);
        },
        addProductCart(item,itemname,categorie,price,qty,priceid){
            addCartJS(item,itemname,categorie,0,1);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        },
        async sendEmail(option,title,data){
        	this.isLoading = true;
        	let emailSend = this.email1;
        	let totales = [];
        	if(option==='open'){ 
        		emailSend = this.email1; 
        		totales = { 
        			"totalCargos":this.totalCargos1, 
        			"totalAbonos":this.totalAbonos1,
        			"totalBalanceDocs":this.totalBalanceDocs1,
        			"totalInteres":this.totalInteres1
        		};
        	}
        	if(option==='month'){ 
        		emailSend = this.email2;
        		totales = { 
        			"totalCargos":this.totalCargos2, 
        			"totalAbonos":this.totalAbonos2,
        			"totalBalanceDocs":this.totalBalanceDocs2,
        			"totalInteres":this.totalInteres2
        		};
        	}
        	let dataSend = {option, title, "email":emailSend, "data":data, totales };
        	/**/
        	await axios.post(this.ubk+'sendemail/statement',dataSend).then(response => {
                Swal.fire(response.data.title, response.data.message, response.data.status);
            })
            .catch(error => {
                Swal.fire('Error', 'Ups!! contacte a un asesor.', 'error');
            })
            .finally(() => {this.isLoading = false;  });
            
        },
   	},
};
</script>

<style lang="css" scoped>
</style>	