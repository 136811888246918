<template>
	<div>
		<div class="text-center text-muted" v-if="isLoading">Cargando...</div>
		{{status}}
	</div>
</template>
	
<script>
export default {
  	name: 'SettingsComponent',
  	props: ['cardcode','item'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		status: 'No se ha comprado',
    	}
  	},
  	mounted() {
  		this.getStatus();
  	},
  	methods: {
		async getStatus () {
			this.isLoading = true;
			let vm = this;
			vm.status = 'No se ha comprado';
			let data = {
                'Action':"STATUSSELL",
                'Param1':`${this.cardcode}`,
                'Param2':`${this.item}`,
                'Param3':"",
                'Param4':"",
                'Param5':"",
                'Param6':"",
                'Param7':"",
                'Param8':"",
                'Param9':"",
                'Param10':"",
                'Param11':"",
                'Param12':"",
                'Param13':"",
                'Param14':"",
                'Page':1,
                'Size':20
            };
            //console.log('Status:',data);
            await axios.post(this.api + "/products/search",data)
                .then(({ data }) => {
                	if (data.length) vm.status = 'Comprado habitualmente';
                })
                .catch(error => {
                    console.log("error", error);
                }).finally(() => {
                    this.isLoading = false;
            });// end axios call
        }
	}
};
</script>

<style lang="css" scoped>
</style>