<template>
    <div class="product-card product-card--hidden-actions ">
        
        <div v-show="seeFav">
            <button v-if="item.idfav==='0'" class="product-favorite" title="Agregar a favorito" type="button" @click="addFavorite($event, item.ItemCode)">
                <svg width="16px" height="16px">
                    <use :xlink:href="ubk+'images/sprite.svg#heart-20'"></use>
                </svg>
                <span class="fake-svg-icon"></span>
            </button>
            <button v-else class="product-favorite" title="Eliminar de favorito" type="button" @click="addFavorite($event, item.ItemCode)">
                <svg width="16px" height="16px">
                    <use :xlink:href="ubk+'images/sprite.svg#wishlist-16'"></use>
                </svg>
                <span class="fake-svg-icon"></span>
            </button>
        </div>

        <div class="product-card__badges-list">
            <div v-if="item.News==='Y'" class="new-badge">
                <img :src="ubk+'media/nuevo.svg'" alt="producto nuevo" width="15">
            </div>
            <div v-if="item.Offert==='YY'" class="new-badge">
                <img :src="ubk+'media/oferta.svg'" alt="producto con oferta" width="15">
            </div>
            <div v-if="item.Liquidation==='Y'" class="new-badge">
                <img :src="ubk+'media/liquidations.svg'" alt="producto en liquidacion" width="15">
            </div>
            <div v-if="item.OnRequest==='Y'" class="new-badge">
                <img :src="ubk+'media/sobrepedido.svg'" alt="producto sobre pedido" width="15">
            </div>
        </div>
        <div class="product-card__image product-image">
            <a v-bind:href="ubk+'details/product/'+item.ItemCode+'/'+sanitizeTitle(item.ItemName)" class="product-image__body">
                <img v-bind:src="ubk+'Items/'+item.ItemCode+'.jpg'" v-bind:alt="item.ItemCode" class="product-image__img" @error="imageUrlAlt">
            </a>
        </div>
        <div class="product-card__info">
            <div class="product-card__name">
                <div class="text-right"><small class="text-muted">{{item.ItemCode}}</small></div>
                <a v-bind:href="ubk+'details/product/'+item.ItemCode+'/'+sanitizeTitle(item.ItemName)">{{item.ItemName}}</a>
            </div>
            <ul class="product-card__features-list">

                <li>Categoria: <a :href="links+'&fam_in='+item.CodeCat">{{item.Categorie}}</a></li>
                <li>Marca: <a :href="links+'&brand_in='+item.CodeBrand">{{item.Brand}}</a></li>

                <li v-if=" userData.SALESMAN==='Y' || userData.see_stock35==='Y'">
                    <span v-if="userData.SALESMAN==='Y'" class="text-success">
                        {{item.Stock | number('0,0')}} Disponible(s)
                    </span>
                    <div v-else>
                        <span v-if="item.Stock>500" class="text-success">
                            +500 Disponibles
                        </span>
                        <span v-else class="text-success">
                            {{item.Stock | number('0,0')}} Disponible(s)
                        </span>
                    </div>
                </li>
                <li v-if=" userData.SALESMAN==='Y' || userData.WHOLESALER==='Y'">
                    Pzas x multiplo: {{item.StockPack | number('0,0')}}
                </li>
                <li v-if="isSugerido">
                    <settingscomponent :cardcode="userData.CardCode" :item="item.ItemCode"/><br>
                </li>
                <div v-else>
                    <br>
                </div>
            </ul>
        </div>
        <div class="product-card__actions">
            <div class="product__actions-item">
                <div class="input-number product__quantity qty-action">
                    <input :id="'qty_'+action+'_'+item.ItemCode" class="input-number__input form-control form-control-lg" type="number" min="1" value="1" @blur="onAction(action,item.ItemCode,item.Stock,'W')">
                    <div class="input-number__add more" @click="onAction(action,item.ItemCode,item.Stock,'A')"></div>
                    <div class="input-number__sub less" @click="onAction(action,item.ItemCode,item.Stock,'R')"></div>
                </div>
            </div>
            <div class="product-card__prices">
                <pricescomponent
                    op="item"
                    :item="item.ItemCode"
                    :price="item.Price"
                    :stock="item.Stock"
                    :options="userData"
                    :action="action"
                    :ubk="ubk"
                    :row="item"
                    home="floatbtn"
                ></pricescomponent>
            </div>
            <div class="product-card__buttons">
                <!-- <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart showbtn" type="button" @click="addProductCart(item.ItemCode,item.ItemName,item.Categorie,item.Price,'qty_'+action+'_'+item.ItemCode,'price_'+action+'_'+item.ItemCode,item.Stock,item)">
                    Agregar al carro
                </button> -->
                <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart showbtn" type="button" @click="addProductCartNew(item)">
                    Agregar al carro
                </button>
                <a v-if="!showPrice" class="btn btn-secondary btn-block product-card__addtocart showbtn" :href="ubk+'login'">
                    Iniciar sesión
                </a>
            </div>
        </div>
    </div>
</template>
<script>
import pricescomponent from './PricesComponent';
import settingscomponent from './SettingsComponent';

export default {
	props: ['item','action','ubk','userData', 'isSugerido'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            isLoading: false,
            records:0,
            showPrice:false,
            listas:[],
            links:'',
            seeFav: true,
            gif:true,
        }
	},
	components: { pricescomponent, settingscomponent },
	mounted() {
        this.type = this.action;
        this.showPrice = this.userData.showcart;
        this.links = this.ubk+"search?init=0&search=*";
        if(this.userData.CardCode === "20000"){ this.seeFav = false; }
        //console.log('Product',this.item);
    },
    methods: {
            imagegifUrlAlt(event) {
                this.gif=false;
            },
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            },
            addFavorite(event, item){
                let str = event.currentTarget.innerHTML;
                //heart-20 wishlist-16
                if(str.includes("heart-20")){
                    var newstr = str.replace("heart-20", "wishlist-16");
                    event.currentTarget.innerHTML = newstr;
                }else{
                    var newstr = str.replace("wishlist-16","heart-20");
                    event.currentTarget.innerHTML = newstr;
                }
                addFavorite(item,this.ubk);
            },
            onAction(ubk,item,stock,action){
            	//action,item.ItemCode,item.Stock,'A'
                //'qty_'+action+'_'+row.ItemCode
                var id = 'qty_'+ubk+'_'+item;
                var stocks = stock;
                if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==='Y'){
                    stocks = this.userData.maxStockCart;
                }
                moreQty(id,action,stock,stocks);
            },
            addProductCartNew(product){
                var sendQty = $(`#qty_${this.action}_${product.ItemCode}`).val();
                var sendPrice = $(`#price_${this.action}_${product.ItemCode}`).val();
                var sendDiscount = $(`#discount_${this.action}_${product.ItemCode}`).val();
                var item = product.ItemCode;
                var categorie = product.Categorie;
                var itemname = product.ItemName;
                var stock = product.Stock;
                addCartJS(item,itemname,categorie,sendPrice,sendQty,this.ubk,stock,product,'Search Page','',sendDiscount);
                //console.log('Add Cart Item ',item);
            },
            addProductCart(item,itemname,categorie,price,qty,priceid,stock,product){
                var sendQty = $('#'+qty).val();
                var sendPrice = $('#'+priceid).val();
                //console.log(sendQty,sendPrice,price);
                addCartJS(item,itemname,categorie,price,sendQty,this.ubk,stock,product,'Search Page');
                //console.log('Add Cart Item ',item);
            },
        },
};
</script>

<style>
.showbtn{
    display: block!important;
}
</style>
