<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="true" :is-full-page="true"></loading>
	    <!-- .block-posts -->
        <div v-if="records>0" class="block block-posts my-4" data-layout="grid-4" data-mobile-columns="2">
            <div class="container">
                <div class="block-header">
                    <h3 class="block-header__title">{{title}}</h3>
                    <div class="block-header__divider"></div>
                    <div class="block-header__arrows-list">
                        <button class="block-header__arrow block-header__arrow--left" type="button" @click="clickcaroucel('prev')">
                            <svg width="7px" height="11px">
                                <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-left-7x11'"></use>
                            </svg>
                        </button>
                        <button class="block-header__arrow block-header__arrow--right" type="button" @click="clickcaroucel('next')">
                            <svg width="7px" height="11px">
                                <use :xlink:href="ubk+'images/sprite.svg#arrow-rounded-right-7x11'"></use>
                            </svg>
                        </button>
                        <a class="block-header__arrow butonSeeMore" v-show="action!=='DB'" v-bind:href="ubk+'products/promotions/GET-'+action">
                            ver más
                        </a>
                        <a class="block-header__arrow butonSeeMore" v-show="action==='DB'" v-bind:href="ubk+'products/list/'+home_id">
                            ver más
                        </a>
                    </div>
                </div>
                <div class="block-posts__slider">
                    <carousel
                        :autoplay="true"
                        :items="4"
                        :margin="15"
                        :responsive="{0:{items:1,nav:false},900:{items:3,nav:true},990:{items:4,nav:true}}"
                        :autoplayHoverPause="true"
                    >
                        <template slot="prev"><span :class="'owl-prev owl-prev-'+action">prev</span></template>
                        <template slot="next"><span :class="'owl-next owl-next-'+action">next</span></template>

                        <!-- Begin loop data for -->
                        <div class="post-card" v-for="(row, index) in listas" :key="index">
                        	<div v-show="seeFav">
                                <button v-if="row.idfav==='0'" class="product-favorite front" title="Agregar a favorito" type="button" @click="addFavorite($event, row.ItemCode, row)">
                                    <svg width="16px" height="16px">
                                        <use :xlink:href="ubk+'images/sprite.svg#heart-20'"></use>
                                    </svg>
                                    <span class="fake-svg-icon"></span>
                                </button>
                                <button v-else class="product-favorite front" title="Eliminar de favorito" type="button" @click="addFavorite($event, row.ItemCode)">
                                    <svg width="16px" height="16px">
                                        <use :xlink:href="ubk+'images/sprite.svg#wishlist-16'"></use>
                                    </svg>
                                    <span class="fake-svg-icon"></span>
                                </button>
                            </div>
                            <div class="product-card__badges-list">
                                <div v-if="row.News==='Y'" class="new-badge">
                                    <img :src="ubk+'media/nuevo.svg'" alt="producto nuevo" class="imgBradge">
                                </div>
                                <div v-if="row.Offert==='Y'" class="new-badge">
                                    <img :src="ubk+'media/oferta.svg'" alt="producto con oferta" class="imgBradge">
                                </div>
                                <div v-if="row.Liquidation==='Y'" class="new-badge">
                                    <img :src="ubk+'media/liquidations.svg'" alt="producto en liquidacion" class="imgBradge">
                                </div>
                                <div v-if="row.OnRequest==='Y'" class="new-badge">
                                    <img :src="ubk+'media/sobrepedido.svg'" alt="producto sobre pedido" class="imgBradge">
                                </div>
                            </div>
                            <div class="post-card__image">
                                <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                    <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                </a>
                            </div>
                            <div class="post-card__info">
                                <div class="post-card__category">
                                    <a href="">Special Offers</a>
                                </div>
                                <div class="post-card__name same-space">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">
                                    {{row.ItemName}}
                                    </a>
                                </div>
                                <div class="post-card__date">{{row.ItemCode}}</div>
                                <div class="post-card__content">
                                    <ul class="product-card__features-list">
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.see_stock35==='Y'">
                                            <span v-if="userData.SALESMAN==='Y'" class="text-success">
                                                {{row.Stock | number('0,0')}} Disponible(s)
                                            </span>
                                            <div v-else>
                                                <span v-if="row.Stock>500" class="text-success">
                                                    +500 Disponibles
                                                </span>
                                                <span v-else class="text-success">
                                                    {{row.Stock | number('0,0')}} Disponible(s)
                                                </span>
                                            </div>
                                        </li>
                                        <li v-if=" userData.SALESMAN==='Y' || userData.WHOLESALER==='Y'">
                                            Pzas x multiplo: {{row.StockPack | number('0,0')}}
                                        </li>
                                    </ul>
                                    <div>
                                    	<div class="input-number product__quantity qty-action">
                                            <input :id="'qty_'+action+'_'+row.ItemCode" class="input-number__input form-control form-control-lg" type="number" min="1" value="1" @blur="onAction(action,row.ItemCode,row.Stock,'W')">
                                        </div>
                                    </div>
                                    <div class="product-card__prices">
                                        <pricescomponent
                                            op="item"
                                            :item.sync="row.ItemCode"
                                            :price.sync="row.Price"
                                            :stock.sync="row.Stock"
                                            :options.sync="userData"
                                            :action.sync="action"
                                            :ubk="ubk"
                                            :row="row"
                                            home="floatbtn-Home"
                                        ></pricescomponent>
                                    </div>
                                    <div class="product-card__buttons">
                                        <button v-if="showPrice" class="btn btn-primary btn-block product-card__addtocart" type="button" @click="addProductCartNew(row)">
                                            Agregar al carro
                                        </button>
                                        <a v-if="!showPrice" class="btn btn-secondary btn-block product-card__addtocart" :href="ubk+'login'">
                                            Iniciar sesión
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- END loop data for -->
                    </carousel>
                </div> <!-- end block-posts__slider -->
            </div>
        </div>
        <!-- end -->
        <!-- .block-posts / end -->
	</div>
</template>

<script>
import axios from "axios";
	// Import component
import Loading from 'vue-loading-overlay';
import product from './PrintProductComponent';
import carousel from 'vue-owl-carousel'
import pricescomponent from './PricesComponent';

export default {
	name: "HomeProductsComponent",
	props: ['action', 'title', 'options', 'ubk', 'top', 'items_home', 'home_id'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            userData:null,
            isLoading: false,
            records:0,
            showPrice:false,
            listas:[],
            links:'',
            seeFav: true,
            gif:true,
        }
	},
	components: { Loading, product, carousel, pricescomponent},
	mounted() {
        //console.log(this.action,this.side,this.title);
        //console.log(this.productdb);
        this.userData = this.options;
        this.type = this.action;
        this.links = this.ubk+"search?init=0&search=*";
        this.showPrice = this.userData.showcart;
        this.links = this.ubk+"search?init=0&search=*";
        if(this.userData.CardCode === "20000"){ this.seeFav = false; }

        this.loadItems();
    },
    methods: {
        	async loadItems(){
                this.isLoading = true;
                let vm = this;
                let Param1 = 'GET-'+this.action;
                let Param2 = '';
                let Param3 = '';
                let Param4 = this.top
                if(this.userData.location==='M'){ // moto
                    Param2 = 'N'; Param3 = 'Y';
                }else if(this.userData.location==='C'){ // bici
                    Param2 = 'Y'; Param3 = 'N';
                }else{
                    Param2 = 'Y'; Param3 = 'Y';
                }
                let Param5 = this.userData.CardCode;
                let Param6 = this.items_home;
                let data = { 'SPName':'spWebOptionsPublic',Param1,Param2,Param3,Param4,Param5,Param6,'Param7':'','Param8':'','Param9':'','Param10':''};
                //console.log(data);
                await axios.post(this.api + "/spmodule/callsp",data)
                    .then(({ data }) => {
                        //console.log('Result',data);
                        this.records = data.length;
                        this.listas = data;
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                	});// end axios call
            },// end function load api   this.loading = false
            clickcaroucel(action){
                $('.owl-'+action+'-'+this.action).click();
            },
            addProductCartNew(product){
                var sendQty = $(`#qty_${this.action}_${product.ItemCode}`).val();
                var sendPrice = $(`#price_${this.action}_${product.ItemCode}`).val();
                var sendDiscount = $(`#discount_${this.action}_${product.ItemCode}`).val();
                var item = product.ItemCode;
                var categorie = product.Categorie;
                var itemname = product.ItemName;
                var stock = product.Stock;
                addCartJS(item,itemname,categorie,sendPrice,sendQty,this.ubk,stock,product,'Home Page Products','',sendDiscount);
                //console.log('Add Cart Item ',item);
            },
            addProductCart(item,itemname,categorie,price,qty,priceid, stock, product){
                var sendQty = $('#'+qty).val();
                var sendPrice = $('#'+priceid).val();
                //console.log(sendQty,sendPrice,price);
                addCartJS(item,itemname,categorie,price,sendQty,this.ubk,stock,product,'Home Page Products');
                //console.log('Add Cart Item ',item);
            },
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            onCancel() {
              console.log('User cancelled the loader.')
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            },
            onAction(ubk,item,stock,action){
                //action,item.ItemCode,item.Stock,'A'
                //'qty_'+action+'_'+row.ItemCode
                var id = 'qty_'+ubk+'_'+item;
                var stocks = stock;
                if(this.userData.SALESMAN==='Y' || this.userData.see_stock35==='Y'){
                    stocks = this.userData.maxStockCart;
                }
                moreQty(id,action,stock,stocks);
            },
        },
};
</script>

<style>
.same-space{
    height: 60px;
    max-height: 70px;
}
.owl-prev, .owl-next{
    display: none!important;
}
.butonSeeMore{
	width: 60px!important;
	font-size: 12px;
	color: #000000!important;
}
.butonSeeMore:hover {
	color: #ffffff!important;
}
.imgBradge {
	width: 15px!important;
}
.imgBradge2 {
	width: 16px!important;
}
.front{
	z-index: 100!important;
}
</style>