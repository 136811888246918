<template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<!-- Report top30 -->
		<h3 class="text-center">Mis Productos Comprados</h3>
		<div class="table-responsive">
			<table class='table table-condensed table-striped font-type'>
				<thead>
					<tr>
						<th class='text-center'>Código</th>
						<th class='text-center'>Nombre</th>
						<th class='text-center'>Cantidad</th>
						<th class='text-center'>Acciones</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="(row,index) in report" :key="index">
						<td><a :href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{ row.ItemCode }}</a></td>
						<td>{{ row.ItemName }}</td>
						<td>{{ row.Quantity | number('0,0') }}</td>
						<td>
							<button v-if="row.Stock>0" class="btn btn-primary btn-block product-card__addtocart" type="button" @click="addProductCart(row.ItemCode,row.ItemName,row.Category,row.Price,1,0,row.Stock, row)">
	                                    Agregar
	                        </button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<!-- End Report top30 -->
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import moment from 'moment';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  	props: ['user','ubk'],
  	data () {
    	return {
    		api:process.env.MIX_API_URL,
    		isLoading: false,
    		userData:[],
    		report: [],
    	}
   	},
   	components: { Loading },
  	mounted() {
  		this.userData = this.user;
  		this.callApi();
  	},
   	methods: {
   		async callApi(){
   			this.isLoading = true;
            this.report = [];
            var Param1 = 'TOP30';
            var Param2 = this.userData.CardCode;
            var Param3 = `${this.userData.list_price}`;
            var Param4 = '';
            var Param5 = '0';
            var SPName = 'spWebOptionsPublic'
            let data = {SPName,Param1,Param2,Param3,Param4,Param5,'Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
            await axios
            .post(this.api + "/spmodule/callsp",data)
            .then(response => {
                let result = response.data;
                if(result.length>0){
                	result.forEach(element=>{
                		this.report.push(element);
                	});
                }
            })
            .catch(error => { console.log("error", error); })
            .finally(() => { this.isLoading = false; });
        },
        addProductCartNew(product){
                var sendQty = 1;
                var sendPrice = product.Price;
                var sendDiscount = 0;
                var item = product.ItemCode;
                var categorie = product.Categorie;
                var itemname = product.ItemName;
                var stock = product.Stock;
                addCartJS(item,itemname,categorie,sendPrice,sendQty,'',stock,product,'Report','',sendDiscount);
                //console.log('Add Cart Item ',item);
        },
        addProductCart(item,itemname,categorie,price,qty,priceid,stock,product){
            addCartJS(item,itemname,categorie,price,1,this.ubk,stock,product);
        },
        sanitizeTitle: function(title) {
           return this.$func.sanitizeTitleURLVue(title);
        },
   	},
};
</script>

<style lang="css" scoped>
</style>	