   <template>
	<div>
		<loading :active.sync="isLoading" background-color="#000" :can-cancel="true" :on-cancel="onCancel" :is-full-page="true"></loading>
	    <!-- .block-posts -->
        <div v-if="records>0" class="block block-posts" data-layout="grid-4" data-mobile-columns="2">
            <div class="container">
                <div class="block-header">
                    <h3 class="block-header__title">{{title}}</h3>
                    <div class="block-header__divider"></div>
                </div>
            	<div class="block-posts__slider">
            		<carousel
            			:autoplay="true"
            			:items="4"
            			:margin="15"
            			:navText='["Anterior", "Siguiente"]'
            			:responsive="{0:{items:1,nav:false},900:{items:4,nav:true}}"
            			:autoplayHoverPause="true"
            		>

                    	<!-- Begin loop data for -->
                        <div class="post-card" v-for="(row, index) in listas" :key="index">
                            <div class="post-card__image">
                                <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="product-image__body">
                                    <img v-bind:src="'https://bicimex.com/Items/'+row.ItemCode+'.jpg'" v-bind:alt="row.ItemCode" class="product-image__img" @error="imageUrlAlt">
                                </a>
                            </div>
                            <div class="post-card__info">
                                <div class="post-card__category">
                                    <a href="">Special Offers</a>
                                </div>
                                <div class="post-card__name">
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)">{{row.ItemName}}</a>
                                </div>
                                <div class="post-card__date">{{row.ItemCode}}</div>
                                <div class="post-card__content">
                                    <ul class="product-card__features-list">
                                        <li>Categoria: <a :href="links+'&fam_in='+row.CodeCat">{{row.Categorie}}</a></li>
                                        <li>Marca: <a :href="links+'&brand_in='+row.CodeBrand">{{row.Brand}}</a></li>
                                    </ul>
                                    <a v-bind:href="ubk+'details/product/'+row.ItemCode+'/'+sanitizeTitle(row.ItemName)" class="btn btn-warning btn-block btn-sm">Ver detalles</a>
                                </div>
                            </div>
                        </div>
                        <!-- END loop data for -->
                	</carousel>
            	</div>
            </div>
        </div>
        <!-- .block-posts / end -->
	</div>
</template>
<script>
import axios from "axios";
	// Import component
import Loading from 'vue-loading-overlay';
import pricescomponent from './PricesComponent';
import carousel from 'vue-owl-carousel'

export default {
	props: ['action','title','options','ubk','item'],
	data () {
	    return {
            api:process.env.MIX_API_URL,
            userData:null,
            isLoading: false,
            records:0,
            showPrice:false,
            listas:[],
            links:'',
        }
	},
	components: { Loading, pricescomponent, carousel },
	mounted() {
        //console.log(this.action,this.side,this.title);
        this.userData = this.options;
        this.type = this.action;
        this.links = this.ubk+"search?init=0&search=*";
        //console.log('type',this.type);
        this.loadItems();
    },
    methods: {
        	async loadItems(){
                //this.isLoading = true;
                let vm = this;
                let namefirst = this.itemname.split(' ');
                let sendname='';
                if(namefirst.length>2){ sendname=namefirst[0]; }else{ sendname=''; }
                let Param1 = this.action;
                let Param2 = this.item;
                let Param3 = '"'+sendname+'"';
                let Param4 = '"'+this.categorie+'"';
                let data = {'SPName':'spWebOptionsPublic', Param1,Param2,Param3,Param4,'Param5':'','Param6':'','Param7':'','Param8':'','Param9':'','Param10':''};
                //console.log(data);
                await axios.post(this.api + "/spmodule/callsp",data)
                    .then(({ data }) => {
                        //console.log('Result',data.result);
                        this.records = data.count;
                        if (data.result.length) {
                            const itms = data.result;
                            itms.forEach(element=>{
                                this.listas.push(element);
                            });
                        }else{
                            this.records = 0;
                        }
                    })
                    .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                	});// end axios call
            },// end function load api   this.loading = false
            addProductCart(item){
                console.log('Add Cart Item ',item);
            },
            imageUrlAlt(event) {
                event.target.src = this.ubk+"images/no_found.png";
            },
            onCancel() {
              console.log('User cancelled the loader.')
            },
            sanitizeTitle: function(title) {
              return this.$func.sanitizeTitleURLVue(title);
            }
        },
};
</script>

<style>
</style>
