<template>
	<div>
    <loading :active.sync="isLoading" background-color="#000" :can-cancel="false" :is-full-page="true"></loading>
		<div class="row">
			<div class="form-group col-12 col-md-5">
	    		<label>Código del Artículo</label>
	    		<input type="text" class="form-control" name="code" placeholder="Código del artículo" v-model="code">
	  		</div>
	  		<div class="form-group col-12 col-md-5">
	    		<label>Cantidad de piezas</label>
	    		<input type="number" class="form-control" name="quantity" placeholder="Cantidad de piezas" v-model="quantity">
	  		</div>
	  		<div class="form-group col-12 col-md-2">
	  			<label>&nbsp;</label>
	    		<button type="button" class="btn btn-primary btn-block" @click="searchItem()">Agregar</button>
	  		</div>
  		</div>
  		<hr>
  		<table class="table">
  			<thead>
  				<tr>
  					<th>Código</th>
  					<th>Artículo</th>
  					<th>Solicitado</th>
  					<th>Precio</th>
            <th>Stock</th>
            <th>Alternativos</th>
  					<th>Acciones</th>
  				</tr>
  			</thead>
  			<tbody>
  				<tr v-for="(row, index) in list" :key="index">
  					<td>{{row.ItemCode}}</td>
            <td>
              {{row.ItemName}}<br>
              <small style="color:#666666">Piezas por empaque: {{row.StockPack | number('0,0')}}</small>
            </td>
            <td>{{row.solicitado}}</td>
  					<td>
              <pricescomponent
                  op="item-alt"
                  :item="row.ItemCode"
                  :price="row.Price"
                  :stock="row.stock"
                  :options="userData"
                  :action="index"
                  :ubk="ubk"
                  :row="row"
              ></pricescomponent>     
            </td>
            <td>{{row.stock}}</td>
  					<td>
              <div v-if="row.alternativos">
                  <select class="form-control" @change="onChangeItem($event, row.alternativos, row)">
                    <option value="0">Seleccionar un alternativo</option>
                    <option v-for="(alt, indx) in row.alternativos" :key="indx" :value="alt.ItemCode">
                      ({{alt.ItemCode}}) {{alt.ItemName}} sk:{{row.Stock | number('0,0')}}
                    </option>
                  </select>
              </div>
            </td>
  					<td>
  						<button type="button" class="btn btn-danger btn-block" @click="onDelete(row.ItemCode,row)">Eliminar</button>
  					</td>
  				</tr>
  			</tbody>
  		</table>
	</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import pricescomponent from './PricesComponent';
// Import stylesheet
import 'vue-loading-overlay/dist/vue-loading.css';
export default {
  name: 'QuickStoreComponent',
  props: ['cart','options','ubk'],
  data () {
    return {
      api:process.env.MIX_API_URL,
      isLoading: false,
      userData:null,
      code: "",
      quantity: 0,
      location: null,
      list:[],
    }
  },
  components: { Loading, pricescomponent },
  mounted() {
    this.userData = this.options;
    this.location = this.options.location;
  },
  methods: {
    async searchItem(){
      if(this.validForm()){
        this.isLoading = true;
        let data = {
            'Action':'GETITEMSAP',
            'Param1':this.code,
            'Param2':"",
            'Param3':"",
            'Param4':"",
            'Param5':"",
            'Param6':"",
            'Param7':"",
            'Param8':"",
            'Param9':"",
            'Param10':"",
            'Param11':"",
            'Param12':"",
            'Param13':"",
            'Param14':"",
            'Page':0,
            'Size':20
        };
        //console.log('Params',data);
        var insert = [];
        await axios.post(this.api + "/products/search",data)
            .then(({ data }) => {
                if (data.length) {
                    let itms = data;
                    itms.forEach(element=>{
                      if(element.stock>=1){
                        insert = element;
                        insert.solicitado = this.quantity;
                        this.list.push(insert);
                        this.code = "";
                        this.quantity = 0;
                        var category = element.Categorie;
                        addCartJS(element.ItemCode,element.ItemName,category,element.Price,insert.solicitado,this.ubk,element.stock,element);
                      }else{
                        this.searchItemAlternative(element,this.quantity);
                      }
                    });
                }
            })
            .catch(error => {
                console.log("error", error);
            }).finally(() => {
               this.isLoading = false;
        });// end axios call
      }
    },
    async searchItemAlternative(dataold,solicitado){
      let data = {
          'Action':'ALTERNATIVES',
          'Param1': dataold.ItemCode,
          'Param2':"",
          'Param3':"",
          'Param4':"",
          'Param5':"Y",
          'Param6':"Y",
          'Param7':"",
          'Param8':"",
          'Param9':"",
          'Param10':"",
          'Param11':"",
          'Param12':"",
          'Param13':"",
          'Param14':"",
          'Page':0,
          'Size':20
      };
      var insert = [];
      //console.log(data);
      await axios.post(this.api + "/products/search",data)
          .then(({ data }) => {
              if (data.length) {
                  let itms = data;
                  insert = itms;
                  //console.log('Alternativos:',insert);
                  itms.forEach(element=>{
                    //if(element.stock === 0) msgShow("info", "Información", "Artículo no tiene existencia");
                    dataold.solicitado = solicitado;
                    dataold.alternativos = insert;
                    //addCartJS(dataold.ItemCode,dataold.ItemName,"QUICK",dataold.Price,dataold.solicitado,this.ubk);
                    this.code = "";
                    this.quantity = 0;
                  });
              }else{
                msgShow("info", "Información", "Artículo no tiene existencia y no cuenta con alternativos");
              }
          })  
          .catch(error => {
              console.log("error", error);
          }).finally(() => {
             this.isLoading = false;
      });// end axios call
      this.list.push(dataold);
    },
    onChangeItem(event, datachange, rowdata){
      let item = event.target.value;
      if(item!==0){
        const newList = [];
        this.list.forEach(element => {
          if(element.ItemCode===rowdata.ItemCode){
            datachange.forEach(element2 => {
              if(element2.ItemCode===item){
                element2.solicitado = rowdata.solicitado;
                newList.push(element2);
                var category = element2.Categorie;
                addCartJS(element2.ItemCode,element2.ItemName,category,element2.Price,rowdata.solicitado,this.ubk,element2.Stock,element2);
                delCartJS(rowdata.ItemCode,this.ubk,0,1);
              }
            });
          }else{
            newList.push(element);
          }
        });
        this.list = newList;
      }
    },
    validForm(){
      var isError = 1;
      var msg = "";
      if(this.code.length<=0){ isError = 0; }
      if(this.quantity<=0){ isError = 0; }
      msg = "Valores vacios";
      this.list.forEach(element => {
        if(element.ItemCode===this.code){
          msg = "Artículo ya existe";
          isError = 0;
        }
      });
      if(isError===0){ msgShow("info", "Información", msg); }
      return isError;
    },
    onDelete(rowid, product){
        console.log(product);
        var msg = "Esta seguro de eliminar el articulo?";
        if(rowid==='all') msg = "Esta seguro de eliminar todo el carrito?";
        Swal.fire({
            title:"Eliminar Artículo",
            text: msg,
            icon: "info",
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'SI'
        }).then((result) => {
            if (result.value) {
                delCartJSSAP(rowid,this.ubk,product,'cart');
            }
        });
    },
  },
};
</script>

<style lang="css" scoped>
</style>