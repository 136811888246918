<template>
	<div v-if="isLoading" class="text-center">
		cargando...
	</div>
	<div v-else class="text-left">
		<input type="hidden" :name="'qty-'+item" :value="newstock">
		<li>
            <span class="text-success">{{newstock | number('0,0')}} Disponible(s)</span>
        </li>
        <li v-if="qty>newstock">
            <span class="text-muted">
                * {{ (qty - newstock) | number('0,0')}} piezas en backorder
            </span>
        </li>
	</div>
</template>

<script>
	export default {
		props: ['op','item','stock','qty','options'],
        data () {
            return {
                api:process.env.MIX_API_URL,
                userData:null,
                isLoading: false,
                newstock:0,
            }
        },
        mounted() {
            this.userData = this.options;
            this.loadQty();
        },
        methods: {
        	async loadQty(){
                this.isLoading = true;
                let data = {
                    'Action':"Stock",
                    'Param1':`${this.item}`,
                    'Param2':"",
                    'Param3':"",
                    'Param4':"",
                    'Param5':"",
                    'Param6':"",
                    'Param7':"",
                    'Param8':"",
                    'Param9':"",
                    'Param10':"",
                    'Param11':"",
                    'Param12':"",
                    'Param13':"",
                    'Param14':"",
                    'Page':1,
                    'Size':20
                };
                await axios
                .post(this.api + "/products/search",data)
                .then(({ data }) => {
                	data.forEach(element=>{
                        this.newstock = element.stock;
                    });
                })
                .catch(error => {
                        console.log("error", error);
                    }).finally(() => {
                        this.isLoading = false;
                    });
            },
        }
	}
</script>